import {call, put, select} from 'redux-saga/effects';
import ExperienciaActions from '../../ducks/experiencia/experiencia';
import ExperienciasActions from '../../ducks/experiencia/experiencias';
import lodash from 'lodash';
import Swal from 'sweetalert2';
import api from '../../../config/axios';
import moment from 'moment';
import SuccessActions from '../../ducks/success';

function* atualizaLista(experienciaId, newValues, create){
    let experienciasLista  = (yield select((state) => state.experiencia.lista.data.asMutable()));
    let experiencias  = [...experienciasLista?.data] ?? [];

    if(create) {
       experiencias.push({...newValues});
    }

    experiencias = lodash.map(experiencias, (item, key) => {
        if(item.experiencia_id === experienciaId){
            return { ...item, ...newValues, id: key }
        }

        return {...item, id: key};
    });


    return {...experienciasLista, data: experiencias};
}

export function* updateExperienciaStatusRequest({ experienciaId, status}) {
    try {

        if(!experienciaId){
          throw new Error("Experiência inválida.")
        }

        let confirm = yield Swal.fire({
            title: "Alteração de Status", 
            text: "Confima alteração de status?",
            icon: '',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonText: "SIM",
            reverseButtons: true
        });

        if(confirm.isConfirmed){
            yield call(api.put, `experiencia/${experienciaId}/status/${status}`);
            let newLista = yield atualizaLista(experienciaId, {cod_status_acesso: status});
            yield put(ExperienciasActions.loadExperienciasSuccess(newLista));
        }

        yield put(SuccessActions.setSuccess(true, 'Experiência salva com sucesso!'));
        yield put(ExperienciaActions.updateExperienciaStatusSuccess());

    } catch (error) {
        error.message =  error.message ?? "Erro ao atualizar status da experiência."
        yield put(ExperienciaActions.updateExperienciaStatusFailure(error));
    }
}


export function* saveExperienciaRequest({ experienciaId, dados, callback}) {
    try {

        let request = experienciaId ? api.put : api.post;
        let url     =  experienciaId ? `experiencia/${experienciaId}` : `experiencia`;

        let newDados = {
            ...dados,
            usuario_id: dados?.usuario?.usuario_id ?? null,
            perfil_id: dados?.perfil?.perfil_id ?? null,
            acomodacao_id: dados?.acomodacao?.acomodacao_id ?? null
        }
        
        const response = yield call(request, url, newDados);

        newDados =  {
            ...newDados,
            ...response.data.data,
            nom_usuario:  dados.usuario?.nom_usuario,
            nom_perfil:  dados.perfil?.nom_perfil,
            dt_experiencia_ini_grid: moment( newDados?.dt_experiencia_ini).format('DD/MM/yyyy'),
            dt_experiencia_fim_grid: newDados?.dt_experiencia_fim ? moment( newDados?.dt_experiencia_fim).format('DD/MM/yyyy'): '',
        };

        let newLista = yield atualizaLista(experienciaId, newDados, !experienciaId);

        yield put(SuccessActions.setSuccess(true, 'Experiência salva com sucesso!'));
        yield put(ExperienciasActions.loadExperienciasSuccess(newLista));
        yield put(ExperienciaActions.saveExperienciaSuccess());

        if(callback && typeof callback === 'function'){
            callback();
        }

    } catch (error) {
        error.message =  error.message ?? "Erro ao salvar experiência"
        yield put(ExperienciaActions.saveExperienciaFailure(error));
    }
}