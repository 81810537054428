import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadProgramacaoItemCaracterizadoresRequest: [ 'atracaoId', 'atracaoItemId'],
    loadProgramacaoItemCaracterizadoresSuccess: ['data'],
    loadProgramacaoItemCaracterizadoresFailure: ['error'],

    saveProgramacaoItemCaracterizadoresRequest: [ 'atracaoId', 'atracaoItemId', 'dados', 'callback'],
    saveProgramacaoItemCaracterizadoresSuccess: ['data'],
    saveProgramacaoItemCaracterizadoresFailure: ['error'],


    clearProgramacaoItemCaracterizadoresRequest: null
});

export const ProgramacaoItemCaracterizadoresTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    loadingCreate:false,
    error: false
});

export const reducerCaracterizadores = createReducer(INITIAL_STATE, {
    [Types.LOAD_PROGRAMACAO_ITEM_CARACTERIZADORES_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_PROGRAMACAO_ITEM_CARACTERIZADORES_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_PROGRAMACAO_ITEM_CARACTERIZADORES_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),

     
    [Types.SAVE_PROGRAMACAO_ITEM_CARACTERIZADORES_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false }),
    [Types.SAVE_PROGRAMACAO_ITEM_CARACTERIZADORES_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loadingCreate: false, error: false }),
    [Types.SAVE_PROGRAMACAO_ITEM_CARACTERIZADORES_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),

    [Types.CLEAR_PROGRAMACAO_ITEM_CARACTERIZADORES_REQUEST]: (state, action) =>
        state.merge(INITIAL_STATE),
});


