import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
    root: {
      // height:"100vh", 
      // display: "flex", 
      // flexDirection: "column",
    },
    content:{
      flex:1,
      display:"flex"
    },
    main:{
      flex:1,
      display:"flex",
      paddingTop: "60px"
    },
    conteudo:{
      flex: 1,  
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: 'calc(100vh - 156px)'
    },
    conteudoCentral:{
      flex: 1,
      height: '100%'
    },
    copyright:{
      padding: 10
    }
  }));