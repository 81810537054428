import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
    root: {
      height:"100vh", 
      // background:  `url(${Back})`, 
      // backgroundRepeat: 'repeat', 
      // backgroundSize: '100%' , 

    },
    toolbar: {
    //   paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },


    title: {
      flexGrow: 1,
    },

    
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    button: {
        height: 60, // setting height/width is optional
    },
    label: {
    // Aligns the content of the button vertically.
        flexDirection: 'column'
    },
    icon: {
        fontSize: '24px !important',
        marginBottom: theme.spacing(1)
    },
    logo:{
        maxHeight:'40px'
    }
  }));