import React, { useCallback, useEffect, useState } from 'react';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';
import DataGrid from '../../components/DataGrid';
import { useDispatch, useSelector } from 'react-redux';
import AgentamentosActions from '../../store/ducks/agendamento/agendamentos';
import AgentamentoActions from '../../store/ducks/agendamento/agendamento';

import history from '../../routes/history';
import { Box, CircularProgress } from '@material-ui/core';
import DateRange from '../../components/DateRange';
import Select from '../../components/Form/Campo/select';
import {useStyles} from './styles';
import moment from 'moment';

function Agenda(props) {
  const classes     = useStyles();
  const dispatch    = useDispatch();
  const atracao     = history.location.state;

  const {data, loading, error}  = useSelector(state => state.agendamento.lista);
  const agendamento             = useSelector(state => state.agendamento.itemCrud);

  const [filtro, setFiltro] = useState({dt_agenda: moment().format('YYYY-MM-DD')});

  let title = `Agendamentos - ${atracao?.nome}`;

  const classHoraPassada = ({row}) => row?.horaPassada ? classes.dataGrid : false
  
  const handleFiltros = (periodo) => {

    const { startDate, endDate, filter } = periodo; 
    let filtro = {};

    if(filter === 'start_now'){
      filtro.dt_agenda = 'startNow';
    }else{
      if(startDate === endDate){
        filtro.dt_agenda = moment(startDate).format('YYYY-MM-DD');
      }else{
        filtro.dt_agenda_ini = moment(startDate).format('YYYY-MM-DD');
        filtro.dt_agenda_fim =  moment(endDate).format('YYYY-MM-DD');
      }
    }

    setFiltro(filtro);
  }

  const onChangeStatus = (row, status) => {
    dispatch(AgentamentoActions.updateAgendamentoStatusRequest(row?.agenda_exp_usu_id, status));
  }

  const carregar = useCallback((propsDataGrid, filtroPeriodo) =>{
    dispatch(AgentamentosActions.loadAgendamentosRequest(atracao?.atracaoId, atracao?.atracaoItemId, filtroPeriodo, false, propsDataGrid?.page));
  }, [dispatch, atracao]);

  const handleStatus = ({row}) => (<>
    {agendamento.loading && agendamento.agendamentoId === row?.agenda_exp_usu_id && <CircularProgress size={12} />}
    <Select 
      value={row?.cod_status}
      options={[
        {value: 'ABERTA', title: 'Aberta'}, 
        {value: 'REALIZADA', title: 'Realizada'}, 
        {value: 'PERDIDA', title: 'Perdida'}, 
        {value: 'CANCELADA', title: 'Cancelada'},
        {value: 'MANUTENCAO', title: 'Manutenção'},
        {value: 'DESAUTORIZADA', title: 'Desautorizada'}
      ]}
      setValue={(e) => onChangeStatus(row, e.target.value)}
      required={false}
      fullWidth
      disabled={agendamento.loading && agendamento.agendamentoId === row?.agenda_exp_usu_id}
    />
    </>
  )

  const columns  = [
    { field: 'dt_agenda', headerName: 'Data', flex: 0.5, cellClassName: classHoraPassada},
    { field: 'hr_agenda', headerName: 'Hora', flex: 0.5,cellClassName: classHoraPassada },
    { field: 'nom_usuario', headerName: 'Nome Usuário', flex: 2 ,cellClassName: classHoraPassada},
    { field: 'num_pessoa_agenda', headerName: 'Nº Acompanhantes', flex:1,cellClassName: classHoraPassada },
    { field: 'cod_status', headerName: 'Status', renderCell: (params) =>  handleStatus(params), flex: 1, cellClassName: classHoraPassada },
  ]

  useEffect(() => {
    carregar(false, filtro);

    return () => {
      dispatch(AgentamentosActions.clearAgendamentosRequest());
    }
    
  }, [carregar, dispatch, filtro]);



  return (
    <Page botaoVoltar title={title} conteudoDireita={false} atualizar={carregar} loading={loading}>
        <Box marginBottom={1}>
          <DateRange onChange={handleFiltros}/>
        </Box>
        <DataGrid
          reload={carregar}
          error={error || agendamento.error}
          loading={loading}
          columns={columns}
          rows={data?.data ?? []}
          rowCount={data?.meta?.total}
          pageSize={15}
          onPageChange={carregar}
          className={classes.dataGrid}
        />     
    </Page>
  );
}

export default  withWidth()(Agenda);
