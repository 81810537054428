import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {reducerCaracterizadores } from './caracterizadores';
import { combineReducers } from 'redux';


const { Types, Creators } = createActions({
    loadAtracaoRequest: [ 'atracaoId'],
    loadAtracaoSuccess: ['atracaoId','data'],
    loadAtracaoFailure: ['error'],

    saveAtracaoRequest: [ 'atracaoId', 'dados'],
    saveAtracaoSuccess: ['atracaoId','data'],
    saveAtracaoFailure: ['error'],

    clearAtracaoRequest: null
});

export const AtracaoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: false,
    loading: false,
    loadingCreate: false,
    error: false,
    atracaoId: false
});

const reducerAtracao = createReducer(INITIAL_STATE, {
    [Types.LOAD_ATRACAO_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_ATRACAO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, atracaoId: action.atracaoId, loading: false, error: false }),
    [Types.LOAD_ATRACAO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
    
    [Types.SAVE_ATRACAO_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false}),
    [Types.SAVE_ATRACAO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, atracaoId: action.atracaoId, loadingCreate: false, error: false }),
    [Types.SAVE_ATRACAO_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),

    [Types.CLEAR_ATRACAO_REQUEST]: (state, action) =>
        state.merge(INITIAL_STATE),
});

export const reducer = combineReducers({dadosBasicos: reducerAtracao, caracterizadores: reducerCaracterizadores});