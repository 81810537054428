import React, { useEffect,useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './styles';
import TabPanel from '../../TabPanel';
import Periodo from './Periodo';
import { useDispatch, useSelector } from 'react-redux';
import PeridosActions from '../../../store/ducks/agenda/periodos'
import Loading from '../../Loading';
import lodash from 'lodash';
import Error from '../../Error';
import DialogSair from '../../Dialogs/DialogSair';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Agenda({atracaoId, atracaoItemId, isFormEdited, setIsFormEdited}) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, loading, error} = useSelector((state) => state.periodos);
  const [tabOpen, setTabOpen] = useState(0);
  const [nextTab, setNextTab] = useState(0);
  const [alert, setAlert] = useState(false);

  // carrega os periodos, se não tive periodo trazer um vazio para ser o primerio do formulário, 
  
  const handleChange = (event, newValue) => {
    if(!isFormEdited || newValue === tabOpen){
      alterTab(newValue);
    }else{
      setAlert(true);
      setNextTab(newValue);
    }
  };
  

  const alterTab = (newTab) => {
    setTabOpen(newTab);
    setAlert(false);
  }

  
  useEffect(() => {
    dispatch(PeridosActions.loadPeriodosRequest(atracaoId, atracaoItemId));
    return () => {
      dispatch(PeridosActions.clearPeriodosRequest());
    }
  }, [dispatch,atracaoId,atracaoItemId])

  return (
    <div className={classes.root}>
      {loading && <Loading loading={loading} />}
      {!loading && <>
        <Tabs orientation="vertical" variant="scrollable" textColor="primary" indicatorColor="primary" value={tabOpen} onChange={handleChange} aria-label="Perídos" className={classes.tabs} TabScrollButtonProps={{style:{height: 20}}}>
          {lodash.map(data, (item, key) => (
            <Tab label={item?.nom_agrupador_agenda} {...a11yProps(key)}  />
          ))}
          <Tab label="+ Novo Período" {...a11yProps(lodash.size(data))} />
        </Tabs>
        <TabPanel value={tabOpen} index={lodash.size(data)} >
          <Periodo atracaoId={atracaoId} atracaoItemId={atracaoItemId} isFormEdited={isFormEdited} setIsFormEdited={setIsFormEdited}/>
        </TabPanel>
        {lodash.map(data, (item, key) => (
          <TabPanel value={tabOpen} index={key} >
            <Periodo atracaoId={atracaoId} atracaoItemId={atracaoItemId} dadosPeriodo={item} isFormEdited={isFormEdited} setIsFormEdited={setIsFormEdited}/>
          </TabPanel>
        ))}
      </>}
      <Error error={error} showAlert={false} alertMessage={error} />
      <DialogSair open={alert} close={() => setAlert(false)} discart={() => alterTab(nextTab)} />

    </div>
  );
}