import { FormControl, InputLabel, MenuItem, TextField, Select as SelectMaterial, makeStyles, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React  from 'react';
import lodash from 'lodash';

const useStyles = makeStyles((theme) => ({
    chipAutocomplete:{
        maxWidth: 120
    }
}));

export default function Select({component, label, value, setValue, defaultValue, options, optionLabel, optionKey, renderInputProps, InputProps, disabled, multiple, placeholder, error, ...rest}){
    const classes = useStyles();


    if(component === 'autocomplete'){
        return  <Autocomplete
                    title={label}
                    options={options ?? []}
                    getOptionLabel={ (option) => option[optionLabel] ?? ''}
                    getOptionSelected={(option, value) => option && value && (option[optionKey] === value[optionKey]) }
                    value={value}
                    onChange={ setValue}
                    size="small"
                    disabled={disabled}
                    renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    fullWidth 
                                    placeholder={placeholder ?? label} 
                                    variant="filled" 
                                    label={label} 
                                    size="small" 
                                    autoComplete="off"
                                    InputProps={{
                                        ...params.InputProps,
                                        ...InputProps
                                    }}
                                    {...renderInputProps}
                                />
                            }
                    multiple={multiple}
                    ChipProps={{size: "small", classes:{root: classes.chipAutocomplete}}}
                    getLimitTagsText={(more) => <Chip label={`+${more}`} className={classes.chipAutocomplete} size="small"></Chip>}
                    {...rest}
                />
    }else{
       return  <FormControl {...rest} >
                    {label && <InputLabel shrink >{label}</InputLabel>}
                    <SelectMaterial
                        value={value}
                        onChange={setValue}
                        disabled={disabled}
                        defaultValue={defaultValue}
                    > 
                        {lodash.map(options, (campo,key) => (
                            <MenuItem value={campo.value} key={`${campo}_${key}`} >{campo.title}</MenuItem>
                        ))}
                    </SelectMaterial>
                </FormControl> 
    }
    
}