import React from 'react';
import { Route } from 'react-router-dom';

import ValidaRedirect from './ValidaRedirect';
export default function RouteWrapper({ 
    component: Component, 
    layout: Layout, 
    ...rest
  }) {
    /**    
    * If not included on both previous cases, redirect user to the desired route.    
    */   
    if(Layout){
      return <Route {...rest} render={(props) => {
        return <ValidaRedirect {...rest} {...props}><Layout  {...props}><Component {...props}/></Layout></ValidaRedirect>}
      } />; 
    }
    return <Route {...rest} render={(props) => {
      return <ValidaRedirect {...rest} {...props}><Component {...props}/></ValidaRedirect>}
    } />; 
    

  }