import { createMuiTheme } from '@material-ui/core/styles';
function createTheme(theme){
  return createMuiTheme(theme);
}
export default createTheme;
export const defaultTheme = {
  shape: {
    borderRadius: 5
  },
  props: {
    // Nome do componente ⚛️
    
    MuiInputBase: {
      style: {
        backgroundColor: '#FFFF',
        color:"#5B5858",
      }
    },
    MuiFormLabel: {
      style: {
        color: '#78c8c8'
      }
    },
    
  },
  palette: {
    primary: {
      type:'dark',
      main: '#78c8c8',
      dark: '#60a0a0',
      contrastText: '#FFF',
      light: '#78c8c8'
    },
    
    secondary: {
      light: 'rgb(237 177 237)',
      main: '#ea5a8d',
      contrastText: '#FFF',
    },
    typography: {
      button: {
        fontSize: '2323rem',
      },
    },
    action:{
      //BOTOES
      disabled: '#CCC',
      disabledBackground: 'white',
      disabledOpacity: 0.38,
    },
    background:{
      default: 'transparent',
      paper:'#fff6'
    },
    text: {
      primary:"#5B5858",
      secondary:'#FFF',
      disabled:'#ccc'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    tonalOffset: 0.2,
  }
};



// {"body": {"background": "url(https://xmeet-arquivos.s3-sa-east-1.amazonaws.com/BRADESCO/PEN20/Fundo_tela.png)", "min-height": "100vh", "background-position": "center", "background-repeat-x": "no-repeat", "background-repeat-y": "repeat", "background-position-x": "center"}, "props": {"MuiFormLabel": {"style": {"color": "#5b5b5b"}}, "MuiInputBase": {"style": {"backgroundColor": "rgba(255,255,255,1)"}}}, "shape": {"borderRadius": 15}, "externa": {"overrides": {"__MuiContainer": {"__root": {"backgroundColor": "#EDEDED"}, "__maxWidthXs": {"maxWidth": "600px!important", "marginTop": "-65px", "minHeight": "100vh", "paddingTop": "65px", "paddingLeft": "40px", "paddingRight": "40px"}}}}, "interna": {"overrides": {"MuiAppBar": {"colorPrimary": {"background-color": "rgba(0,0,0,.4) !important"}}, "MuiContainer": {"root": {"background": "transparent"}, "maxWidthXs": {"height": "initial", "marginTop": "initial", "minHeight": "initial", "paddingTop": "initial"}}}}, "palette": {"text": {"hint": "#6b6b6b", "primary": "#6b6b6b", "disabled": "#bdbdbd", "secondary": "#ffffff"}, "action": {"disabled": "#bdbdbd", "disabledOpacity": 0.38, "disabledBackground": "rgba(135, 9, 15,0.8)"}, "primary": {"main": "#000000", "type": "dark", "contrastText": "#ffffff"}, "secondary": {"dark": "#5b5b5b", "main": "#aa035f", "light": "#000", "contrastText": "#FFF"}, "background": {"paper": "#FFF", "default": "#FFF"}, "typography": {"button": {"fontSize": "2323rem"}}, "tonalOffset": "0.2", "contrastThreshold": "3"}, "typography": {"fontFamily": "Bradesco Sans"}, "logoLoginSecundaria": "https://xmeet-arquivos.s3-sa-east-1.amazonaws.com/BRADESCO/PEN20/Logo_baixo.png"}