import React from 'react';
import PropTypes from 'prop-types';

export default function Body(props) {
    if(props.style){
        for(let prop in props.style){
            document.body.style.setProperty(prop, props.style[prop], "important");
        }
    }
  return (<>{props.children}</>);
}
Body.propTypes = {
    style: PropTypes.object,
};