import psl from 'psl';
let dominioAcesso = psl.parse(window.location.hostname);
let domainAPI = process.env.REACT_APP_API_URL;
if(!domainAPI){
    domainAPI = process.env.REACT_APP_PREFIX_API_URL;
    if(dominioAcesso.domain){
        domainAPI += dominioAcesso.domain;
    }
}
if(process.env.REACT_APP_HTTP_PORTA !== "80"){
    domainAPI = domainAPI+ ':'+process.env.REACT_APP_HTTP_PORTA;
}

const configEnv = {
    tenor:{
        url: process.env.REACT_APP_PREFIX_TENOR_URL,
        key: process.env.REACT_APP_TENOR_API_KEY
    },
    url: {
        DOMINIO: dominioAcesso.input??'localhost',
        API_URL: domainAPI,
        WSHOST: process.env.REACT_APP_WSHOST,
        WSSPORT: process.env.REACT_APP_WSSPORT,
        WSPORT: process.env.REACT_APP_WSPORT,
        HTTPS_PORTA: process.env.REACT_APP_HTTPS_PORTA,
        HTTP_PORTA: process.env.REACT_APP_HTTP_PORTA,
        WSENCRYPTED:process.env.REACT_APP_WSENCRYPTED==="true"?true:false
   }
}
export const config = configEnv;
