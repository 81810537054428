import { all, takeLatest } from 'redux-saga/effects';

import { AtracoesTypes } from '../../ducks/atracao/atracoes';
import { AtracaoTypes } from  '../../ducks/atracao/atracao';
import { AtracaoCaracterizadoresTypes } from  '../../ducks/atracao/caracterizadores';
import { TiposAtracaoTypes } from '../../ducks/atracao/tipos';

import * as atracao from './atracao';
import * as atracoes from './atracoes';
import * as caracterizadores from './caracterizadores';
import * as tipos from './tipos';

export const atracaoEffects = all([
        
        takeLatest(AtracoesTypes.LOAD_ATRACOES_REQUEST, atracoes.loadAtracoesRequest),

        takeLatest(AtracaoTypes.LOAD_ATRACAO_REQUEST, atracao.loadAtracaoRequest),
        takeLatest(AtracaoTypes.SAVE_ATRACAO_REQUEST, atracao.saveAtracaoRequest),
        takeLatest(AtracaoCaracterizadoresTypes.LOAD_ATRACAO_CARACTERIZADORES_REQUEST, caracterizadores.loadAtracaoCaracterizadoresRequest),
        takeLatest(AtracaoCaracterizadoresTypes.SAVE_ATRACAO_CARACTERIZADORES_REQUEST, caracterizadores.saveAtracaoCaracterizadoresRequest),
        takeLatest(TiposAtracaoTypes.LOAD_TIPOS_ATRACAO_REQUEST, tipos.loadTiposAtracaoRequest),
    ]
)