import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadStatusRequest: null,
    loadStatusSuccess: ['data'],
    loadStatusFailure: ['error'],

    createAglomeracaoRequest: ['atracaoId','atracaoItemId', 'dados', 'callback'],
    createAglomeracaoSuccess: ['data'],
    createAglomeracaoFailure: ['error'],
});

export const AglomeracaoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false,
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_STATUS_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOAD_STATUS_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_STATUS_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
 
    [Types.CREATE_AGLOMERACAO_REQUEST]: (state, action) =>
        state.merge({ loading: true, error: false}),
    [Types.CREATE_AGLOMERACAO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false}),
    [Types.CREATE_AGLOMERACAO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error}),
});
