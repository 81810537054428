import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

export default function Loading({loading, containerProps,...props}){
    
    let theme         = useTheme();

    return loading ? <Box display="flex" justifyContent="center" margin={4} ><CircularProgress  style={{color: theme.palette.text.primary}} {...props}/></Box> : <></>;

}