import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadMenuRequest: null,
    loadMenuSuccess: ['data'],
    loadMenuFailure: ['error'],

    setMenuSelecionadoRequest: ['menuSelecionado'],
    setMenuSelecionadoSuccess: ['menuSelecionado'],
   
});

export const MenuTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    menuSelecionado: {},
    error: false,
    loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_MENU_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOAD_MENU_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_MENU_FAILURE]: (state, action) =>
        state.merge({ loading: false,  error: action.error }),
   
    [Types.SET_MENU_SELECIONADO_SUCCESS]: (state, action) =>
        state.merge({menuSelecionado: action.menuSelecionado}),


});
