import { all, takeLatest } from 'redux-saga/effects';

import { ProgramacaoTypes } from '../../ducks/programacao/programacao';
import { ProgramacaoItemTypes } from  '../../ducks/programacao/programacaoItem';
import { ProgramacaoItemCaracterizadoresTypes } from  '../../ducks/programacao/caracterizadores';

import * as programacaoItem from './programacaoItem';
import * as programacao from './programacao';
import * as caracterizadores from './caracterizadores';

export const programacaoEffects = all([
        
        takeLatest(ProgramacaoTypes.LOAD_PROGRAMACAO_REQUEST, programacao.loadProgramacaoRequest),
        takeLatest(ProgramacaoItemTypes.LOAD_PROGRAMACAO_ITEM_REQUEST, programacaoItem.loadProgramacaoItemRequest),
        takeLatest(ProgramacaoItemCaracterizadoresTypes.LOAD_PROGRAMACAO_ITEM_CARACTERIZADORES_REQUEST, caracterizadores.loadProgramacaoItemCaracterizadoresRequest),
        takeLatest(ProgramacaoItemCaracterizadoresTypes.SAVE_PROGRAMACAO_ITEM_CARACTERIZADORES_REQUEST, caracterizadores.saveProgramacaoItemCaracterizadoresRequest),

        takeLatest(ProgramacaoItemTypes.SAVE_PROGRAMACAO_ITEM_REQUEST, programacaoItem.saveProgramacaoItemRequest),

    ]
)