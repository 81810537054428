import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from './select';
import AcomodacoesActions from '../../../store/ducks/acomodacoes';


export default function SelectAcomodacao({ label, value, onChange, renderInputProps, InputProps, disabled, error, ...rest}){
    const dispatch = useDispatch();

    const acomodacoes  = useSelector(state => state.acomodacao.lista);

    const buscaAcomodacoes = useCallback((e) => {
        dispatch(AcomodacoesActions.loadAcomodacoesRequest(e?.target?.value));
    },[dispatch])

    useEffect(() => {
        buscaAcomodacoes()
    }, [dispatch,buscaAcomodacoes])
  
    return (
        <Select
            fullWidth
            component="autocomplete"
            label="Acomodação"
            disabled={acomodacoes.error}
            options={acomodacoes.data?.data}
            optionKey={'acomodacao_id'}
            optionLabel={'nom_acomodacao'}
            value={value}
            setValue={(e,value) => onChange(value)}
            loading={acomodacoes.loading}
            renderInputProps={{onChange: (e) =>  buscaAcomodacoes(e)}}
            {...rest}
         />
    )
    
    
    
}

