import React, {useRef, useState} from 'react';
import { Button, Popper, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import lodash from 'lodash';

function MenuListMeet({id, children, menuItens, buttonClasses, ariaLabel}) {
    const anchorRef             = useRef(null);

    const [openMenu, setOpenMenu] = useState(false);

    const handleToggle = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenu(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open   
    const prevOpenMenu = useRef(openMenu);
    React.useEffect(() => {
        if (prevOpenMenu.current === true && openMenu === false) {
            anchorRef.current.focus();
        }
        prevOpenMenu.current = openMenu;
    }, [openMenu]);

    
    return (
    <>
        <Button 
            id={id}
            color="inherit" 
            ref={anchorRef}
            aria-controls={openMenu ? 'menu-list-grow' : undefined}
            onClick={()=>handleToggle()}
            classes={buttonClasses}
            aria-label={ariaLabel}
        > 
            {children}
        </Button> 
        <Popper open={openMenu} anchorEl={anchorRef.current} transition disablePortal onClick={handleClose} aria-label="Opções" style={{minWidth: 150}}>
            <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        {
                            lodash.map(menuItens, (menuItem, key) => {
                                return menuItem?.show === true && <MenuItem {...menuItem} key={key} style={{color: '#000000'}}>{menuItem.children}</MenuItem>
                            })
                        }
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    </>
  );
}

export default MenuListMeet;