import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './styles';

import Agenda from '../AgendaForm/index';
import DadosBasicos from './DadosBasicos';
import Caracterizadores from './Caracterizadores';
import TabPanel from '../../TabPanel';


import {  useSelector } from 'react-redux';
import DialogSair from '../../Dialogs/DialogSair';
import { Paper } from '@material-ui/core';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProgramacaoItemForm({atracaoId, atracaoItemId, openTab}) {

  const classes     = useStyles();

  const {atracaoItemId: atracaoItemAtual} = useSelector(state => state.programacao.itemCrud.dadosBasicos);

  const [tabOpen, setTabOpen]           = useState(0);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [alert, setAlert]               = useState(false);
  const [nextTab, setNextTab]           = useState(false);

  const handleChange = (event, newValue) => {
    if(!isFormEdited || newValue === tabOpen){
      alterTab(newValue);
    }else{
      setAlert(true);
      setNextTab(newValue);
    }
  };

  const alterTab = (newTab) => {
    setTabOpen(newTab);
    setAlert(false);
  }
  
  useEffect(() => {
    if(openTab){
      setTabOpen(openTab)
    }
  },[openTab])
 
  return (<>
    <Paper square className={classes.root} elevation={1}>
      <AppBar  position="static" color="default" elevation={0} > 
        <Tabs value={tabOpen} variant="fullWidth"  onChange={handleChange} aria-label="Navegação - Formulário de Programação " textColor="secondary"  scrollButtons="auto">
          <Tab label="Dados Básicos" {...a11yProps(0)} />
          <Tab label="Caracterizadores" {...a11yProps(1)} disabled={!atracaoItemAtual}/>
          <Tab label="Agenda" {...a11yProps(2)}  disabled={!atracaoItemAtual} />
        </Tabs>
      </AppBar>

      <TabPanel value={tabOpen} index={0}>
        <DadosBasicos atracaoId={atracaoId} atracaoItemId={atracaoItemAtual}  isFormEdited={isFormEdited} setIsFormEdited={setIsFormEdited} />
      </TabPanel>
      <TabPanel value={tabOpen} index={1}>
        <Caracterizadores  atracaoId={atracaoId} atracaoItemId={atracaoItemAtual}  isFormEdited={isFormEdited} setIsFormEdited={setIsFormEdited} />
      </TabPanel>
      <TabPanel value={tabOpen} index={2} padding={0}>
        <Agenda   atracaoId={atracaoId} atracaoItemId={atracaoItemAtual}  isFormEdited={isFormEdited} setIsFormEdited={setIsFormEdited} />
      </TabPanel>
    </Paper>
    <DialogSair open={alert} close={() => setAlert(false)} discart={() => alterTab(nextTab)} />
    </>
  );
}