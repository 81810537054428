import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
  textSmall:{
    fontSize:`${theme.typography.pxToRem(14)} !important`,
    width:'100%'
  },
  inputView: {
    height: '0px !important',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    padding:`${theme.spacing(0.9)}px !important`,
  },
  padding:{
    paddingTop:'80px'
  },
  iconImg:{
    fontSize:"1.5em",
    height: '25px',

  },
  listaCards:{
    display:'flex',
    flexWrap: "wrap",
    justifyContent:"left"
  },
  cardView:{
    width:'150px',
    height:'100px',
    maxWidth: '150px',
    margin:`${theme.spacing(2)}px`,
    background:'#FFF',
    border: `${theme.spacing(.3)}px solid ${theme.palette.primary.main}`,
    display:"flex",
    flexDirection:"column"
  },
  icone:{
    flexGrow:4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  nome:{
    flexGrow:1,
    textAlign: 'center',
    color: theme.palette.secondary.dark,
    fontWeight: 'bold'
  },
  link:{
    textDecoration:"none"
  }
  }));