import { call, put} from 'redux-saga/effects';
import api from '../../config/axios';
import PerfilActions from '../ducks/perfil';
import {param} from 'jquery';

export function* loadPerfilRequest({busca}) {
    try {

        let request =  `perfil` ;
        
        let filtro  = {};

        if(busca){
            filtro = {nom_perfil: busca}
        }

        let response = yield call(api.get, `${request}?${param(filtro)}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        yield put(PerfilActions.loadPerfilSuccess(response.data));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar perfis."
        yield put(PerfilActions.loadPerfilFailure(error));
    }
}
