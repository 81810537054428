import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    endOfYear
} from 'date-fns';
  
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfNow: new Date(),
    endOfYear: endOfYear(new Date())
  };
  
  const staticRangeHandler = {
    range: { },
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };
  
  export function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range}));
  }
  
  export const staticRanges = createStaticRanges([
    {
      label: 'Hoje',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
        filter: 'today'
      }),
    },
    {
      label: 'Essa Semana',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        filter: 'this_week'
      }),
    },
    {
      label: 'Esse mês',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        filter: 'this_month'
      }),
    },
    {
      label: 'A partir de agora',
      range: () => ({
        startDate: defineds.startOfNow,
        endDate:defineds.endOfYear,
        filter: 'start_now'
      }),
    },
   
  ]);
  
  export const inputRanges = [
  
  ];