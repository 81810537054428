import React, { useState } from 'react';
import Dialog from '../Dialog';
import ButtonCancel from '../../ButtonCancel';
import ButtonSuccess from '../../ButtonSuccess';
import Upload from '../../Upload';

function DialogUpload({open, close, confirm, title, uploadProps}) {

    const [files, setFiles] = useState(false);
	return (	
        <Dialog 
            open={open}
            close={close}
            title={title ?? `Upload`}
            content={
                <Upload 
                   {...uploadProps}
                   onUpload={(files) => setFiles(files)}
                />
            }
            actions={
                <>
                    <ButtonCancel onClick={close} aria-label="Cancelar Upload">
                        Cancelar
                    </ButtonCancel>
                    <ButtonSuccess onClick={() => confirm(files)} autoFocus aria-label="Confirmar Upload"> 
                        Confirmar
                    </ButtonSuccess>
                </> 
            }
        />
	)

}

export default  (DialogUpload);
