import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import { useStyles } from './styles';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright'
import loginActions from '../../store/ducks/login';
import CircularProgress from '@material-ui/core/CircularProgress';
import Password from '../../components/Form/Campo/password';
import Texto from '../../components/Form/Campo/texto';
import { useForm } from "react-hook-form";
import { Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import history from '../../routes/history';
import imagemLogo from '../../imagens/logo_going_padrao.jpg';

function Login(props) {

  const classes = useStyles();

  let login         = useSelector(state=>state.login);
  let tema          = useSelector(state=>state.tema.data);

  let dispatch      = useDispatch();

  const {  errors, register, handleSubmit } = useForm();

  const clickLogin = (dados) => {
    dispatch(loginActions.loginRequest(dados?.username, dados?.password));
  };

  let logo   = tema?.arq_logo_instalacao ?? imagemLogo;

  return (<>
    <div className={classes.root}>
      <Container  maxWidth="md" className={classes.container} >
        <Paper  elevation={3} variant="outlined" className={classes.paper} >
          {logo && <><div  className={`${classes.bloco} ${classes.blocoLogo}`} > 
              <img src={logo} className={classes.logoLogin} alt={'Logomarca'} />
            </div>
            <div className={classes.divider}></div></>
          }
          <div className={`${classes.bloco} ${classes.blocoForm}`} > 
            <Typography variant="h6" className={classes.title}> Faça seu login</Typography>
            <form className={classes.form} noValidate>
              <Texto 
                fullWidth
                label="E-mail"
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleSubmit(clickLogin);
                  }
                }}
                autoFocus
                name="username"
                inputRef={register({ required: true })}
                error={errors?.username ||  login.error !== false}
                helperText={errors?.username && "Digite um e-mail."}
              />
              <Password
                id={"password"}
                label="Senha"
                name="password"
                inputRef={register({ required: true })}
                onPressEnter={handleSubmit(clickLogin)}
                error={(errors?.password && !errors.username) || login.error  !== false}
                errorMessage={<>
                  { errors?.password && "Digite uma senha."  }
                  {login.error && login.error?.message }
                  </>}
              />
              <Link className={classes.link} onClick={() => history.push("/esqueciSenha")}> Esqueci minha senha</Link>
              <Button 
                variant="outlined" 
                color="secondary"  
                className={classes.submit} 
                onClick={handleSubmit(clickLogin)} 
                disabled={login.loading} 
                aria-label="Entrar"
              >
                 {login.loading && <CircularProgress color="primary" size={23} />}{'Entrar'}
              </Button>
            </form>
          </div>
          <Box  display={{ xs: 'block', sm: 'none', md: 'none', lg: 'none' }} >
           <Copyright />
          </Box>
        </Paper>
        
      </Container>
      <Box  display={{ xs: 'none', sm: 'block', md: 'block', lg: 'block' }} mt={8}>
        <Copyright />
      </Box>        
    </div>
    {/* {!login.loading && <Error error={login?.error} alertMessage={login?.error?.message}  showAlert={true} duration={6000}/> } */}

    </>
  );
}
export default (Login);