import { call, put} from 'redux-saga/effects';
import api from '../../config/axios';
import UploadArquivoActions from '../ducks/upload';
import lodash from 'lodash';

export function* uploadArquivoRequest({arquivo, tipo}) {
    try {

        if(!arquivo){
            throw Error("Arquivo inválido!");
        }

        let arquivoEnvio = null;
        let header = {};
        
        if(lodash.isObject(arquivo)){
            arquivoEnvio = new FormData();
            arquivoEnvio.append('file',arquivo[0]);
            if(tipo) {
                arquivoEnvio.append('tipo',tipo);
            }    
            header = {headers: {"Content-Type": "multipart/form-data"}};
        }else{
            arquivoEnvio = {
                file: arquivo,
                tipo: tipo??false
            } 
        }

        let response = yield call(api.post, `upload`, arquivoEnvio, header);
        yield put(UploadArquivoActions.uploadArquivoSuccess(response?.data?.content?.file));

    } catch (error) {
        error.message = error.message ?? "Erro ao realizar upload do arquivo."
        yield put(UploadArquivoActions.uploadArquivoFailure(error));
    }
}
