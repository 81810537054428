import { call, put, select} from 'redux-saga/effects';
import api from '../../../config/axios';
import AgendaPeriodoActions from '../../ducks/agenda/agendaPeriodo';
import SuccessActions from '../../ducks/success';

import {param} from 'jquery';
import lodash from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';

function trataRowsGrid(dados){
    
    let rows = lodash.map(dados, (item, key) => {
        return {
            ...item,
            id: key, 
            dt_agenda_grid: moment(item?.dt_agenda).format("DD/MM/YYYY"),
            dia_semana:  lodash.startCase(moment(item?.dt_agenda).format("dddd")) ,
            actions: ' '
        };
    });
    return rows;
}


function* atualizaLista(agendaId, newValues, create, remove){
    let periodosLista  = (yield select((state) => state.agendaPeriodo.data.asMutable()));
    let periodos  = [...periodosLista?.data] ?? [];

    if(create) {
       periodos.push({...newValues});
    }else if(remove){
        periodos = lodash.filter(periodos, (value) => value.agenda_id !== agendaId);
    }else{
        periodos = lodash.map(periodos, (item, key) => {
            if(item.agenda_id === agendaId){
                return { ...item, ...newValues, id: key }
            }
    
            return {...item, id: key};
        });
    }
    
    return  {...periodosLista, data: yield trataRowsGrid(periodos)};
}

export function* loadAgendaPeriodoRequest({ atracaoId, atracaoItemId, agendaSetupId, filtros, page}) {
    try {

        let pagina = page ?? (yield select((state) => state.agendaPeriodo.page));

        let response = yield call(api.get, `agenda?${param({atracao_id: atracaoId, atracao_item_id: atracaoItemId, agenda_setup_id: agendaSetupId, ...filtros})}&page=${pagina}`);
        
        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        let data = yield trataRowsGrid(response.data?.data);
        response.data.data = data;

        yield put(AgendaPeriodoActions.loadAgendaPeriodoSuccess(agendaSetupId, response.data, page));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar agenda do período."
        yield put(AgendaPeriodoActions.loadAgendaPeriodoFailure(error));
    }
}


function trataHora(hora) {

    let newHora = hora.split(':');

    if(hora){
        if(lodash.size(newHora) > 2){
            return hora;
        }else{
            return hora+":00"
        }
    }

    return false

}


export function* saveAgendaPeriodoRequest({agendaSetupId, agendaId, dados, callback}) {
    try {

        if(!agendaId){
            throw Error("Agenda não encontrada.");
        }

        let page = yield select((state) => state.agendaPeriodo.page);

        let newDados = { ...dados, hr_agenda: trataHora(dados?.hr_agenda)}

        const response = yield call(api.put, `agenda/${agendaId}`, newDados);

        newDados = { ...dados,  ...response?.data?.data }
        let newLista = yield atualizaLista( agendaId, newDados, false);

        yield put(SuccessActions.setSuccess(true, 'Agenda do período salva com sucesso!'));
        yield put(AgendaPeriodoActions.loadAgendaPeriodoSuccess(agendaSetupId, newLista, page));
        yield put(AgendaPeriodoActions.saveAgendaPeriodoSuccess());

        if(callback && typeof callback === 'function'){
            callback();
        }

    } catch (error) {
        error.message =  error.message ?? "Erro ao salvar período da agenda."
        yield put(AgendaPeriodoActions.saveAgendaPeriodoFailure(error));
    }
}


export function* removeAgendaPeriodoRequest({ agendaSetupId, agendaId}) {
    try {

        if(!agendaId){
            throw Error("Agenda não encontrada.");
        }

        let page = yield select((state) => state.agendaPeriodo.page);

        let confirm = yield Swal.fire({
            title: "Exclusão de agenda do período", 
            text: "Confima a exclusão da agenda do período?",
            icon: '',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonText: "SIM",
            reverseButtons: true
        });

        if(confirm.isConfirmed){
            yield call(api.delete, `agenda/${agendaId}`);

            let newLista = yield atualizaLista(agendaId, false, false, true);

            yield put(SuccessActions.setSuccess(true, 'Agenda do período removida com sucesso!'));

            yield put(AgendaPeriodoActions.loadAgendaPeriodoSuccess(agendaSetupId, newLista, page));
        }

        yield put(AgendaPeriodoActions.removeAgendaPeriodoSuccess());


    } catch (error) {
        error.message =  error.message ?? "Erro ao remover agenda do período."
        yield put(AgendaPeriodoActions.removeAgendaPeriodoFailure(error));
    }
}


