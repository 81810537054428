import { call, put } from 'redux-saga/effects';
import api from '../../config/auth';
import lodash from 'lodash';
import UsuarioInstalacaoActions from '../ducks/usuarioInstalacao';
import Swal from 'sweetalert2';

export function* loadInstalacoesUsuarioRequest() {
    try {
        let response = yield call(api.get, 'usuario/instalacao');

        let instalacoes = {}
        lodash.forEach(response?.data?.data, (instalacao) => {
            instalacoes[instalacao.instalacao_id] = instalacao.nom_instalacao
        })

        yield put(UsuarioInstalacaoActions.loadInstalacoesUsuarioSuccess(instalacoes));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar instalacoes."
        yield put(UsuarioInstalacaoActions.loadInstalacoesUsuarioFailure(error));
    }
}

export function* updateInstalacaoUsuarioRequest({instalacaoId}) {
    try {
        yield call(api.put, `usuario/instalacao/${instalacaoId}`);
        window.location.reload();
    } catch (error) {
        Swal.fire({
            icon: 'error',
            text: "Erro ao alternar instalação"
        })
    }
}
