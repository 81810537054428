import React, { useCallback, useEffect } from 'react'
import withWidth from '@material-ui/core/withWidth'
import Page from '../../components/Page'
import { Button, Avatar } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import ComunicacoesActions from '../../store/ducks/comunicacao/comunicacoes'
import DataGrid from '../../components/DataGrid'
import { useStyles } from './styles'
import history from '../../routes/history'
import lodash from 'lodash'

function Comunicacoes(props) {
    const dispatch = useDispatch()
    const classes     = useStyles();

    const { lista, meta, loading, error } = useSelector(state=>state.comunicacao.lista)
    const { optionsCodDestino, optionsCodTipo } = useSelector(state=>state.comunicacao.lista)

    const handleImagem = (params) => (
        <div className={classes.rowFoto} >
            <Avatar
                alt={params.row?.des_titulo}
                src={params.row?.arq_imagem_chamada}
                className={classes.foto}
                variant="square"
            />
        </div>
    )

    const handleRowActions = (row) => (
        <div style={{width: '100%', textAlign: 'end'}} >
            <Button
                variant="outlined" 
                color="secondary" 
                size="small"
                startIcon={<FontAwesomeIcon icon="edit" className={classes.icon} />}
                onClick={() => onClickRow(row)}
            >
                Editar 
            </Button>
        </div>
    )

    const handleCodTipo = (row) => {
        let codTipo = lodash.find(optionsCodTipo, { value: row.cod_tipo })
        return codTipo?.title
    }

    const handleCodDestino = (row) => {
        let codDestino = lodash.find(optionsCodDestino, { value: row.cod_destino })
        return codDestino?.title
    }

    const columns  = [
        { field: 'arq_imagem_chamada', headerName: 'Imagem', renderCell: (params) => handleImagem(params), flex: 1},
        { field: 'des_titulo', headerName: 'Titulo', flex: 1 },
        { field: 'cod_tipo', headerName: 'Tipo', flex: 1, renderCell: (params) => handleCodTipo(params.row) },
        { field: 'cod_destino', headerName: 'Destino', flex: 1, renderCell: (params) => handleCodDestino(params.row) },
        { field: 'actions', headerName: ' ', renderCell: (params) =>  handleRowActions(params.row), flex: 1 }
    ]

    const conteudoDireita = (
        <Button
            variant='outlined'
            color='primary'
            startIcon={<FontAwesomeIcon icon='plus' />}
            onClick={() => onClickRow()}
        >
            Nova
        </Button>
    )

    const loadComunicacoes = useCallback((props) => {
        dispatch(ComunicacoesActions.loadComunicacoesRequest(false, false, props?.page))
    }, [dispatch])

    const onClickRow = (row) => {
        history.push(`/comunicacao/${row?.comunicacao_id??'nova'}`, { titulo: row?.des_titulo })
    }

    useEffect(() => {
        loadComunicacoes()
    }, [loadComunicacoes])

    return (<>
        <Page
            codControl={'MN_WEB_COMUNICACAO'}
            conteudoDireita={conteudoDireita}
            atualizar={loadComunicacoes}
            loading={loading}
        >
            <DataGrid
                reload={loadComunicacoes}
                error={error}
                loading={loading}
                columns={columns}
                rows={lista}
                rowCount={meta.total}
                pageSize={meta.per_page}
                onPageChange={loadComunicacoes}
            />   
        </Page>
    </>)
}

export default withWidth()(Comunicacoes)