import React, { useCallback, useEffect } from 'react';
import Dialog from '../../Dialogs/Dialog';
import ButtonSuccess from '../../ButtonSuccess';
import ButtonCancel from '../../ButtonCancel';
import { Box, CircularProgress} from '@material-ui/core';
import {useStyles} from './styles';
import { Controller, useForm } from 'react-hook-form';
import Texto from '../Campo/texto';
import Select from '../Campo/select';
import DateTime from '../Campo/dateTime';
import {useDispatch, useSelector} from 'react-redux';
import UsuariosActions from '../../../store/ducks/usuarios';
import ExperienciaActions from '../../../store/ducks/experiencia/experiencia';
import Inteiro from '../Campo/inteiro';
import SelectPerfil from '../Campo/selectPerfil';
import SelectAcomodacao from '../Campo/selectAcomodacao';
import { useControlMenu } from '../../../hooks/useControlMenu';

function ExperienciaForm({open, close, experiencia}) {

    const classes  = useStyles();
    const dispatch = useDispatch();
    
    const { control: controlMenu } = useControlMenu('MN_WEB_EXPERIENCIA', "Formulário - ");
    let titlePage =  `Formulário ${controlMenu?.des_label ? ' - '+ controlMenu?.des_label : ''}` ;

    let defaultValues = experiencia ? {...experiencia} : {cod_status_acesso: 'VALIDO'};

    const { register, control, errors, handleSubmit , watch} = useForm({defaultValues});

    const usuarios = useSelector(state => state.usuario.lista);
    const {loading: loadingSave}  = useSelector(state => state.experiencia.itemCrud);

    const perfilSelecionado = watch('perfil');

    const handleSave = (dados) =>{
        dispatch(ExperienciaActions.saveExperienciaRequest(experiencia?.experiencia_id, {...experiencia, ...dados}, () => close()));
    }

    const buscaUsuarios = useCallback((e) => {
        dispatch(UsuariosActions.loadUsuariosRequest(e?.target?.value));
    },[dispatch])


    useEffect(() => {
        buscaUsuarios()
    }, [dispatch, buscaUsuarios])

	return (	
        <Dialog  
            open={open} 
            close={()=> close()}
            title={titlePage}
            content={<>
                <Box minWidth={{sm: 600}} margin={.5}>
                    <form className={classes.form} noValidate >
                        <div className={classes.row}> 
                            <Controller 
                                name="usuario"  
                                control={control} 
                                rules={{required: true}}
                                render={({ onChange, value }) => <>
                                    <Select
                                        fullWidth
                                        component="autocomplete"
                                        label="Usuário"
                                        disabled={usuarios.error}
                                        options={usuarios.data?.data}
                                        optionKey={'usuario_id'}
                                        optionLabel={'nom_usuario'}
                                        value={value}
                                        setValue={(e,value) => onChange(value)}
                                        renderInputProps={{onChange: (e) =>  buscaUsuarios(e)}}
                                        loading={usuarios.loading}
                                    />
                                 </>
                                }
                            />
                        </div>
                        <div className={classes.row}>
                            <Texto 
                                fullWidth
                                label="Código de Acesso"
                                name="cod_chave_acesso"  
                                inputRef={register({ required: true })}
                                error={errors?.cod_chave_acesso}
                                helperText={errors?.cod_chave_acesso && "O campo é obrigatório."}
                                disabled={experiencia}
                            />
                            <Controller 
                                name={`qtd_pessoa`}
                                control={control} 
                                rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                                render={({ onChange, value }) => 
                                    <Inteiro 
                                        fullWidth
                                        label="Nº de Pessoas"
                                        error={errors?.qtd_pessoa}
                                        helperText={errors?.qtd_pessoa && "O campo é obrigatório."}
                                        setValue={onChange}
                                        value={value}
                                    />
                                
                                }
                            />
                        </div>
                        <div className={classes.row}>
                            <Controller 
                                name="perfil"  
                                control={control} 
                                rules={{required: true}}
                                render={({ onChange, value }) => <>
                                    <SelectPerfil value={value} onChange={onChange} />
                                </>
                                }
                            />
                            <Controller 
                                name="cod_status_acesso"  
                                control={control} 
                                rules={{required: true}}
                                render={({ onChange, value }) => 
                                    <Select 
                                        style={{width: 220}}
                                        label={"Status"}
                                        value={value}
                                        options={[{value: 'AGUARDE', title: 'Aguarde'}, {value: 'VALIDO', title: 'Válido'}, {value: 'FINALIZADO', title: 'Finalizado'}, {value: 'INVALIDO', title: 'Inválido'}]}
                                        setValue={onChange}
                                    />
                                }
                            />
                        </div>
                        <div className={classes.row}>
                            <Controller 
                                name="dt_experiencia_plan_ini"  
                                control={control} 
                                render={({ onChange, value }) => 
                                    <DateTime 
                                        fullWidth
                                        value={value}
                                        label="Dt. Planejamento Início"
                                        setValue={onChange}
                                        error={errors?.dt_experiencia_plan_ini}
                                        helperText={errors?.dt_experiencia_plan_ini && "O campo é obrigatório."}
                                        required={false}
                                    />
                                }
                            />
                            <Controller 
                                name="dt_experiencia_plan_fim"  
                                control={control} 
                                render={({ onChange, value }) => 
                                    <DateTime 
                                        fullWidth
                                        value={value}
                                        label="Dt. Planejamento Fim"
                                        setValue={onChange}
                                        error={errors?.dt_experiencia_plan_fim}
                                        required={false}
                                    />
                                }
                            />
                        </div>
                        <div className={classes.row}>
                            <Controller 
                                name="dt_experiencia_ini"  
                                control={control} 
                                render={({ onChange, value }) => 
                                    <DateTime 
                                        fullWidth
                                        value={value}
                                        label="Dt. Experiência Início"
                                        setValue={onChange}
                                        error={errors?.dt_experiencia_ini}
                                        required={false}
                                    />
                                }
                            />
                            <Controller 
                                name="dt_experiencia_fim"  
                                control={control} 
                                render={({ onChange, value }) => 
                                    <DateTime 
                                        fullWidth
                                        value={value}
                                        label="Dt. Experiência Fim"
                                        setValue={onChange}
                                        error={errors?.dt_experiencia_fim}
                                        required={false}
                                    />
                                }
                            />
                        </div>
                        {perfilSelecionado?.ind_acomodacao === 'S' && <div className={classes.row}>
                            <Controller 
                                name="acomodacao"  
                                control={control} 
                                render={({ onChange, value }) => 
                                    <SelectAcomodacao value={value} onChange={onChange} />
                                }
                            />
                        </div>}
                    </form>
               </Box>
                                
               </>
            }
            actions={
                <Box display="flex">
                    <ButtonCancel onClick={()=> close()} > Cancelar </ButtonCancel>
                    <ButtonSuccess autoFocus onClick={handleSubmit(handleSave)} disabled={loadingSave} >
                        {loadingSave && <CircularProgress size={15} /> } Salvar
                    </ButtonSuccess>
                </Box>
            }
        />     
    )

}

export default  (ExperienciaForm);
