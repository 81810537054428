import React  from 'react';
import Alert from '@material-ui/lab/Alert';
import {Snackbar} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SuccessActions from '../../store/ducks/success';

function Success() {
    
    const success = useSelector(state => state.success)
    const dispatch = useDispatch();

    let closeSuccess = ()=>{
        dispatch(SuccessActions.clearSuccess());
    }

	return (	
        <>
            <Snackbar  
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }} 
                open={success.show === true} 
                autoHideDuration={6000} 
                key={'success'}
                onClose={closeSuccess}
            >
                <Alert onClose={closeSuccess} severity="success">
                    {success?.message}
                </Alert>
            </Snackbar>
        </>
	)

}

export default  (Success);
