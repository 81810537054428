import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import lodash from 'lodash';

export const useControlMenu = (codControl) => {
    
    const [control, setControl] = useState(false);
    const menu    = useSelector(state => state.menu.data);

    useEffect(() => {
        if(codControl){
            let control = lodash.find(menu, (item) => {
                return item.cod_control === codControl;
            })

            if(control){
                setControl(control);
            }else{
                setControl(false);
            }
           
        }else{
            setControl(false);
        }
    }, [ menu, codControl]);

    return { control};
} 