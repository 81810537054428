import React,  { useState, useEffect, useCallback } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useStyles } from './styles';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright'
import LoginActions from '../../store/ducks/login';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Paper, useTheme } from '@material-ui/core';
import Error from '../../components/Error';
import Password from '../../components/Form/Campo/password';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagemLogo from '../../imagens/logo_going_padrao.jpg';

function RecuperaSenha(props) {
  
  const classes = useStyles();
  let usuario      = useSelector(state=>state.login.usuarioToken);
  let error         = useSelector(state=>state.login.errorUsuario);
  let loadingUpdate = useSelector(state=>state.login.loadingAlteraSenha);
  let errorUpdate   = useSelector(state=>state.login.errorAlteraSenha);
  let tema          = useSelector(state=>state.tema.data);

  let dispatch      = useDispatch();
  const theme       = useTheme();

  const [newPassword, setNewPassword]         = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [senhaAlterada, setSenhaAlterada]     = useState(false);

  const params  = useParams();

  let disableBotaoAlteraSenha = !confirmPassword || !newPassword;

  const clickAlterarSenha = useCallback((event)=>{
    event.preventDefault();

    if(!disableBotaoAlteraSenha){
      dispatch(LoginActions.recuperaSenhaRequest(params, {senha_nova: newPassword, confirma_senha: confirmPassword}, () => setSenhaAlterada(true)));
    }

  },[dispatch, newPassword, confirmPassword,disableBotaoAlteraSenha, params]);

  useEffect(()=>{
    if(params?.tokenSenha){
      dispatch(LoginActions.validaTokenSenhaRequest(params?.tokenSenha, params?.instalacao));
    }
  },[dispatch,params]);

  let logo   = tema?.arq_logo_instalacao ?? imagemLogo;


  return (
    <div className={classes.root}>
      <Container  maxWidth="sm" className={classes.container} >
        <Paper  elevation={3} variant="outlined" className={classes.paper} >
          {!senhaAlterada && <div  className={`${classes.bloco} ${classes.blocoLogo}`} > 
            {logo && <img src={logo} className={classes.logoLogin} alt={'Logomarca Costa do Sauípe'} />}
          </div>}
          <div className={classes.divider}></div>
          {usuario?.usuario &&  <div className={`${classes.bloco} ${classes.blocoForm}`} > 
            <Typography variant="h6" className={classes.title}>{usuario?.usuario?.nom_usuario && `Olá, ${usuario?.usuario?.nom_usuario}!` } </Typography>
            <Typography variant="h6" className={classes.title}> Redefina sua senha: </Typography>

            <form className={classes.form} noValidate>
                <Password
                  label={"Nova Senha"}
                  onChange={(e) => setNewPassword(e.target.value)}
                  showRegraSenha={true}
                  className={classes.textField}
                  onPressEnter={clickAlterarSenha}
                />
                <Password
                  label={"Confirmação de Senha"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={classes.textField}
                  onPressEnter={clickAlterarSenha}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={clickAlterarSenha}
                  disabled={disableBotaoAlteraSenha}
                  
                >
                  {!loadingUpdate && 'Confirmar'}
                  {loadingUpdate && <CircularProgress color="primary" size={23} />}
                  
                </Button>
              </form>
          </div>
          }

          {error &&  
            <div className={`${classes.bloco} ${classes.blocoForm}`} > 
              <Typography variant="h6" className={classes.title}> Redefinição de senha </Typography>
              <Typography  variant="subtitle1" align="center" style={{color: theme.palette.error.main, marginTop: theme.spacing(3)}} >{error.message}</Typography>
             </div>
          }

          {senhaAlterada && 
            <div className={`${classes.bloco} ${classes.blocoForm}`} >
              <Box textAlign="center">
                <FontAwesomeIcon icon="check-circle" color={theme.palette.success.main} size='5x' style={{margin: theme.spacing(2)}}/> 
                <Typography variant="h6" className={classes.title}> Senha alterada com sucesso! </Typography>
                <Typography  variant="subtitle2" align="center" style={{marginTop: theme.spacing(2)}} >Acesse o aplicativo para realizar o login.</Typography>
              </Box>
            </div>
          }

        </Paper>
        
      </Container>
      <Box  display={{ xs: 'none', sm: 'block', md: 'block', lg: 'block' }} mt={8}>
        <Copyright />
      </Box>      
      <Error error={errorUpdate} showAlert={false} alertMessage={errorUpdate} />
  
    </div>
  );
}
export default (RecuperaSenha);