import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      background: '#F8F8F8',
      '& .MuiDataGrid-colCellWrapper':{
        background: theme.palette.primary.light,
        height: '30px',
        width: '100% !important',
        display: 'flex'
      },
      '& .MuiDataGrid-colCell':{
        flex: 1,
      },
      '& .MuiDataGrid-colCellTitle':{
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
      },
      '& .MuiDataGrid-row':{
        background: 'white',
        width: "100% !important",
      },
      '& .rendering-zone':{
        width: "100% !important",
      }
    },
}));