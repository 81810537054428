import React, { useState, useCallback } from 'react';
import { Typography, Button, useTheme, Box, CircularProgress, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import {detect} from 'detect-browser';
import lodash from 'lodash';

import Dropzone from 'react-dropzone';
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faImage, faVideo } from '@fortawesome/pro-solid-svg-icons';
import DeleteIcon from '@material-ui/icons/Delete';


function Upload({ width, accept, onUpload, openCamera, maxTimeVideo, maxSize, showFiles, selecteds, ...props}) {
  const theme = useTheme();
  const browser     = detect();
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(selecteds ?? false);

  let browserValidated = () => {
    if((browser.name !== 'safari' && browser.os === 'iOS') || browser.name === 'ie'){
      return false;
    }
    return true;
  }

  const getIconType = (type) => {
    if(lodash.includes(type, 'image')){
      return <FontAwesomeIcon icon={faImage} size="2x"/>;
    }else if(lodash.includes(type, 'video')){
      return <FontAwesomeIcon icon={faVideo} size="2x" />;
    } else {
      return <FontAwesomeIcon icon={faFile} size="2x"/>;
    }
  }

  const onDropRejected = (event) =>{
    if(event.length > 0){
      let erro = event[0].errors[0]?.code === "file-invalid-type" ? `O arquivo deve ser nas extensões: ${accept?.join()}` : "Existe alguma inconsistência no arquivo.";
      setAlert({mesage: erro, type: "error"});
    }
  }

  const fileToBase64 = (file) => { 
    return new Promise (resolve => { 
      var reader = new FileReader ();
      reader.readAsDataURL(file); 
      reader.onerror = () => setAlert({mesage: "Erro na leitura do arquivo.", type: "error"}); 
      reader.onloadstart = () =>  setLoading(true);
      reader.onloadend = () => setLoading(false);
      reader.onload = function (event) { 
        resolve(event.target.result);
      }; 
    }); 
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      fileToBase64(file).then(result => {
        file.data = result;
      })
  });
    setFiles(acceptedFiles);
    onUpload(acceptedFiles);
  }, [onUpload]);

  const removeFile = (file, chave) => {
    let arquivos = files;

    arquivos = lodash.remove(arquivos, (value, key) => {
      return chave !== key;
    });

    onDrop(arquivos);
  }

  return (
    <>
      <Dropzone onDrop={onDrop} accept={accept} {...props} onDropRejected={onDropRejected}>
          {({getRootProps, getInputProps}) => ( 
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" borderRadius={theme.spacing(2)} border="1px dashed" padding={theme.spacing(0.2)}> 
            <div {...getRootProps({accept: accept})} style={{textAlign: 'center'}}>
                <input  {...getInputProps({accept: accept})}/>
                {width !== "xs" && <Typography variant="h6">Arraste e solte o arquivo para fazer o envio.</Typography> } 
                <Typography variant="subtitle2">Extensões: {accept?.join()} </Typography>              
                { maxTimeVideo && <Typography variant="subtitle2">Tempo máximo: {maxTimeVideo/1000}s </Typography>}
                { maxSize && <Typography variant="subtitle2">Tamanho máximo: {maxSize} </Typography>}
                {width !== "xs" &&  <Typography variant="subtitle2">- ou -</Typography>}
                <Button variant="contained" color="primary" style={{margin: theme.spacing(1), minWidth: 200 }}  >
                  Selecionar Arquivo 
                </Button>
            </div> 
            {openCamera && browserValidated() && 
              <Button variant="contained" color="primary" style={{minWidth: 200}} onClick={openCamera} > Abrir Camera </Button>
            }
            {loading && <CircularProgress />}
          </Box>
        )}
      </Dropzone> 
      {showFiles && <Box margin={2}>
          <List  dense={true}>
            {lodash.map(files, (file, key) => (
              <ListItem>
                <ListItemIcon>
                  {getIconType(file.type)}
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="subtitle1" color="textPrimary" style={{textOverflow: 'ellipsis', overflow: 'hidden'}} >{file.name}</Typography>}
                  secondary={<Typography variant="body" color="textPrimary"  style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{file.type}</Typography>}
                />
                <ListItemSecondaryAction>
                  <IconButton  onClick={() => removeFile(file, key)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
      </Box>}
      {alert && 
        <Alert severity={alert.type}>
          {alert.mesage}
        </Alert>
      }
    </>
  )
}

export default  withWidth()(Upload);
