import { call, put } from 'redux-saga/effects';
import api from '../../config/axios';
import TemaActions from '../ducks/tema';

export function* loadTemaRequest({codInstalacao}) {
    try {
        
        if(!codInstalacao){
            throw new Error("Instalação não encontrada");
        }

        let temaEvento = yield call(api.get, `tema`);
            
        let dados = temaEvento.data.data;
      
        yield put(TemaActions.loadTemaSuccess(dados));

    } catch (error) {
        yield put(TemaActions.loadTemaFailure(error));
    }
}