import { combineReducers } from 'redux';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {reducer as reducerExperiencia} from '../experiencia/experiencia';

const { Types, Creators } = createActions({
    loadExperienciasRequest: [ 'filtros', 'ordenacao', 'page'],
    loadExperienciasSuccess: ['data'],
    loadExperienciasFailure: ['error'],
});

export const ExperienciasTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    error: false
});

const reducerExperiencias = createReducer(INITIAL_STATE, {
    [Types.LOAD_EXPERIENCIAS_REQUEST]: state =>
        state.merge({ loading: true, error: false}),
    [Types.LOAD_EXPERIENCIAS_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_EXPERIENCIAS_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
});

export const reducer = combineReducers({lista: reducerExperiencias, itemCrud: reducerExperiencia});

