import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
    root:{ 
        height:"100vh", 
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        flexDirection:'column',
        // background: '#ff9933'
    },
    blocoLogo:{
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2)
    },
    form:{
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
      },
    logoLogin:{
      width: '50%',
        height: 'auto',
    },
    formControl:{
        margin: theme.spacing(3,0,2.5),
    },
    title:{
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    disabled:{
        color: theme.palette.grey[500]
    },
    paper:{
        // width: '100%',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        minHeight: '50vh',
  
        [theme.breakpoints.down('xs')]: {
          height: '100vh',
          justifyContent: 'flex-start',
  
        },
      },
  }));  