import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {useStyles} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import history from '../../routes/history';
import Success from '../Success';
import { useControlMenu } from '../../hooks/useControlMenu';

Page.propTypes = {
    title: PropTypes.string,
    conteudoDireita:PropTypes.node,
    botaoVoltar: PropTypes.bool,
}

Page.default = {
    title: '',
    conteudoDireita: <></>,
    botaoVoltar: false,
    atualizar: false,
    loading: false
}

export default function Page({title, codControl, tituloPrefixo, tituloSufixo, botaoVoltar, conteudoDireita, atualizar, loading,...props}){
    const classes = useStyles();
    const [titulo, setTitulo] = useState(false);

    const { control } = useControlMenu(codControl);
    
    const voltar = () => {
        history.goBack();
    }

    useEffect(() => {
        if(codControl && control?.des_label){
            let texto = `${tituloPrefixo ? tituloPrefixo : ""} ${control?.des_label} ${tituloSufixo ? tituloSufixo : ""}` 
            setTitulo(texto);
        }

        if(title){
            setTitulo(title);
        }
    } ,[codControl, control, tituloPrefixo, tituloSufixo, title])

    return (<>
        <div className={classes.root}>
            <div className={classes.cabecalho}>
                <div className={classes.title}>
                   { botaoVoltar && <Button onClick={() => voltar()} className={classes.button} size="small" color="primary"  startIcon={<FontAwesomeIcon icon={faChevronLeft} size="sm"  />}> Voltar </Button>} 
                   {titulo && <Typography variant="h6"> {titulo} </Typography>} 
                </div>
                <div>
                    { atualizar && <Button color="primary" size="small" onClick={() => atualizar() } startIcon={loading ? <CircularProgress size={12} /> :  <FontAwesomeIcon icon={faSyncAlt}  />} className={classes.button}>  Atualizar </Button>}
                    {conteudoDireita}
                </div>
            </div>
            <div className={classes.conteudo}>
                {props.children}
            </div>
        </div>
        <Success />
        </>
    )

}