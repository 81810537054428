import { withWidth } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../../components/Page'
import ComunicacaoActions from '../../store/ducks/comunicacao/comunicacao'
import Loading from '../../components/Loading'
import ComunicacaoForm from '../../components/ComunicacaoForm'

function Comunicacao(props) {
    const dispatch = useDispatch()
    const { data, loading } = useSelector(state=>state.comunicacao.itemCrud)
    let { comunicacaoId } = props?.match?.params

    useEffect(() => {
        if(comunicacaoId > 0) {
            dispatch(ComunicacaoActions.loadComunicacaoRequest(comunicacaoId))
        }
        return () => dispatch(ComunicacaoActions.clearComunicacaoRequest())
    }, [comunicacaoId, dispatch])

    return (<>
        <Loading loading={loading} />
        {!loading && <Page
            title={data.des_titulo ? `Edição - ${data.des_titulo}` : ''}
            tituloPrefixo={'Criação - '}
            codControl={'MN_WEB_COMUNICACAO'}
            conteudoDireita={false}
            atualizar={false}
            loading={false}
            botaoVoltar
        >
            <ComunicacaoForm />
        </Page>}
    </>)
}

export default withWidth()(Comunicacao)