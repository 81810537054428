import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfilActions from '../../../store/ducks/perfil';
import Select from './select';


export default function SelectPerfil({ value, onChange, disabled, error, ...rest}){
    const dispatch = useDispatch();
    const perfil  = useSelector(state => state.perfil.lista);


    useEffect(() => {
        dispatch(PerfilActions.loadPerfilRequest());
    }, [dispatch])

    return (
        <Select
            fullWidth
            component="autocomplete"
            label={"Perfil"}
            disabled={perfil.error}
            options={perfil.data?.data}
            optionKey={'perfil_id'}
            optionLabel={'nom_perfil'}
            value={value}
            setValue={(e,value) => onChange(value)}
            loading={perfil.loading}
            {...rest}
        />
    )
    
    
    
}

