import { call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import CaracterizadoresActions from '../../ducks/caracterizador/caracterizadores';
import {param} from 'jquery';

//  function trataRowsGrid(dados){
    
//     let rows = lodash.map(dados, (item, key) => {
//         return {
//             ...item,
//             id: key, 
//             nom_usuario: item.usuario?.nom_usuario,
//             nom_perfil: item.perfil?.nom_perfil,
//             actions: ' ',
//             dt_experiencia_ini_grid: moment(item?.dt_experiencia_ini).format('DD/MM/yyyy'),
//             dt_experiencia_fim_grid: item?.dt_experiencia_fim ? moment(item?.dt_experiencia_fim).format('DD/MM/yyyy'): '',
//         };
//     });

//     return rows;
// }

export function* loadCaracterizadoresRequest({ busca}) {
    try {

        let filtros = {};
        if(busca) {
            filtros = {des_label_caracterizador: busca}
        }

        let response = yield call(api.get, `caracterizador/setup?${param({filtros})}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        // let data = yield trataRowsGrid(response.data?.data);
        // response.data.data = data;
        yield put(CaracterizadoresActions.loadCaracterizadoresSuccess(response.data));

    } catch (error) {
        error.message = "Erro ao carregar caracterizadores."
        yield put(CaracterizadoresActions.loadCaracterizadoresFailure(error));
    }
}
