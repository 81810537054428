import { call, put, take, race } from 'redux-saga/effects';
import api from '../../../config/axios';
import ComunicacaoActions from '../../ducks/comunicacao/comunicacao';
import SuccessActions from '../../ducks/success'
import UploadActions from '../../ducks/upload'

export function* loadComunicacaoRequest({ comunicacaoId }) {
    try {
        let response = yield call(api.get, `comunicacao/${comunicacaoId}`);

        let dados = {
            ...response.data?.data,
            list_dia_semana: response.data?.data?.list_dia_semana.split(',')
        };

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
       
        yield put(ComunicacaoActions.loadComunicacaoSuccess(dados));
    } catch (error) {
        error.message = error.message ?? "Erro ao carregar comunicação."
        yield put(ComunicacaoActions.loadComunicacaoFailure(error));
    }
}

function* uploadImagem(imagem) {
    
    yield put(UploadActions.uploadArquivoRequest(imagem, 'banner'));

    const {error, success} = yield race({
        error:  take(UploadActions.uploadArquivoFailure().type),
        success: take(UploadActions.uploadArquivoSuccess().type),
    });

    return {error, success}
}

export function* saveComunicacaoRequest({ comunicacaoId, dados }) {
    try {
        let response = {}

        dados = {
            ...dados,
            list_dia_semana: dados.list_dia_semana ? dados.list_dia_semana.join(',') : '1,2,3,4,5,6,7',
            des_destino: dados.des_destino ? dados.des_destino : 'PAGINA'
        };
        
        const base64regex = /data:image\/[^;]+;base64[^"]+/;
        if(dados.arq_imagem_chamada && base64regex.test(dados.arq_imagem_chamada)){
            let { error, success} = yield uploadImagem(dados?.arq_imagem_chamada);
            if(error){
                throw error;
            }
            dados = {...dados, arq_imagem_chamada: success?.data}
        }
        if(comunicacaoId) {
            response = yield call(api.put, `comunicacao/${comunicacaoId}`, dados);
        } else {
            response = yield call(api.post, `comunicacao`, dados);
        }

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        yield put(SuccessActions.setSuccess(true, 'Comunicação salva com sucesso!'));
        yield put(ComunicacaoActions.loadComunicacaoRequest(response?.data?.data?.comunicacao_id));
    } catch (error) {
        error.message = error.message ?? "Erro ao salvar comunicação."
        yield put(ComunicacaoActions.saveComunicacaoFailure(error));
    }
}