import React from 'react';
import { Badge, Box, IconButton, TextField, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Texto({label, value, setValue, endIcon, endToolTip, ...props}){
    
    return  <TextField 
                label={label}
                value={value} 
                onChange={setValue}
                autoComplete="off"
                variant="filled" 
                required
                InputProps={{
                    endAdornment: (
                        <> {endIcon && 
                            <Badge badgeContent={endIcon?.badgeContent ?? 0} color="secondary">
                                <IconButton onClick={endIcon?.onClick} size="small" title={endIcon?.title}>
                                    <FontAwesomeIcon icon={endIcon?.icon} ></FontAwesomeIcon>
                                </IconButton>
                            </Badge> } 
                            {endToolTip && 
                                <Tooltip title={endToolTip?.title}>
                                    <Box component="span">
                                        <FontAwesomeIcon icon={endToolTip?.icon} />
                                    </Box>
                                </Tooltip> 
                            } </>
                    )
                }}
                {...props}
            />
}