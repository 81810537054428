import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	form:{
		margin: theme.spacing(1),
	},
	row:{
		display: 'flex',
		flexDirection: 'row',
		margin: theme.spacing(.5),
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap'
		},
		'& .MuiFormControl-root':{
			margin: theme.spacing(.5),
			alignSelf: 'flex-end'
		},
		'& .MuiAutocomplete-root':{
			alignSelf: 'flex-end', marginRight: theme.spacing(1),
		},
	}
}));