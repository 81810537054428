import axios from 'axios';
import {Cookies} from 'react-cookie';
import {config} from './env';
import store from '../store';
import loginActions from '../store/ducks/login';
import ValidationError from '../exceptions/ValidationError';

// const baseURL = 'http://xmeet.localhost.com:8000'
const baseURL = config.url.API_URL;
const headers = { 'Content-Type': 'application/json' , 'Accept': 'application/json' };

export function getApi(){

    const api = axios.create({
        baseURL: baseURL+'/',
        headers: headers,
        withCredentials: true
    });
    // ************* INTERCEPTORS ***********************
    
    api.interceptors.request.use(
        async request => {
            let cookie = new Cookies();
            let token = cookie.get('access_token');
            let cod_instalacao = cookie.get('cod_instalacao');
            if (token != null) {
                request.headers.Authorization = `Bearer ${token}`;
            }

            request.url = cod_instalacao+'/'+request.url
            return request;
        },
        // error => Promise.reject(error),
    );
    
    api.interceptors.response.use(
        response => {
            // const { data } = response.data;
            return response;
        },
        function (error){
            let res = error.response;
            if(res?.status === 401){
                store.dispatch(loginActions.logoutRequest(false));
            }
            if(res?.status === 400 && res?.data?.error === "invalid_grant"){
                error = new Error(res?.data?.error);
            }else if(res?.data?.message){
                error = new ValidationError(res.data?.message, res.data?.error, res.data?.errors);
            }
            error.response = res;
            return Promise.reject(error);
        }
    );    
    return api;
}
const api = getApi();


export default api;
