import { combineReducers } from 'redux';
import { createReducer, createActions } from 'reduxsauce';
import {reducerCaracterizador} from './caracterizador';

import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadCaracterizadoresRequest: ['busca'],
    loadCaracterizadoresSuccess: ['data'],
    loadCaracterizadoresFailure: ['error'],

});

export const CaracterizadoresTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false,
});

export const reducerCaracterizadores = createReducer(INITIAL_STATE, {
    [Types.LOAD_CARACTERIZADORES_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_CARACTERIZADORES_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_CARACTERIZADORES_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
 
});

export const reducer = combineReducers({lista: reducerCaracterizadores, itemCrud: reducerCaracterizador});
