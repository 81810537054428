
import React, { useEffect,useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DateTime from '../Campo/dateTime';
import Inteiro from '../Campo/inteiro';
// import SelectAcomodacao from '../Campo/selectAcomodacao';
// import SelectPerfil from '../Campo/selectPerfil';
import SelectSemana from '../Campo/selectSemana';
import Texto from '../Campo/texto';
import {useStyles} from './styles';
import PeriodoItens from './PeriodoItens';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Typography, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PeriodoActions from '../../../store/ducks/agenda/periodos';
import Moment from 'react-moment';

export default function Periodo({ atracaoId, atracaoItemId, dadosPeriodo, isFormEdited, setIsFormEdited }) {

	const classes = useStyles();
	const dispatch = useDispatch();
	const theme    = useTheme();

	const { loadingCreate } = useSelector(state => state.periodos);

	const { errors, register, control, handleSubmit, reset,formState } = useForm();
	const [openFormulario, setOpenFormulario] = useState(false);

	const onFinallySave = () => {
		setOpenFormulario(false);
	}

	const save = (dados) => {
		dispatch(PeriodoActions.savePeriodoRequest(atracaoId, atracaoItemId, {...dadosPeriodo, ...dados}, () => onFinallySave()));
	}

	const remove = () => {
		dispatch(PeriodoActions.removePeriodoRequest(atracaoId, atracaoItemId, dadosPeriodo?.agenda_setup_id));
	}

	useEffect(() => {
		setIsFormEdited(formState.isDirty)
	}, [formState, setIsFormEdited]);

	useEffect(() => {
		if(!dadosPeriodo){
			setOpenFormulario(true);
			reset({list_dia_semana: []})
		}else{
            reset({ ...dadosPeriodo, list_dia_semana: dadosPeriodo.list_dia_semana?.asMutable() })
		}
	},[dadosPeriodo, openFormulario, reset]);

  	return (
   		<>
		{dadosPeriodo && <>
			<Box display="flex" flexDirection="row" p={.5} alignItems="baseline" justifyContent="space-between">
				<Box display="flex" flexDirection="row" alignItems="baseline" flexGrow={2}>
					<Typography variant="h6" color="secondary"  style={{marginRight: 10}} >
						{dadosPeriodo?.nom_agrupador_agenda}
					</Typography>
					<Typography variant="subtitle2"   style={{marginRight: 5}}> 
						<FontAwesomeIcon icon="calendar"  style={{marginRight: 5}}/> 
						<Moment date={dadosPeriodo?.dt_agenda_ini} format='DD/MM/yyyy' /> - <Moment date={dadosPeriodo?.dt_agenda_fim} format='DD/MM/yyyy' />
					</Typography>
					<Typography variant="subtitle2"   style={{marginRight: 5}}> 
						<FontAwesomeIcon icon="clock" /> {dadosPeriodo?.hr_agenda_ini}h às {dadosPeriodo?.hr_agenda_fim}h
					</Typography>
					{dadosPeriodo?.num_minuto_frequencia && 
						<Typography variant="subtitle2" style={{marginLeft: 15}}> 
							<FontAwesomeIcon icon="history"  style={{marginRight: 5}}/> 
							{dadosPeriodo?.num_minuto_frequencia} em {dadosPeriodo?.num_minuto_frequencia} minutos 
						</Typography> 
					}
				</Box>
				{!openFormulario && <Box flexGrow={1}  display="flex" justifyContent="flex-end"> 
					<Button onClick={() => setOpenFormulario(true)}  variant="outlined" title="Editar dados do período"  size="small" color="secondary" startIcon={<FontAwesomeIcon icon="edit" style={{fontSize: 14}} />} style={{marginRight: 5}}>Editar</Button>
					<Button onClick={() => remove()}  variant="outlined" title="Excluir período" size="small"  startIcon={<FontAwesomeIcon icon="trash" style={{fontSize: 14, color: theme.palette.error.main}} />}>Excluir</Button>
				 </Box>
				}
			</Box>
		 </>
		}

      	{openFormulario && <>
		 <form  className={classes.formulario}>
			<div className={classes.row}>
				<Texto 
					fullWidth
					size="small"
					label="Nome do Período"
					name={`nom_agrupador_agenda`}   
					inputRef={register({ required: false })}
					error={Boolean(errors?.nom_agrupador_agenda)}
					helperText={errors?.nom_agrupador_agenda && "O campo é obrigatório."}
				/>
				<Controller 
					name={`num_pessoa_max`}
					control={control} 
					rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
					render={({ onChange, value }) => 
						<Inteiro 
							fullWidth={false}
							size="small"
							label="Nº de Pessoas"
							minValue={1}
							error={errors?.num_pessoa_max}
							helperText={errors?.num_pessoa_max && "O campo é obrigatório."}
							required={false}
							setValue={onChange}
							value={value}
						/>
					}
				/>
				
			</div>
			<div className={classes.row}>
				<Controller 
					name={`num_pessoa_max_grupo`}
					control={control} 
					rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
					render={({ onChange, value }) => 
						<Inteiro 
							fullWidth
							size="small"
							label="Nº de Pessoas por Grupo"
							minValue={1}
							error={errors?.num_pessoa_max_grupo}
							helperText={errors?.num_pessoa_max_grupo && "O campo é obrigatório."}
							required={false}
							setValue={onChange}
							value={value}
						/>
					}
				/>
				<Controller 
					name={`num_grupo_max`}
					control={control} 
					rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
					render={({ onChange, value }) => 
						<Inteiro 
							fullWidth
							size="small"
							label="Nº Máximo de Grupos"
							minValue={1}
							required={false}
							error={errors?.num_grupo_max}
							helperText={errors?.num_grupo_max && "O campo é obrigatório."}
							setValue={onChange}
							value={value}
						/>
					}
				/>
			</div>
			<div className={classes.row}>
				<Controller 
					name="dt_agenda_ini"  
					control={control} 
					render={({ onChange, value }) => 
						<DateTime 
							size="small"
							fullWidth
							value={value}
							label="Data Início"
							setValue={onChange}
							onlyDate={true}
							error={errors?.dt_agenda_ini}
							helperText={errors?.dt_agenda_ini && "O campo é obrigatório."}
						/>
					}
				/>
				<Controller 
					name="dt_agenda_fim"  
					control={control} 
					render={({ onChange, value }) => 
						<DateTime 
							fullWidth
							value={value}
							size="small"
							label="Data Fim"
							setValue={onChange}
							onlyDate={true}
							error={errors?.dt_agenda_fim}
							helperText={errors?.dt_agenda_fim && "O campo é obrigatório."}
						/>
					}
				/>
       		</div>
			<div className={classes.row}>
				<Controller 
					name="hr_agenda_ini"  
					control={control} 
					render={({ onChange, value }) => 
						<DateTime 
						fullWidth
						value={value}
						size="small"
						label="Horário Abertura"
						setValue={onChange}
						onlyTime={true}
						error={errors?.hr_agenda_ini}
						helperText={errors?.hr_agenda_ini && "O campo é obrigatório."}
						/>
					}
				/>
				<Controller 
					name="hr_agenda_fim"  
					control={control} 
					render={({ onChange, value }) => 
						<DateTime 
							fullWidth
							value={value}
							size="small"
							label="Horário Fechamento"
							setValue={onChange}
							onlyTime={true}
							error={errors?.hr_agenda_fim}
							helperText={errors?.hr_agenda_fim && "O campo é obrigatório."}
						/>
					}
				/>
       		</div>
			<div className={classes.row}>
				<Controller 
					name={`num_minuto_frequencia`}
					control={control} 
					rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
					render={({ onChange, value }) => 
						<Inteiro 
							fullWidth
							size="small"
							label="Frequência em minutos"
							minValue={1}
							error={errors?.num_minuto_frequencia}
							helperText={errors?.num_minuto_frequencia && "O campo é obrigatório."}
							setValue={onChange}
							value={value}
						/>
					}
				/>
				<Controller 
					name="list_dia_semana"  
					control={control} 
					render={({ onChange, value }) => 
						<SelectSemana 
							fullWidth
							required={false}
							name="list_dia_semana"  
							label="Dias da semana"
							setValue={onChange}
							value={value ?? []}
							error={Boolean(errors?.list_dia_semana)}
							helperText={errors?.list_dia_semana && "O campo é obrigatório."}
						/> 
					}
				/>
			</div>
			{/* <div className={classes.row}>
				<Controller 
					name="perfil"  
					control={control} 
					rules={{required: true}}
					render={({ onChange, value }) =>
						<SelectPerfil  onChange={onChange} />
					}
				/>
				<Controller 
					name="acomodacao"  
					control={control} 
					rules={{required: true}}
					render={({ onChange, value }) => 
						<SelectAcomodacao onChange={onChange} />
					}
				/>
			</div> */}
			<Box display="flex" justifyContent="flex-end" flexGrow={1} pr={1} > 
				{dadosPeriodo && <Button onClick={() => setOpenFormulario(false)} variant="contained" className={classes.cancel} disabled={loadingCreate}>  cancelar </Button>}
				<Button  
					onClick={handleSubmit(save)} 
					variant="contained" 
					color="secondary"
					endIcon={<FontAwesomeIcon icon="chevron-right" /> } 
					disabled={!isFormEdited || loadingCreate}
				> 
					{loadingCreate && <CircularProgress size={15} /> } salvar 
				</Button>
			</Box>
     	</form>
		
	  </>
    }

    {!openFormulario && dadosPeriodo &&
        <PeriodoItens atracaoId={atracaoId} atracaoItemId={atracaoItemId} atracaoSetupId={dadosPeriodo?.agenda_setup_id} dtAgendaFim={dadosPeriodo?.dt_agenda_fim} setOpenFormulario={setOpenFormulario} />
	}
   
    </>
  );
}