import { combineReducers } from 'redux';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadPerfilRequest: ['busca'],
    loadPerfilSuccess: ['data'],
    loadPerfilFailure: ['error'],

});

export const PerfilTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false,
});

export const reducerPerfil = createReducer(INITIAL_STATE, {
    [Types.LOAD_PERFIL_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_PERFIL_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_PERFIL_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
 
});

export const reducer = combineReducers({lista: reducerPerfil});
