import { call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import TiposAtracaoActions from '../../ducks/atracao/tipos';

export function* loadTiposAtracaoRequest() {
    try {
        let response = yield call(api.get, `tipo/atracao`);
        let dados = response.data;
        yield put(TiposAtracaoActions.loadTiposAtracaoSuccess(dados));
    } catch (error) {
        error.message = error.message ?? "Erro ao carregar tipos da atração."
        yield put(TiposAtracaoActions.loadTiposAtracaoFailure(error));
    }
}
