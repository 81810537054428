import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { useStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@material-ui/core';

export default function EditorHTML({ value, onChange, ...props }) {
    const classes = useStyles()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showHTML, setShowHTML] = useState(false)

    const onEditorStateChange = (value) => {
        setEditorState(value)
    }

    const EditHTML = () => {
        return (<IconButton
                    size="small"
                    className={classes.htmlButton}
                    title={"Editar HTML"}
                    onClick={() => {
                        setShowHTML(!showHTML)
                        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                    }}
                >
                <FontAwesomeIcon icon={faCode} />
            </IconButton>)
    }

    useEffect(() => {
        if(editorState) {
            let timerId = setTimeout(() => {
                onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
            }, 3000);

            return () => clearInterval(timerId)
        }
    }, [editorState, onChange])

    useEffect(() => {
        const contentBlock  = htmlToDraft(value ?? '<p></p>')
        if(contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
        }
    }, [value])
    
    return (<div>
        <Editor
            wrapperClassName="demo-wrapper"
            editorClassName={showHTML === false ? classes.editor : classes.hideEditor}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded']
            }}
            toolbarCustomButtons={[<EditHTML />]}
            onBlur={() => onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
            {...props}
        />
        {showHTML && <textarea
            value={value} 
            onChange={(e) => onChange(e.target.value)}
            className={classes.textAreaHtml}
        />}
    </div>)
}