import { combineReducers } from 'redux';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {reducer as reducerProgramacaoItem} from './programacaoItem';

const { Types, Creators } = createActions({
    loadProgramacaoRequest: [ 'atracaoId', 'filtros', 'ordenacao','page'],
    loadProgramacaoSuccess: ['data'],
    loadProgramacaoFailure: ['error'],
});

export const ProgramacaoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    error: false
});

export const reducerProgramacao = createReducer(INITIAL_STATE, {
    [Types.LOAD_PROGRAMACAO_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_PROGRAMACAO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_PROGRAMACAO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
});


export const reducer = combineReducers({lista: reducerProgramacao, itemCrud: reducerProgramacaoItem});
