import React, {useEffect} from 'react';
import {Redirect } from 'react-router-dom';
import loginActions from '../store/ducks/login';
import temaActions from '../store/ducks/tema';
import { useDispatch, useSelector } from 'react-redux';
import {useStyles} from './styles';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

export default function ValidaRedirect({isPrivate, redirectIfLogged, ...rest}) {
    const dispatch    = useDispatch();
    let styles        = useStyles();

    let codInstalacao = '';
    let cookie = new Cookies();
    if(cookie.get('cod_instalacao')){
        codInstalacao = cookie.get('cod_instalacao').toUpperCase();
    }


    useEffect(()=>{
        dispatch(temaActions.loadTemaRequest(codInstalacao));
    }, [dispatch, codInstalacao]);

    let usuarioSessao                   = useSelector(state=>state.login.data);
    let loadingUsuSessao                = useSelector(state=>state.login.loadingLogged);

    useEffect(()=>{
        if(loadingUsuSessao === 0 ){
            dispatch(loginActions.loggedRequest(false));
        }
    }, [dispatch,usuarioSessao, loadingUsuSessao]);

    /**    
    * Redirect user to SignIn page if he tries to access a private      route
    * without authentication.    
    */   
    if(loadingUsuSessao === false){ 
        if (isPrivate && !usuarioSessao.usuario_id){
            if(rest.path !== '/login' && rest.path !== '/:instalacao/recuperaSenha/:tokenSenha' && rest.path !== '/esqueciSenha') {
                return <Redirect to={`/login`}/>;   
            }
        }

        if(usuarioSessao.usuario_id){
            if(redirectIfLogged || rest.path === '/login'  ) {
                return <Redirect to={`/atracoes`}/>;    
            }

        }
       
        ReactGA.pageview(rest.location?.pathname);
        return <> {rest.children} </>
    } 

    return <div align="center" className={styles.centerLoading}>
        {/* {!tema.error && <CircularProgress color="primary" />} */}
        {/* {tema.error && <span>Tivemos problemas para carregar alguns dados. Atualize a página.</span>} */}
    </div>;

  }