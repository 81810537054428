import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {detect} from 'detect-browser';

export default function Copyright() {
   
    const browser     = detect();
    return (
      <>{
          browser.name !== 'safari' && <>
          <Typography variant="body2" color="textSecondary" align="center" >
            {' © '}
            <Link color="inherit" target="_blank" href="https://xtrategie.com.br">
              Software Powered by Xtrategie 
             <br />2009- {''}{new Date().getFullYear()}
            </Link>
          </Typography></>
        }
      </>
    );
  }