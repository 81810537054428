import { call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import AtracoesActions from '../../ducks/atracao/atracoes';
import lodash from 'lodash';
import {param} from 'jquery';

function trataRowsGrid(dados){
    
    let rows = lodash.map(dados, (item, key) => {
        return {
            ...item,
            id: key, 
            actions: ' '
        };
    });
    return rows;
}

export function* loadAtracoesRequest({ filtros, ordenacao, page}) {
    try {

        let pagina = page ?? 1;

        let response = yield call(api.get, `atracao?${param(filtros)}&${param(ordenacao)}&page=${pagina}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        let data = yield trataRowsGrid(response.data?.data);
        response.data.data = data;
        yield put(AtracoesActions.loadAtracoesSuccess(response.data));

    } catch (error) {
        error.message = "Erro ao carregar atrações."
        yield put(AtracoesActions.loadAtracoesFailure(error));
    }
}
