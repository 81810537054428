import React from 'react';
import { DataGrid as DataGridMaterial } from "@material-ui/data-grid";
import {useStyles} from './styles';
import Error from '../Error';
import { Box } from '@material-ui/core';


function DataGrid({columns, rows, perPage, reload, error, className, ...props}) {
  
  const classes = useStyles();

  return (
    <>
    <DataGridMaterial
      className={`${classes.root}`}
      headerHeight={30}
      disableSelectionOnClick={true}
      rows={rows}
      columns={columns}
      paginationMode="server"
      disableColumnMenu={true}
      components={{
        NoRowsOverlay: (params) => {
          return <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">Nenhum dado encontrado.</Box>
       }
      }}
      {...props}
    /> 
    {error && <Error error={error} carregar={reload} showAlert={false}></Error>}
    </>
  )
}

export default (DataGrid)