import React, { useEffect } from 'react';
import Dialog from '../../Dialogs/Dialog';
import ButtonSuccess from '../../ButtonSuccess';
import ButtonCancel from '../../ButtonCancel';
import { Box, CircularProgress } from '@material-ui/core';
import AgendaPeriodoActions from '../../../store/ducks/agenda/agendaPeriodo';
import { useDispatch, useSelector } from 'react-redux';
import {useStyles} from './styles';
import { Controller, useForm } from 'react-hook-form';
import DateTime from '../Campo/dateTime';
import Inteiro from '../Campo/inteiro';

function PeriodoItemForm({open, close, agenda}) {

  const dispatch = useDispatch();
  const classes  = useStyles();

  const { loadingCreate } = useSelector(state => state.agendaPeriodo);
	const { errors, control, handleSubmit, reset } = useForm();

  const handleSave = (data) =>{
      let dados = {...agenda,...data};
      dispatch(AgendaPeriodoActions.saveAgendaPeriodoRequest(agenda?.agenda_setup_id, agenda?.agenda_id, dados, () => {
        close()
      }));
  }

  useEffect(() => {
    reset({...agenda})
  },[agenda, reset])

	return (	
		
        <Dialog  
            open={open} 
            close={()=> close()}
            title={"Edição da agenda"}
            content={<>
                <Box minWidth={400} margin={.5}>
                  <div className={classes.row}>
                    <Controller
                      name="dt_agenda"  
                      control={control} 
                      render={({ onChange, value }) => 
                        <DateTime 
                          size="small"
                          fullWidth
                          value={value}
                          label="Data"
                          setValue={onChange}
                          onlyDate={true}
                          error={errors?.dt_agenda}
                          helperText={errors?.dt_agenda && "O campo é obrigatório."}
                        />
                      }
                    />
                    <Controller 
                      name="hr_agenda"  
                      control={control} 
                      render={({ onChange, value }) => 
                        <DateTime 
                          fullWidth
                          value={value}
                          size="small"
                          label="Horário"
                          setValue={onChange}
                          onlyTime={true}
                          error={errors?.hr_agenda}
                          helperText={errors?.hr_agenda && "O campo é obrigatório."}
                        />
                      }
                    />
                  </div>
                  <div className={classes.row}>
                    <Controller 
                      name={`num_pessoa_max`}
                      control={control} 
                      rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                      render={({ onChange, value }) => 
                        <Inteiro
                          fullWidth
                          size="small"
                          label="Nº de Pessoas"
                          error={errors?.num_pessoa_max}
                          helperText={errors?.num_pessoa_max && "O campo é obrigatório."}
                          value={value}
                          setValue={onChange}
                        />
                      }
                    />
                  </div>
                  <div className={classes.row}>
                  <Controller 
                      name={`num_pessoa_max_grupo`}
                      control={control} 
                      rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                      render={({ onChange, value }) => 
                        <Inteiro 
                          fullWidth
                          size="small"
                          label="Nº de Pessoas por Grupo"
                          error={errors?.num_pessoa_max_grupo}
                          helperText={errors?.num_pessoa_max_grupo && "O campo é obrigatório."}
                          required={false}
                          value={value}
                          setValue={onChange}
                        />
                      }
                    />
                    <Controller 
                      name={`num_grupo_max`}
                      control={control} 
                      rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                      render={({ onChange, value }) => 
                        <Inteiro 
                          fullWidth
                          size="small"
                          label="Nº Máximo de Grupos"
                          required={false}
                          error={errors?.num_grupo_max}
                          helperText={errors?.num_grupo_max && "O campo é obrigatório."}
                          value={value}
                          setValue={onChange}
                        />
                    }
                  />
                  </div>
                </Box>
               </>
            }
            actions={<Box display="flex">
               <ButtonCancel onClick={()=> close()} > Cancelar </ButtonCancel>
               <ButtonSuccess autoFocus onClick={handleSubmit(handleSave)} disable={loadingCreate} >
                    {loadingCreate && <CircularProgress size={15} />} Salvar
                </ButtonSuccess>
            </Box>
            }
        />     
    )

}

export default  (PeriodoItemForm);
