
import { createStore, compose, applyMiddleware } from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import history from '../routes/history';
import createSagaMiddleware from 'redux-saga';


import rootReducer from './ducks';
import rootSaga from './sagas';


const middleware = [
    routerMiddleware(history)
];

const sagaMiddleware = createSagaMiddleware();


const composeEnhancers  = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composer          = composeEnhancers(applyMiddleware(sagaMiddleware), applyMiddleware(...middleware));
const store             = createStore(connectRouter(history)(rootReducer), composer);

sagaMiddleware.run(rootSaga);

export default store;
