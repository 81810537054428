import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {reducerCaracterizadores } from './caracterizadores';
import { combineReducers } from 'redux';

const { Types, Creators } = createActions({
    loadProgramacaoItemRequest: [ 'atracaoId','atracaoItemId'],
    loadProgramacaoItemSuccess: ['data','atracaoId', 'atracaoItemId'],
    loadProgramacaoItemFailure: ['error'],

    saveProgramacaoItemRequest: [ 'atracaoId','atracaoItemId', 'dados'],
    saveProgramacaoItemSuccess: ['atracaoId', 'atracaoItemId', 'data'],
    saveProgramacaoItemFailure: ['error'],
});

export const ProgramacaoItemTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    error: false,
    atracaoId:false,
    atracaoItemId: false,
    loadingCreate: false
});

export const reducerProgramacao = createReducer(INITIAL_STATE, {
    [Types.LOAD_PROGRAMACAO_ITEM_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_PROGRAMACAO_ITEM_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false, atracaoId: action.atracaoId, atracaoItemId: action.atracaoItemId}),
    [Types.LOAD_PROGRAMACAO_ITEM_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),

    [Types.SAVE_PROGRAMACAO_ITEM_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false }),
    [Types.SAVE_PROGRAMACAO_ITEM_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loadingCreate: false, error: false, atracaoId: action.atracaoId, atracaoItemId: action.atracaoItemId}),
    [Types.SAVE_PROGRAMACAO_ITEM_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),
});


export const reducer = combineReducers({dadosBasicos: reducerProgramacao, caracterizadores: reducerCaracterizadores });