import {  call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import ProgramacaoItemCaracterizadoresActions from '../../ducks/programacao/caracterizadores';
import lodash from 'lodash';
import SuccessActions from '../../ducks/success';


export function* loadProgramacaoItemCaracterizadoresRequest({ atracaoId, atracaoItemId}) {
    try {

        let response = yield call(api.get, `atracao/${atracaoId}/item/${atracaoItemId}/caracterizador`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
     
        
        yield put(ProgramacaoItemCaracterizadoresActions.loadProgramacaoItemCaracterizadoresSuccess(response.data?.data));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar caracterizadores da programação."
        yield put(ProgramacaoItemCaracterizadoresActions.loadProgramacaoItemCaracterizadoresFailure(error));
    }
}



export function* saveProgramacaoItemCaracterizadoresRequest({ atracaoId, atracaoItemId, dados, callback}) {
    try {

        if(!atracaoItemId || !atracaoId){
            throw Error("Os dados estão incompletos");
        }


        let dadosEnvio = lodash.map(dados?.caracterizadores, (value, key) => {
            return  {
                ...value,
                caracterizador_setup_id: value?.setup?.caracterizador_setup_id
            }
        });

        yield call(api.post, `atracao/${atracaoId}/item/${atracaoItemId}/caracterizador`, {data: dadosEnvio});
       
        if(callback && typeof callback === 'function'){
            callback()
        }

        yield put(SuccessActions.setSuccess(true, 'Caracterizações salvar com sucesso!'));

        yield put(ProgramacaoItemCaracterizadoresActions.saveProgramacaoItemCaracterizadoresSuccess(dados?.caracterizadores));

    } catch (error) {
        error.message = error.message ?? "Erro ao salvar  os caracterizadores da programação."
        yield put(ProgramacaoItemCaracterizadoresActions.saveProgramacaoItemCaracterizadoresFailure(error));
    }
}
        