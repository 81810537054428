import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadAtracaoCaracterizadoresRequest: [ 'atracaoId'],
    loadAtracaoCaracterizadoresSuccess: ['data'],
    loadAtracaoCaracterizadoresFailure: ['error'],

    saveAtracaoCaracterizadoresRequest: [ 'atracaoId', 'dados', 'callback'],
    saveAtracaoCaracterizadoresSuccess: ['data'],
    saveAtracaoCaracterizadoresFailure: ['error'],

    clearAtracaoCaracterizadoresRequest: null
});

export const AtracaoCaracterizadoresTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false,
    loadingCreate: false
});

export const reducerCaracterizadores = createReducer(INITIAL_STATE, {
    [Types.LOAD_ATRACAO_CARACTERIZADORES_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_ATRACAO_CARACTERIZADORES_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_ATRACAO_CARACTERIZADORES_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
    
    [Types.SAVE_ATRACAO_CARACTERIZADORES_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false }),
    [Types.SAVE_ATRACAO_CARACTERIZADORES_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loadingCreate: false, error: false }),
    [Types.SAVE_ATRACAO_CARACTERIZADORES_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),

    [Types.CLEAR_ATRACAO_CARACTERIZADORES_REQUEST]: (state, action) =>
        state.merge(INITIAL_STATE),
});


