import React from 'react';
import { TextField } from '@material-ui/core';
import moment from 'moment';

export default function DateTime({label, value, setValue, className, onlyDate, onlyTime, ...rest}){

    let type = onlyDate ? "date" : onlyTime ? "time" : "datetime-local";

    let formataData = (data) => {
        if(data){
            if(onlyDate) 
                return moment(data).format("YYYY-MM-DD") 
            else if(onlyTime)
                return data
            else
                return moment(data).format("YYYY-MM-DDTHH:mm");
        }else{
            return null;
        }
    }

    return  <TextField 
                label={label}                
                value={formataData(value)} 
                onChange={setValue} 
                InputLabelProps={{ shrink: true }}
                required 
                variant="filled" 
                type={type}
                autoComplete="off"
                {...rest}
            />
}