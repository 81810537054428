import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './styles';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';
import DataGrid from '../../components/DataGrid';
import { useDispatch, useSelector } from 'react-redux';
import AtracoesActions from '../../store/ducks/atracao/atracoes';
import { Avatar, Button, ButtonGroup, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../routes/history';
import AglomeracaoForm from '../../components/Form/AglomeracaoForm';

function Atracoes(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [dialogAglomera, setDialogAglomera] = useState(false);
    const [queryString, setQueryString] = useState('')

    const { data, loading, error } = useSelector(state => state.atracao.lista);

    const carregar = useCallback((props, queryString) => {
        dispatch(AtracoesActions.loadAtracoesRequest({ busca_texto: queryString }, false, props?.page));
    }, [dispatch]);

    const onClickItem = (destino, row) => {
        switch (destino) {
            case 'aglomeracao':
                setDialogAglomera(row);
                break;
            default:
                history.push(`/${destino}`, { atracaoId: row?.atracao_id, nome: row?.nom_atracao });
                break;
        }
    }

    const handleImagem = (params) => (
        <div className={classes.rowFoto} >
            <Avatar alt={params?.row?.nom_atracao} src={params?.row?.arq_imagem_atracao} className={classes.foto} variant="square" />
        </div>
    )

    const handleRowActions = (params) => (
        <ButtonGroup variant="outlined" color="secondary" size="small" className={classes.group}>
            <Button className={classes.button} startIcon={<FontAwesomeIcon icon="edit" className={classes.icon} />} onClick={() => onClickItem('atracao', params?.row)} >Editar </Button>
            <Button className={classes.button} startIcon={<FontAwesomeIcon icon="users" className={classes.icon} />} onClick={() => onClickItem('aglomeracao', params?.row)} >Aglomeração </Button>
            <Button className={classes.button} startIcon={<FontAwesomeIcon icon="calendar" className={classes.icon} />} onClick={() => onClickItem('agenda', params?.row)} >Agenda </Button>
            <Button className={classes.button} startIcon={<FontAwesomeIcon icon="list" className={classes.icon} />} onClick={() => onClickItem('programacao', params?.row)} >Programação </Button>
        </ButtonGroup>
    )

    const columns = [
        { field: 'des_icone_atracao', headerName: 'Foto', renderCell: (params) => handleImagem(params), flex: 0.5 },
        { field: 'nom_atracao', headerName: 'Nome', flex: 1 },
        { field: 'cod_tipo_atracao', headerName: 'Código', flex: 1 },
        { field: 'des_atracao', headerName: 'Descrição', flex: 2 },
        { field: 'actions', headerName: ' ', renderCell: (params) => handleRowActions(params), width: 430 }
    ]

    useEffect(() => {
        carregar({}, queryString);
    }, [carregar, queryString]);

    const botaoNovo =
        <>
            <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon="plus" />} onClick={() => onClickItem('atracao', {})} > Nova</Button>
            <TextField
                id="standard-basic"
                placeholder="Pesquisar atração"
                value={queryString}
                style={{ minWidth: '300px', marginLeft: '20px' }}
                onChange={(e) => setQueryString(e.target.value)}
            />
            <FontAwesomeIcon icon='search' size="lg" style={{ marginTop: '8px' }} />
        </>

    return (
        <>
            <Page codControl={"MN_WEB_ATRACAO"} conteudoDireita={botaoNovo} atualizar={(props) => carregar(props, '')} loading={loading}>
                <DataGrid
                    reload={(props) => carregar(props, '')}
                    error={error}
                    loading={loading}
                    columns={columns}
                    rows={data?.data ?? []}
                    rowCount={data?.meta?.total}
                    pageSize={15}
                    onPageChange={(props) => carregar(props, queryString)}
                />
            </Page>
            {dialogAglomera !== false &&
                <AglomeracaoForm
                    open={dialogAglomera !== false}
                    atracao={dialogAglomera}
                    close={() => setDialogAglomera(false)}
                />
            }
        </>
    );
}

export default withWidth()(Atracoes);
