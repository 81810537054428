import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadCaracterizadorRequest: ['busca'],
    loadCaracterizadorSuccess: ['data'],
    loadCaracterizadorFailure: ['error'],

});

export const CaracterizadorTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false,
});

export const reducerCaracterizador = createReducer(INITIAL_STATE, {
    [Types.LOAD_CARACTERIZADOR_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_CARACTERIZADOR_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_CARACTERIZADOR_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
 
});

