import React, {useEffect, useCallback}  from 'react';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';


import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import ViewActions from '../../store/ducks/view';
import { useTheme} from '@material-ui/core';
import lodash from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function View(props) {

  let theme           = useTheme();
  const classes       = useStyles();
  const dispatch      = useDispatch();
  const views         = useSelector((state)=>state.view.data) ?? [];
  const atualizarLista = useCallback(()=>{
    dispatch(ViewActions.loadViewRequest());
  }, [dispatch]);

  useEffect(()=>{
    atualizarLista();
  }, [atualizarLista]);

  return (
    <>
      <Page codControl={"MN_WEB_VIEW"} >
      <div className={classes.listaCards} >
          {lodash.size(views) === 0 && 'Nenhum dashboard encontrado'}
					{ lodash.map(views, (view)=>{
						return <a href={view.des_link_view} target="_blank" rel="noopener noreferrer" className={classes.link}>
									<div className={classes.cardView}> 
										<div className={classes.icone}>
                      <FontAwesomeIcon icon={['fal', view.nom_icone]} className={classes.iconeSist}  color={theme.palette.secondary.dark}  size="3x"/>
										</div>
										<div className={classes.nome}>{view.nom_view}</div>
									</div>
								</a>;
						})
                    }
        </div> 
      </Page>

    </>
  );
}

export default  withWidth()(View);
