import { call, put, select} from 'redux-saga/effects';
import api from '../../../config/axios';
import AgendamentoActions from '../../ducks/agendamento/agendamentos';
import lodash from 'lodash';
import {param} from 'jquery';
import moment from 'moment';

function trataRowsGrid(dados){
    
    let dtHoraAtual =  moment();

    let rows = lodash.map(dados, (item, key) => {
        let dtHoraAgenda = moment(item?.agenda?.dt_agenda+ 'T'+item?.agenda?.hr_agenda)
        return {
            ...item,
            id: key, 
            actions: ' ',
            nom_usuario: item?.usuario?.nom_usuario,
            dt_agenda: moment(item?.agenda?.dt_agenda).format("DD/MM/YYYY"),
            hr_agenda: item?.agenda?.hr_agenda,
            horaPassada: dtHoraAgenda < dtHoraAtual
        };
    });
    return rows;
}

export function* loadAgendamentosRequest({atracaoId, atracaoItemId, filtros, ordenacao, page}) {
    try {
    
        if(!atracaoId){
            throw new Error("Atração não encontrada.");
        }

        let request = !atracaoItemId ?  `atracao/${atracaoId}/agendamento` : `atracao/${atracaoId}/item/${atracaoItemId}/agendamento`;
        
        let pagina = page ?? (yield select((state) => state.experiencia.lista.page));

        let response = yield call(api.get, `${request}?${param(filtros)}&${param(ordenacao)}&page=${pagina}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        let data = yield trataRowsGrid(response.data?.data);
        response.data.data = data;
        yield put(AgendamentoActions.loadAgendamentosSuccess(response.data, page));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar agendamentos."
        yield put(AgendamentoActions.loadAgendamentosFailure(error));
    }
}
