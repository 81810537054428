import React, { useCallback, useEffect, useState } from 'react';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';
import DataGrid from '../../components/DataGrid';
import { Button, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import ExperienciasActions from '../../store/ducks/experiencia/experiencias';
import ExperienciaActions from '../../store/ducks/experiencia/experiencia';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from '../../components/Form/Campo/select';
import ExperienciaForm from '../../components/Form/ExperienciaForm';

function Experiencias(props) {

  const dispatch    = useDispatch();

  const {data, loading, error}  = useSelector(state => state.experiencia.lista);
  const experiencia             = useSelector(state => state.experiencia.itemCrud);

  const [openForm, setOpenForm] = useState(false);

  const onClickItem = (item) => {
    setOpenForm(item);
  }

  const onChangeStatus = (row, status) => {
    dispatch(ExperienciaActions.updateExperienciaStatusRequest(row?.experiencia_id, status));
  }

  const handleRowActions = (params) => (
    <div style={{width: '100%', textAlign: 'center'}} >
      <Button 
        variant="outlined" 
        color="secondary" 
        size="small" 
        startIcon={<FontAwesomeIcon icon="edit" style={{width: 12}}/>}
        onClick={() => onClickItem(params.row)} >
          Editar
      </Button>
    </div>
    
  )

  const handleStatus = ({row}) => ( <>
      {experiencia.loading && experiencia.experienciaId === row?.experiencia_id && <CircularProgress size={12} />}
      <Select 
        value={row?.cod_status_acesso}
        options={[{value: 'AGUARDE', title: 'Aguarde'}, {value: 'VALIDO', title: 'Válido'}, {value: 'FINALIZADO', title: 'Finalizado'}, {value: 'INVALIDO', title: 'Inválido'}]}
        setValue={(e) => onChangeStatus(row, e.target.value)}
        required={false}
        fullWidth
      />
    </>
  )

  const columns  = [
    { field: 'nom_usuario', headerName: 'Nome Usuário',flex: 2},
    { field: 'nom_perfil', headerName: 'Tipo', headerAlign: 'center', flex: 2 },
    { field: 'cod_chave_acesso', headerName: 'Código Acesso', headerAlign: 'center',align: 'center',flex: 2  },
    { field: 'dt_experiencia_ini_grid', headerName: 'Data Início', headerAlign: 'center',align: 'center', flex: 2  },
    { field: 'dt_experiencia_fim_grid', headerName: 'Data Fim', headerAlign: 'center',align: 'center', flex: 2 },
    { field: 'qtd_pessoa', headerName: 'Qtd. Pessoas', headerAlign: 'center',align: 'center', flex: 2 },
    { field: 'cod_status_acesso', headerName: 'Status',  headerAlign: 'center', align: 'center', renderCell: (params) =>  handleStatus(params), flex: 2 },
    { field: 'actions', headerName:' ', renderCell: (params) =>  handleRowActions(params), flex: 2}
  ]

  const carregar = useCallback((props) => {
    dispatch(ExperienciasActions.loadExperienciasRequest(false, false, props?.page));
  },[dispatch]);

  const botaoNovo =  <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon="plus" />} onClick={() => onClickItem()} > Nova</Button>

  useEffect(() => {
    carregar();
  }, [carregar]);

  return (<>
      <Page codControl={"MN_WEB_EXPERIENCIA"} conteudoDireita={botaoNovo} atualizar={carregar} loading={loading}>
        <DataGrid 
          reload={carregar}
          error={error || experiencia?.error}
          loading={loading}
          columns={columns}
          rows={data?.data ?? []}
          rowCount={data?.meta?.total}
          pageSize={15}
          onPageChange={carregar}
        />     
      </Page>
      {openForm !== false && <ExperienciaForm  open={true} close={() => setOpenForm(false)}  experiencia={openForm} />}
    </>
  );
}

export default  withWidth()(Experiencias);
