import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
    rowFoto:{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100%'
    },
    foto:{
        width: 38,
        height: 31,
        borderRadius: 5,
    },
    icon:{
		width:'15px !important'
	},
	button:{
		fontSize: 10
    },
    busca: {
        paddingLeft: "5px",
        paddingRight: "5px",
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center'
    }
   
}));