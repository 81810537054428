import { call, put, select} from 'redux-saga/effects';
import api from '../../config/axios';
import apiAuth from '../../config/auth';

import LoginActions from '../ducks/login';
import {Cookies} from 'react-cookie';
import history from "../../routes/history";
import Swal from 'sweetalert2';
import lodash from 'lodash';

function saveToken(access_token) {   
    let cookie = new Cookies(); 
    cookie.set('access_token',access_token, { path: '/' });
}


function saveCodInstalacao(dadosUser) {   
    let cookie = new Cookies();
    let codInstalacao = false;
    
    if(lodash.size(dadosUser?.instalacao_principal) > 0) {
        codInstalacao = dadosUser?.instalacao_principal?.instalacao?.cod_instalacao;
    }else{
        codInstalacao = dadosUser?.instalacao?.cod_instalacao;
    }

    cookie.set('cod_instalacao',codInstalacao, { path: '/' });
}


export function* login({ username, password}) {
    try {

        const config = { grant_type: 'password', username, password};
        const response = yield call(apiAuth.post, 'oauth/token', config );
        if(response.status !== 200 && response.status !== 201){
            throw response;
        }

        saveToken(response.data.access_token);

        let user = yield validateToken();
        yield loggedRequest(); 
        yield put(LoginActions.loginSuccess(user.data.data));

    } catch (error) {

        if(error.message  === "invalid_grant"){
            error.message = "Usuário e/ou senha inválido.";
        }else if(error?.message){
            error.message =  `Falha ao tentar autenticar. `;
        }else{
            error.message =  `Não foi possível fazer login. Tente novamente. `;
        }

        deleteToken();
        yield put(LoginActions.loginFailure(error));
    }
}

function deleteToken(){
    let cookie = new Cookies();
    cookie.remove('access_token', { path: '/' });
}

export function* logout(action) {
    try {
       
        deleteToken();
        yield put(LoginActions.logoutSuccess());
        history.push(`/login`);

    } catch (error) {
        yield put(LoginActions.logoutFailure(error));
    }
}
export function* validateToken() {
    try{
        let cookie = new Cookies();

        if(!cookie.get('access_token')){
            throw new Error('Sem Token');
        }
        
        const user = yield call(apiAuth.get, 'api/usuarioLogado');
        if(user.status !== 200 && user.status !== 201){
            throw new Error(user);
        }

        yield saveCodInstalacao(user?.data?.data);


        return user;
    }catch(error){
        throw error;
    }
}


export function* loggedRequest(action) {
    try{

        let user = yield validateToken();

        yield put(LoginActions.loggedSuccess(user.data?.data,true));

    }catch(error){
        yield put(LoginActions.loggedFailure(error));
    }
}


export function* recuperaSenhaRequest({dadosUrl, dados, callBackApp}) {
    try {
        
        const { usuarioToken }  = yield select(state => state.login);
        if(!dadosUrl.tokenSenha){
            throw new Error("O Token é inválido.");
        }

        let newDados = {senha_nova: dados.senha_nova, confirma_senha: dados.confirma_senha}
        let resposta = null;
        if(dadosUrl.instalacao){
            resposta = yield call(apiAuth.put, `${dadosUrl.instalacao}/recuperaSenha/${dadosUrl.tokenSenha}`, newDados);
        }else{
            resposta = yield call(api.put, `recuperaSenha/${dadosUrl.tokenSenha}`, newDados);
        }
        
        if(usuarioToken?.origem === 'web'){
            Swal.fire({
                title: "Senha alterada com sucesso!", 
                text: "Você será redirecionado para a tela inicial, para realizar o login.", 
                icon: 'success',
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then(() => {
                history.push(`/login`);
            });
        }else{
            if(callBackApp && typeof callBackApp === 'function'){
                callBackApp();
            }
        }

        yield put(LoginActions.recuperaSenhaSuccess(resposta.data.data));

    } catch (error) {
        yield put(LoginActions.recuperaSenhaFailure(error));
    }
}

export function* validaTokenSenhaRequest({tokenSenha, instalacao}) {
    try {

        if(!tokenSenha){
            throw new Error("O Token é inválido.");
        }
        let resposta = null;
        if(instalacao){
            resposta = yield call(apiAuth.get, `${instalacao}/recuperaSenha/${tokenSenha}`);
        }else{
            resposta = yield call(api.get, `recuperaSenha/${tokenSenha}`);
        }
        yield put(LoginActions.validaTokenSenhaSuccess(resposta.data));

    } catch (error) {
        yield put(LoginActions.validaTokenSenhaFailure(error));
    }
}


export function* esqueciSenhaRequest({email}) {
    try {

        if(!email){
            throw new Error("É obrigatório preencher o e-mail.");
        }
        yield call(apiAuth.post, `/esqueciSenha`, {email: email});

        yield put(LoginActions.esqueciSenhaSuccess(email));

    } catch (error) {
        yield put(LoginActions.esqueciSenhaFailure(error));
    }
}