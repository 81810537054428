import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadInstalacoesUsuarioRequest: [],
    loadInstalacoesUsuarioSuccess: ['lista'],
    loadInstalacoesUsuarioFailure: ['error'],

    updateInstalacaoUsuarioRequest: ['instalacaoId'],
});

export const UsuarioInstalacaoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    lista: [],
    loading: false,
    error: false,
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_INSTALACOES_USUARIO_REQUEST]: state =>
        state.merge({ loading: true, error: false, lista: [] }),
    [Types.LOAD_INSTALACOES_USUARIO_SUCCESS]: (state, action) =>
        state.merge({ lista: action.lista, loading: false, error: false }),
    [Types.LOAD_INSTALACOES_USUARIO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),

    [Types.UPDATE_INSTALACAO_USUARIO_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
});
