import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadViewRequest: null,
    loadViewSuccess: ['data'],
    loadViewFailure: ['error'],

    
});

export const ViewTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    error: false,
    loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_VIEW_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOAD_VIEW_SUCCESS]: (state, action) =>
        state.merge({ data: action.data,  logged:true, loading: false, error: false }),
    [Types.LOAD_VIEW_FAILURE]: (state, action) =>
        state.merge({ loading: false, logged:false, error: action.error }),

});
