import React from 'react';
import { useStyles } from './styles';
import { Button} from '@material-ui/core';

import lodash from 'lodash';

MenuHeader.defaultProps = {
  menuLista:[], 
  onClick: ()=>{}, 
  menuSelecionado: {}
}

function MenuHeader({menuLista, onClick, menuSelecionado}) {

    const classes               = useStyles();
	
    let renderItemMenu = (menu)=>{
      return  <Button color="inherit" onClick={()=>onClick(menu)} aria-label={menu.des_label_control} tabIndex={0}
                  className={menuSelecionado?.cod_control === menu.cod_control ? classes.buttonActive : classes.button}> 
                {menu.des_label}
            </Button>
    }
    return (<>
      {lodash.map(menuLista, (menu)=>{
            return renderItemMenu(menu);
        })
      }
    </>
  );
}

  export default MenuHeader;