import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadTemaRequest: ['codInstalacao'],
    loadTemaSuccess: ['data'],
    loadTemaFailure: ['error'],
});

export const TemaTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    error: false,
    loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_TEMA_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOAD_TEMA_SUCCESS]: (state, action) =>
        state.merge({ data: action.data,  logged:true, loading: false, error: false }),
    [Types.LOAD_TEMA_FAILURE]: (state, action) =>
        state.merge({ loading: false, logged:false, error: action.error }),

});
