import { call, put } from 'redux-saga/effects';
import api from '../../config/axios';
import ViewActions from '../ducks/view';


export function* loadViewRequest() {
    try {
        let view = yield call(api.get, `view`);
        if(view.status !== 200){
            throw view;
        }
        yield put(ViewActions.loadViewSuccess(view.data.data));
    } catch (error) {
        yield put(ViewActions.loadViewFailure(error));
    }
}