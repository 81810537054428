import { Box, Button, CircularProgress, Paper, InputLabel } from '@material-ui/core';
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Texto from '../Form/Campo/texto';
import Imagem from '../Form/Campo/imagem';
import Select from '../Form/Campo/select';
import { useStyles } from './styles'
import Loading from '../Loading';
import EditorHTML from '../EditorHTML'
import DateTime from '../Form/Campo/dateTime';
import SelectSemana from '../Form/Campo/selectSemana';
import Error from '../Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComunicacaoActions from '../../store/ducks/comunicacao/comunicacao'
// import AtracoesActions from '../../store/ducks/atracao/atracoes'
import IndSituacao from '../Form/Campo/indSituacao';

export default function ComunicacaoForm() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { data, loading, error } = useSelector(state=>state.comunicacao.itemCrud)
    const { optionsCodDestino } = useSelector(state=>state.comunicacao.lista)
    const atracoes  = useSelector(state => state.atracao.lista);

    const { register, control, reset, errors, watch, handleSubmit } = useForm();
    const codDestino = watch('cod_destino')

    // const buscaAtracoes = (nomAtracao) => {
    //     dispatch(AtracoesActions.loadAtracoesRequest({ busca_texto: nomAtracao }, false, 1))
    // }

    const save = (dados) => {
        dispatch(ComunicacaoActions.saveComunicacaoRequest(data?.comunicacao_id, dados))
    }

    useEffect(() => {
        reset({
            ...data,
            list_dia_semana: data.list_dia_semana?.asMutable()
        })
    }, [reset, data])

    return (<>
        <Loading loading={loading} size={30}/>
        <Paper square className={classes.root} elevation={1}>
            {!loading && <form className={classes.formulario}>
                <div className={classes.row}>
                    <Controller 
                        name={`arq_imagem_chamada`}  
                        control={control} 
                        rules={{required: true}}
                        defaultValue={null}
                        render={({ value, onChange }) => 
                            <Imagem 
                                name={`arq_imagem_chamada`}  
                                url={value ?? data?.arq_imagem_chamada}
                                width={280}
                                height={60}
                                onChange={(file) => onChange(file[0]?.data)}
                                containerStyle={classes.image}
                            />
                        }
                    />
                    <Texto
                        label="Título"
                        name='des_titulo'
                        inputRef={register({ required: true })}
                        error={Boolean(errors.des_titulo)}
                        helperText={Boolean(errors.des_titulo) && 'Campo obrigatório'}
                        fullWidth
                        defaultValue={null}
                    />
                    <Controller 
                        name={`ind_situacao`}  
                        control={control} 
                        rules={{required: true}}
                        defaultValue={'A'}
                        render={({ value, onChange }) => 
                            <IndSituacao
                                indSituacao={value}
                                onSelect={onChange} 
                            />
                        }
                    />
                </div>
                <div className={classes.row}>
                    <Controller 
                        name="cod_destino"
                        control={control} 
                        rules={{required: true}}
                        defaultValue='PAGINA'
                        render={({ onChange, value }) => 
                            <Select 
                                style={{width: 220, marginLeft: 20}}
                                label={"Destino"}
                                value={value}
                                options={optionsCodDestino}
                                setValue={onChange}
                                required={true}
                                fullWidth
                            />
                        }
                    />
                    {codDestino === 'LINK' && <Texto
                        label="Url"
                        name='des_destino'
                        inputRef={register({ required: true })}
                        error={Boolean(errors.des_destino)}
                        helperText={Boolean(errors.des_destino) && 'Campo obrigatório'}
                        fullWidth
                        defaultValue={null}
                    />}
                    {codDestino === 'ATRACAO' && <Controller 
                        name="des_destino"
                        control={control} 
                        rules={{required: true}}
                        defaultValue={null}
                        render={({ onChange, value }) => (<>
                            <Select 
                                fullWidth
                                component="autocomplete"
                                label="Atração"
                                disabled={atracoes.error}
                                options={atracoes.data?.data}
                                optionKey={'atracao_id'}
                                optionLabel={'nom_atracao'}
                                value={value}
                                setValue={(e,value) => onChange(value)}
                                loading={atracoes.loading}
                                required={true}
                                // onInputChange={(value) => buscaAtracoes(value)}
                                // renderInputProps={{
                                //     onChange: (value) => buscaAtracoes(value)
                                // }}
                            />
                        </>)}
                    />}
                    {codDestino === 'ITEM' && <Controller 
                        name="des_destino"
                        control={control} 
                        rules={{required: true}}
                        defaultValue={null}
                        render={({ onChange, value }) => (<>
                            <Select 
                                fullWidth
                                component="autocomplete"
                                label="Atração"
                                disabled={atracoes.error}
                                options={atracoes.data?.data}
                                optionKey={'atracao_id'}
                                optionLabel={'nom_atracao'}
                                value={value}
                                setValue={(e,value) => onChange(value)}
                                loading={atracoes.loading}
                                required={true}
                                // renderInputProps={{
                                //     onChange: (value) => buscaAtracoes(value)
                                // }}
                            />
                        </>)}
                    />}
                </div>
                <div className={classes.row}>
                    <Controller
                        name='dt_comunicacao_ini'
                        control={control}
                        rules={{required: true}}
                        defaultValue={null}
                        render={({ value, onChange }) =>
                            <DateTime
                                fullWidth
                                value={value}
                                setValue={onChange} 
                                onlyDate
                                label='Data de Início'
                            /> 
                        }
                    />
                    <Controller
                        name='dt_comunicacao_fim'
                        control={control}
                        rules={{required: false}}
                        defaultValue={null}
                        render={({ value, onChange }) =>
                            <DateTime
                                fullWidth
                                value={value}
                                setValue={onChange} 
                                onlyDate
                                label='Data Fim'
                                required={false}
                            /> 
                        }
                    />
                </div>
                <div className={classes.row}>
                    <Controller
                        name='hr_comunicacao_ini'
                        control={control}
                        rules={{required: false}}
                        defaultValue={null}
                        render={({ value, onChange }) =>
                            <DateTime
                                fullWidth
                                value={value}
                                setValue={onChange} 
                                onlyTime
                                label='Hora Início'
                                required={false}
                            /> 
                        }
                    />
                    <Controller
                        name='hr_comunicacao_fim'
                        control={control}
                        rules={{required: false}}
                        defaultValue={null}
                        render={({ value, onChange }) =>
                            <DateTime
                                fullWidth
                                value={value}
                                setValue={onChange} 
                                onlyTime
                                label='Hora Fim'
                                required={false}
                            /> 
                        }
                    />
                    <Controller 
                        name="list_dia_semana"  
                        control={control}
                        defaultValue={null}
                        render={({ onChange, value }) => 
                            <SelectSemana 
                                fullWidth
                                required={false}
                                name="list_dia_semana"  
                                label="Dias da semana"
                                setValue={onChange}
                                value={value ?? []}
                                error={Boolean(errors?.list_dia_semana)}
                                helperText={errors?.list_dia_semana && "O campo é obrigatório."}
                            /> 
                        }
                    />
                </div>
                {(codDestino === 'PAGINA' || !codDestino) && <>
                    <div style={{margin: 10}}>
                        <InputLabel htmlFor="des_conteudo">Conteúdo html:</InputLabel>
                    </div>
                    <div className={classes.row}>
                     <Controller
                        name='des_conteudo'
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({ value, onChange }) => 
                            <div style={{margin: 10}}>
                                <EditorHTML
                                    value={value}
                                    onChange={onChange}
                                />
                            </div>
                        }
                        />
                        </div></>
                    }
            </form>}
        </Paper>
            <Box display="flex" justifyContent="flex-end" flexGrow={1} pr={1}> 
                <Button 
                    onClick={handleSubmit(save)}
                    variant="contained"
                    color="secondary"
                    endIcon={<FontAwesomeIcon icon="chevron-right" /> }
                    disabled={loading}
                    style={{margin: 10}}
                > 
                {(loading) && <CircularProgress size={15} /> } salvar 
                </Button>
            </Box>

            <Error error={error} showAlert={false} alertMessage={error} />
    </>)
}