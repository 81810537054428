import React, { useCallback, useEffect } from 'react';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';
import { useDispatch } from 'react-redux';
import AtracaoActions from '../../store/ducks/atracao/atracao';
import history from '../../routes/history';
import AtracaoForm from '../../components/Form/AtracaoForm';

function Atracao(props) {

  const dispatch    = useDispatch();

  const atracao = history.location.state;
  let title = atracao?.nome ? `Edição - ${atracao?.nome}` : false;
  let tituloIncremento = "Criação - ";

  const carregar = useCallback((atracaoId) => {
    dispatch(AtracaoActions.loadAtracaoRequest(atracaoId));
  }, [dispatch]);

  
  useEffect(() => {
    carregar(atracao?.atracaoId);   
  }, [carregar, atracao]);

  return (
    <>
      <Page title={title} codControl={"MN_WEB_ATRACAO"} tituloPrefixo={tituloIncremento} conteudoDireita={false} atualizar={false} loading={false} botaoVoltar>
        <AtracaoForm atracaoId={atracao?.atracaoId} openTab={atracao?.openTab} />
      </Page>
    </>
  );
}

export default  withWidth()(Atracao);
