import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    uploadArquivoRequest: ['arquivo', 'tipo'],
    uploadArquivoSuccess: ['data'],
    uploadArquivoFailure: ['error'],

});

export const UploadTypes = Types;
export default Creators;


export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    error: false,
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPLOAD_ARQUIVO_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.UPLOAD_ARQUIVO_SUCCESS]: (state, action) =>
        state.merge({  loading: false, error: false }),
    [Types.UPLOAD_ARQUIVO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
 
});