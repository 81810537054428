import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(1),
    },
    formulario:{
      minHeight: 'calc(100vh - 390px)'

      // padding: theme.spacing(2)
    },
    row:{
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(.5),
     
      '& .MuiFormControl-root':{
        margin: theme.spacing(.5),
        alignSelf: 'flex-end'
      },
      '& .MuiAutocomplete-root':{
        alignSelf: 'flex-end', marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
       
      },
    },
    imagem:{
      width: 100,
      height: 50,
      borderRadius: 5
    }
}));
