import { call, put, race, take, select} from 'redux-saga/effects';
import api from '../../../config/axios';
import ProgramacaoItemActions from '../../ducks/programacao/programacaoItem';
import ProgramacaoItemCaracterizadoresActions from '../../ducks/programacao/caracterizadores';
import UploadActions from '../../ducks/upload';
import history from '../../../routes/history';
import SuccessActions from '../../ducks/success';

export function* loadProgramacaoItemRequest({atracaoId, atracaoItemId}) {
    try {

        if(!atracaoId){
            throw Error("Atração não encontrada.");
        }

        let dados = {
            icone_atracao_item: null,
            imagem_atracao_item: null,
        };

        if(atracaoItemId){

            let response = yield call(api.get, `atracao/${atracaoId}/item/${atracaoItemId}`);
            
            dados = {
                ...response.data?.data,
                ...dados
            };

            if(response.status !== 200 && response.status !== 201){
                throw response;
            }

            yield put(ProgramacaoItemCaracterizadoresActions.loadProgramacaoItemCaracterizadoresRequest(atracaoId, atracaoItemId));

            const {caracterizadorError} = yield race({
                caracterizadorError:  take(ProgramacaoItemCaracterizadoresActions.loadProgramacaoItemCaracterizadoresFailure().type),
                success: take(ProgramacaoItemCaracterizadoresActions.loadProgramacaoItemCaracterizadoresSuccess().type),
            });

    
            if(caracterizadorError){
                throw caracterizadorError;
            }
        }

      

        yield put(ProgramacaoItemActions.loadProgramacaoItemSuccess(dados, atracaoId, atracaoItemId));

    } catch (error) {
        error.message = "Erro ao carregar programação."
        yield put(ProgramacaoItemCaracterizadoresActions.loadProgramacaoItemCaracterizadoresFailure(error));
    }
}




function* uploadImagem(imagem) {
    
    yield put(UploadActions.uploadArquivoRequest(imagem));

    const {error, success} = yield race({
        error:  take(UploadActions.uploadArquivoFailure().type),
        success: take(UploadActions.uploadArquivoSuccess().type),
    });

    return {error, success}
}

export function* saveProgramacaoItemRequest({ atracaoId, atracaoItemId, dados}) {
    try {

        if(!atracaoId){
            throw Error("Atração não encontrada!");
        }

        let dadosAtuais  = yield select((state) => state.programacao.itemCrud.dadosBasicos.data);
        let dadosEnvio = atracaoItemId ? {...dadosAtuais, ...dados} : dados;

        if(dados?.imagem_atracao_item){
            let { error, success} = yield uploadImagem(dados?.imagem_atracao_item);
            if(error){
                throw error;
            }
            dadosEnvio = {...dadosEnvio, arq_imagem_atracao_item: success?.data}
        }

        if(dados?.icone_atracao_item){
            let { error, success} = yield uploadImagem(dados?.icone_atracao_item);
            if(error){
                throw error;
            }
            dadosEnvio = {...dadosEnvio, des_icon_atracao_item: success?.data}
        }

        let request    = atracaoItemId ? api.put : api.post;
        let url        = atracaoItemId ?  `atracao/${atracaoId}/item/${atracaoItemId}` :  `atracao/${atracaoId}/item`;

        let response = yield call(request, url, dadosEnvio);

        if(!atracaoId){
            history.push('/programacaoItem', {atracaoId: response?.data?.data?.atracao_id, atracaoItemId:  response?.data?.data?.atracao_item_id, nome: response?.data?.data?.nom_atracao_item, openTab: 1})
        }

        yield put(SuccessActions.setSuccess(true, 'Programação salva com sucesso!'));
        yield put(ProgramacaoItemActions.saveProgramacaoItemSuccess(response?.data?.data?.atracao_id, response?.data?.data?.atracao_item_id, response?.data?.data));

    } catch (error) {
        error.message = error.message ?? "Erro ao salvar programação."
        yield put(ProgramacaoItemActions.saveProgramacaoItemFailure(error));
    }
}

