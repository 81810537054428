import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Texto from '../Campo/texto';
import CaracterizadoresActions from '../../../store/ducks/caracterizador/caracterizadores';
import ProgramacaoItemCaracterizadoresActions from '../../../store/ducks/programacao/caracterizadores';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Select from '../Campo/select';
import Loading from '../../Loading';
import lodash from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonSuccess from '../../ButtonSuccess';
import Error from '../../Error';
import ButtonCancel from '../../ButtonCancel';

export default function Caracterizadores({atracaoId, atracaoItemId, isFormEdited, setIsFormEdited}) {
  
  const dispatch                     = useDispatch();
  const classes                      = useStyles();
  const {data, loading, loadingCreate, error}       = useSelector(state => state.programacao.itemCrud.caracterizadores);
  const caracterizadorSetup                         = useSelector(state => state.caracterizador.lista);

  const { register, control, handleSubmit, reset, getValues, formState} = useForm();
  const [caracterizadores, setCaracterizadores] = useState();
  
  const save = (data) => {
    dispatch(ProgramacaoItemCaracterizadoresActions.saveProgramacaoItemCaracterizadoresRequest(atracaoId, atracaoItemId, data));
  }

  const podeAdicionar = () => {
    if(lodash.size(caracterizadorSetup.data?.data) > lodash.size(caracterizadores)){
      return true
    }else{
      return false;
    }
  }

  const removeItem = (key) => {

      let newData = lodash.remove(getValues().caracterizadores, (itens, index) =>  index !== key);
      setIsFormEdited(true);
      setCaracterizadores(newData);
    
  }

  const novoItem = () => {
    if(podeAdicionar){
      let lista = getValues()?.caracterizadores ? [...getValues().caracterizadores] : [];
      lista.push({});
      setIsFormEdited(true);
      setCaracterizadores(lista)
    }
  }

  const buscaCaracterizadores = useCallback((busca) => {
    dispatch(CaracterizadoresActions.loadCaracterizadoresRequest(busca));
  }, [dispatch]);

  useEffect(() => {
    setIsFormEdited(formState.isDirty)
  }, [formState,setIsFormEdited]);
  
  useEffect(() => {
    buscaCaracterizadores();
  }, [buscaCaracterizadores]);
  

  useEffect(() => {
    let valores = [];

    if(lodash.size(data) > 0){
      valores = [...data];
    }

    setCaracterizadores(valores);
    
    reset({caracterizadores: valores});
  }, [data, reset]);


  return (<>
        <Loading  loading={loading} size={30} />
        {!loading && 
          <form className={classes.formulario} onSubmit={handleSubmit(save)}>
                <> 
                {lodash.map(caracterizadores, (item, index) => {
                  const fieldName = `caracterizadores[${index}]`;
                  return (
                    <div key={index} className={classes.row}>
                      
                      <Controller
                        name={`${fieldName}.setup`}   
                        control={control} 
                        rules={{required: true}}
                        defaultValue={item?.setup}
                        render={({ onChange, value }) => (
                          <Select
                              component="autocomplete"
                              label="Caracterizador"
                              disabled={caracterizadorSetup.error}
                              options={caracterizadorSetup.data?.data}
                              optionKey={'caracterizador_setup_id'}
                              optionLabel={'des_label_caracterizador'}
                              value={value}  
                              setValue={(e,value) => onChange(value)}
                              loading={caracterizadorSetup.loading}
                              renderInputProps={{onChange: (e) =>  buscaCaracterizadores(e?.target?.value)}}
                              style={{flex: 1}}
                              renderOption={(option) => (
                                <Typography> <FontAwesomeIcon icon={option.des_icone_caracterizador} /> {option.des_label_caracterizador}</Typography>
                              )}
                              InputProps={{startAdornment:  <FontAwesomeIcon icon={value?.des_icone_caracterizador} />}}
                          />
                        )}
                      />
                      <Texto 
                          fullWidth
                          size="small"
                          label="Descrição"
                          name={`${fieldName}.val_caracterizador`}
                          inputRef={register({ required: true })}
                          required={true}
                          style={{flex: 2}}
                      />
                      <Box flex={1} display={'flex'} m={1} >
                        <ButtonCancel  size="small" onClick={() => removeItem(index)} startIcon={<FontAwesomeIcon icon="trash" />} style={{margin: 5}} >
                          remover
                        </ButtonCancel>
                        {podeAdicionar() && (index+1) === lodash.size(caracterizadores) && 
                          <ButtonSuccess color="secondary" size={"small"} onClick={() => novoItem()} startIcon={<FontAwesomeIcon icon="plus" />} style={{margin: 5}} >
                            adicionar
                          </ButtonSuccess>
                        }
                      </Box>
                    </div>
                  )
                })
              }
              {podeAdicionar() &&  lodash.size(caracterizadores)  === 0 && 
                <ButtonSuccess size={"small"} color="secondary" onClick={() => novoItem()} startIcon={<FontAwesomeIcon icon="plus" />}  >
                  adicionar
                </ButtonSuccess>
              }
              </>
          </form>
        } 
        <Box display="flex" justifyContent="flex-end" flexGrow={1} pr={1}> 
          <Button  onClick={handleSubmit(save)} variant="contained" color="secondary" endIcon={<FontAwesomeIcon icon="chevron-right" /> } disabled={!isFormEdited} > 
            {(loadingCreate || loading) && <CircularProgress size={15} /> } salvar 
          </Button>
        </Box>
      <Error error={error} showAlert={false} alertMessage={error} />
     </>
  );
}