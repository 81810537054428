import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './styles';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';
import DataGrid from '../../components/DataGrid';
import { useDispatch, useSelector } from 'react-redux';
import ProgramacaoActions from '../../store/ducks/programacao/programacao';
import { Avatar, Button, ButtonGroup } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../routes/history';
import AglomeracaoForm from '../../components/Form/AglomeracaoForm';

function Programacao(props) {

  const classes     = useStyles();
  const dispatch    = useDispatch();
  const atracao     = history.location.state;

  let title = `Programação - ${atracao?.nome}`;

  const [dialogAglomera, setDialogAglomera] = useState(false);
  const {data, loading, error}  = useSelector(state => state.programacao.lista);

  const carregar = useCallback((props) =>{
    dispatch(ProgramacaoActions.loadProgramacaoRequest(atracao?.atracaoId, false, false, props?.page));
  }, [dispatch, atracao]);

  const onClickItem = (destino, row) => {
    switch (destino) {
      case 'aglomeracao':
          setDialogAglomera(row);
        break;
      default:
        history.push(`/${destino}`, {atracaoId: row?.atracao_id, atracaoItemId: row?.atracao_item_id, nome: row?.nom_atracao_item} );
        break;
    }
  }
  
  const handleImagem = (params) => (
    <div className={classes.rowFoto} >
      <Avatar alt={params?.row?.nom_atracao_item} src={params?.row?.arq_imagem_atracao_item} className={classes.foto} variant="square" />
    </div>
  )

  const handleRowActions = (params) => (
    <ButtonGroup variant="outlined" color="secondary" size="small" className={classes.group}>
      <Button className={classes.button} startIcon={<FontAwesomeIcon icon="edit" className={classes.icon} />} onClick={() => onClickItem('programacaoItem',params?.row)} >Editar </Button>
      <Button className={classes.button} startIcon={<FontAwesomeIcon icon="users" className={classes.icon} />} onClick={() => onClickItem('aglomeracao',params?.row)} >Aglomeração </Button>
      <Button className={classes.button} startIcon={<FontAwesomeIcon icon="calendar" className={classes.icon} />} onClick={() => onClickItem('agenda', params?.row)} >Agenda </Button>
    </ButtonGroup>
  )
 
  const columns  = [
    { field: 'des_icone_atracao_icone', headerName: 'Foto', renderCell: (params) =>  handleImagem(params), flex: 0.5 },
    { field: 'nom_atracao_item', headerName: 'Nome', flex: 1 },
    { field: 'des_atracao_item', headerName: 'Descrição', flex: 2 },
    { field: 'actions', headerName: ' ', renderCell: (params) =>  handleRowActions(params),width: 310 }
  ]

  useEffect(() => {
    carregar();
  }, [atracao, carregar]);

  const botaoNovo =  <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon="plus" />} onClick={() => onClickItem('programacaoItem',{atracao_id: atracao?.atracaoId })} > Nova</Button>


  return (<>
    <Page title={title} botaoVoltar conteudoDireita={botaoNovo} atualizar={carregar} loading={loading}>
       <DataGrid
          reload={carregar}
          error={error}
          loading={loading}
          columns={columns}
          rows={data?.data ?? []}
          rowCount={data?.meta?.total}
          pageSize={15}
          onPageChange={carregar}
        />     
    </Page>
    {dialogAglomera !== false && 
        <AglomeracaoForm
          open={dialogAglomera !== false}
          atracao={dialogAglomera}
          close={() => setDialogAglomera(false)}
        />
      } 
    </>
  );
}

export default  withWidth()(Programacao);
