import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  editor : {
    display: 'block',
    border: '1px solid #ccc',
    borderRadius: '5px',
    minHeight: 200,
    width: 'auto'
  },
  hideEditor:{
    display: 'none'
  },
  htmlButton: {
    backgroundColor: 'white'
  },
  textAreaHtml:{
    width: '100%',
    minHeight: 200
  }
}));