import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

function DialogTitle(props) {

    const { children, onClose, classes} = props;

	return (
      <MuiDialogTitle disableTypography style={{padding: 10}}>
      {  <Typography variant="h6">{children}</Typography>}
        {onClose ? (
          <IconButton aria-label="Fechar janela" className={classes.closeButton} onClick={() => onClose()} size="small">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );

}

export default  (DialogTitle);
