import React, { useEffect, useState } from 'react';
import Dialog from '../../Dialogs/Dialog';
import ButtonSuccess from '../../ButtonSuccess';
import ButtonCancel from '../../ButtonCancel';
import { Box, CircularProgress, Typography, useTheme, Button } from '@material-ui/core';
import Error from '../../Error';
import AglomeracaoActions from '../../../store/ducks/aglomeracao';
import { useDispatch, useSelector } from 'react-redux';
import {useStyles} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';

function AglomeracaoForm({open, close, atracao}) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();

    const { data, error, loading } = useSelector(state => state.aglomeracao);

    const [selecionado, setSelecionado] = useState(false);

    const handleClick= (item) =>{
        setSelecionado(item);
    }

    const handleSave = () =>{
        dispatch(AglomeracaoActions.createAglomeracaoRequest(atracao?.atracao_id, atracao?.atracao_item_id, selecionado, () => {
            close()
        }));
    }

    const renderItem = (item) => {

        let colors = {
            '1' : theme.palette.success.main,
            '2' : "#f2c307",
            '3' : theme.palette.error.main
        }

        return (
            <Button onClick={() => handleClick(item)} key={item?.status_aglomeracao_id} className={selecionado?.status_aglomeracao_id === item?.status_aglomeracao_id ? classes.selecionado : classes.button}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <FontAwesomeIcon icon={['fas', item.des_icone_status]} size="2x" color={colors[item.num_ordem]} />
                    <Typography variant="subtitle2" style={{color: colors[item.num_ordem]}}>{item.nom_status}</Typography>
                </Box>
            </Button>
        )

    }


    useEffect(() => {
        dispatch(AglomeracaoActions.loadStatusRequest());
    }, [dispatch]);


	return (	
		
        <Dialog  
            open={open} 
            close={()=> close()}
            title={"Aglomeração"}
            content={<>
               <Box minWidth={400} margin={.5}>
                   <Box display="flex" flexDirection="column" justifyContent="center">
                    {lodash.size(data) > 0 && <>
                        <Typography variant="subtitle2" className={classes.titulo}> Como está a aglomeração no local? </Typography>
                        <Box display="flex" justifyContent="space-around" paddingTop={3} >
                            {lodash.map(data, item => renderItem(item))}
                        </Box> 
                        </>
                    }
                    {lodash.size(data) === 0 && loading && <CircularProgress size={30}  style={{alignSelf: 'center'}}/>}
                   </Box>
               </Box>
               <Error error={error} showAlert={false} />
               </>
            }
            actions={<Box display="flex">
               <ButtonCancel onClick={()=> close()} > Cancelar </ButtonCancel>
               <ButtonSuccess autoFocus onClick={()=> handleSave()} disabled={loading || !selecionado} >
                    {loading && <CircularProgress size={15} />} Salvar
                </ButtonSuccess>
            </Box>
            }
        />     
    )

}

export default  (AglomeracaoForm);
