import React from 'react';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';


function Home(props) {

  const classes     = useStyles();
  
  return (
        <Container maxWidth={(isWidthUp('md',props.width)?'lg':'xs')} className={classes.container}>

        </Container>
  );
}

export default  withWidth()(Home);
