import React, {useEffect, useState}  from 'react';
import Alert from '@material-ui/lab/Alert';
import {Button, Snackbar} from '@material-ui/core';
import { errorDescription } from './errorDescription';


function Error({error, duration, carregar, alertMessage, showAlert, key, onClose}) {
    
    let [errorEdit, setErrorEdit] = useState(false);

    let closeError = ()=>{
        setErrorEdit(false);
        
        if(onClose)
            onClose();
    }

    useEffect(()=>{
        if(error){
          setErrorEdit(true);
        }
    }, [error]);

	return (	
        <>
            <Snackbar  
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }} 
                open={errorEdit} 
                autoHideDuration={duration} 
                key={key??`agenda-errors`}
                onClose={closeError}
            >
                <Alert onClose={closeError} severity="error">
                    {!error?.message ? alertMessage: errorDescription(error)}
                    {carregar && <Button color="primary" onClick={ () => {carregar(); closeError(); }} size="small">Tentar de novo</Button>}
                </Alert>
            </Snackbar>
            { error && showAlert &&
                <Alert severity="error">
                    {alertMessage}     
                    {carregar && <Button color="primary" onClick={ () => {carregar(); closeError(); }}>Tentar de novo</Button>}
                </Alert>
            }
        </>
	)

}

export default  (Error);
