import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadPeriodosRequest: [ 'atracaoId','atracaoItemId'],
    loadPeriodosSuccess: ['atracaoId','atracaoItemId','data'],
    loadPeriodosFailure: ['error'],

    savePeriodoRequest: [ 'atracaoId', 'atracaoItemId', 'dados', 'callback'],
    savePeriodoSuccess: ['atracaoId','atracaoItemId', 'agendaSetupId'],
    savePeriodoFailure: ['error'],

    removePeriodoRequest: [ 'atracaoId', 'atracaoItemId', 'agendaSetupId'],
    removePeriodoSuccess: ['atracaoId','atracaoItemId', 'agendaSetupId'],
    removePeriodoFailure: ['error'],


    clearPeriodosRequest: null
});

export const PeriodosTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    loading: false,
    loadingCreate: false,
    error: false,
    agendaSetupId: false,
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_PERIODOS_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:[]}),
    [Types.LOAD_PERIODOS_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_PERIODOS_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
    
    [Types.SAVE_PERIODO_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false}),
    [Types.SAVE_PERIODO_SUCCESS]: (state, action) =>
        state.merge({ agendaSetupId: action.agendaSetupId,loadingCreate: false, error: false }),
    [Types.SAVE_PERIODO_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),

    [Types.REMOVE_PERIODO_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false}),
    [Types.REMOVE_PERIODO_SUCCESS]: (state, action) =>
        state.merge({ agendaSetupId: action.agendaSetupId,loadingCreate: false, error: false }),
    [Types.REMOVE_PERIODO_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),

    [Types.CLEAR_PERIODOS_REQUEST]: (state) =>
        state.merge(INITIAL_STATE),
});

