import React from 'react';
import Dialog from '../Dialog';
import { Typography } from '@material-ui/core';
import ButtonCancel from '../../ButtonCancel';
import ButtonSuccess from '../../ButtonSuccess';


function DialogSair({open, close, discart}) {

	return (	
		
        <Dialog 
            open={open}
            close={close}
            title={"Deseja cancelar?"}
            content={
                <Typography variant="subtitle1" >
                    As alterações realizadas serão descartadas. Deseja Continuar?
                </Typography>
            }
            actions={
                <>
                    <ButtonCancel onClick={discart} aria-label="Sim, descartar alterações.">
                        SIM
                    </ButtonCancel>
                    <ButtonSuccess onClick={close}  autoFocus aria-label="Não, manter alterações."> 
                        NÃO
                    </ButtonSuccess>
                </> 
            }
        />
	)

}

export default  (DialogSair);
