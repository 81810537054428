import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    updateExperienciaStatusRequest: ['experienciaId', 'status'],
    updateExperienciaStatusSuccess: null,
    updateExperienciaStatusFailure: ['error'],

    saveExperienciaRequest: ['experienciaId', 'dados', 'callback'],
    saveExperienciaSuccess: null,
    saveExperienciaFailure: ['error']
});

export const ExperienciaTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    experienciaId: false,
    loading: false,
    error: false
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_EXPERIENCIA_STATUS_REQUEST]: (state, action)=>
        state.merge({ loading: true, error: false, experienciaId: action.experienciaId }),
    [Types.UPDATE_EXPERIENCIA_STATUS_SUCCESS]: (state, action) =>
        state.merge({ loading: false, error: false, experienciaId: false }),
    [Types.UPDATE_EXPERIENCIA_STATUS_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error, experienciaId: false }),
    
    [Types.SAVE_EXPERIENCIA_REQUEST]: (state, action)=>
        state.merge({ loading: true, error: false,  experienciaId: action.experienciaId }),
    [Types.SAVE_EXPERIENCIA_SUCCESS]: (state, action) =>
        state.merge({ loading: false, error: false, experienciaId: false }),
    [Types.SAVE_EXPERIENCIA_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error, experienciaId: false }),
});
