import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWrapper from './routeWrapper';
import LayoutDefault from '../components/Layout/LayoutDefault';
import LayoutTema from '../components/Layout/LayoutTema';

import Atracao from '../pages/Atracao';
import View from '../pages/View';
import history from './history';

import Login from '../pages/Login';
import Home from '../pages/Home';
import Atracoes from '../pages/Atracoes';
import ProgramacaoItem from '../pages/ProgramacaoItem';

import Experiencias from '../pages/Experiencias';
import Programacao from '../pages/Programacao';
import Agenda from '../pages/Agenda';
import RecuperaSenha from '../pages/RecuperaSenha';

import { ConnectedRouter } from 'connected-react-router';
import EsqueciSenha from '../pages/EsqueciSenha';
import Comunicacoes from '../pages/Comunicacoes';
import Comunicacao from '../pages/Comunicacao';

export default function Routes() {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <RouteWrapper path="/login" exact component={Login} redirectIfLogged layout={LayoutTema} />

                <RouteWrapper path="/home" isPrivate component={Home} layout={LayoutDefault} />
                <RouteWrapper path="/atracao" isPrivate component={Atracao} layout={LayoutDefault} />
                <RouteWrapper path="/view" isPrivate component={View} layout={LayoutDefault} />
                <RouteWrapper path="/atracoes" isPrivate component={Atracoes} layout={LayoutDefault} />
                <RouteWrapper path="/experiencias" isPrivate component={Experiencias} layout={LayoutDefault} />
                <RouteWrapper path="/comunicacao/:comunicacaoId" isPrivate component={Comunicacao} layout={LayoutDefault} />
                <RouteWrapper path="/comunicacoes" isPrivate component={Comunicacoes} layout={LayoutDefault} />
                <RouteWrapper path="/programacao" isPrivate component={Programacao} layout={LayoutDefault} />
                <RouteWrapper path="/agenda" isPrivate component={Agenda} layout={LayoutDefault} />

                <RouteWrapper path="/programacaoItem" isPrivate component={ProgramacaoItem} layout={LayoutDefault} />
                <RouteWrapper path="/:instalacao/recuperaSenha/:tokenSenha" redirectIfLogged isPrivate component={RecuperaSenha} layout={LayoutTema} />
                <RouteWrapper path="/esqueciSenha" redirectIfLogged isPrivate component={EsqueciSenha} layout={LayoutTema} />
                <RouteWrapper component={Login} redirectIfLogged layout={LayoutTema} />
            </Switch>
        </ConnectedRouter>
    );
}