import { call, put} from 'redux-saga/effects';
import api from '../../config/axios';
import AcomodacoesActions from '../ducks/acomodacoes';
import {param} from 'jquery';

export function* loadAcomodacoesRequest({busca}) {
    try {

        let request =  `acomodacao` ;
        
        let filtro  = {};

        if(busca){
            filtro = {des_acomodacao: busca}
        }

        let response = yield call(api.get, `${request}?${param(filtro)}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        yield put(AcomodacoesActions.loadAcomodacoesSuccess(response.data));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar acomodações."
        yield put(AcomodacoesActions.loadAcomodacoesFailure(error));
    }
}
