import { call, put, select} from 'redux-saga/effects';
import api from '../../../config/axios';
import PeriodosActions from '../../ducks/agenda/periodos';
import SuccessActions from '../../ducks/success';

import {param} from 'jquery';
import lodash from 'lodash';
import Swal from 'sweetalert2';

function trataDados(dados){
    
    let rows = lodash.map(dados, (item, key) => {
        return {
            ...item,
            list_dia_semana:  !lodash.isArray(item.list_dia_semana) ? item.list_dia_semana?.split(',') : item.list_dia_semana
        };
    });
    return rows;
}


function* atualizaLista(agendaSetupId, newValues, create, remove){
    let periodosLista  = (yield select((state) => state.periodos.data.asMutable()));
    let periodos  = [...periodosLista] ?? [];

    if(create) {
       periodos.push({...newValues});
    }else if(remove){
        periodos = lodash.filter(periodos, (value) => value.agenda_setup_id !== agendaSetupId);
    }else{
        periodos = lodash.map(periodos, (item, key) => {
            if(item.agenda_setup_id === agendaSetupId){
                return { ...item, ...newValues, id: key }
            }
    
            return {...item, id: key};
        });
    }

    return [...periodos];
}

export function* loadPeriodosRequest({ atracaoId, atracaoItemId}) {
    try {

        let response = yield call(api.get, `agenda/setup?${param({atracao_id: atracaoId, atracao_item_id: atracaoItemId})}`);
        
        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        yield put(PeriodosActions.loadPeriodosSuccess(atracaoId, atracaoItemId, trataDados(response?.data?.data)));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar periodos."
        yield put(PeriodosActions.loadPeriodosFailure(error));
    }
}

function trataHora(hora) {

    let newHora = hora.split(':');

    if(hora){
        if(lodash.size(newHora) > 2){
            return hora;
        }else{
            return hora+":00"
        }
    }

    return false

}

export function* savePeriodoRequest({ atracaoId, atracaoItemId, dados, callback}) {
    try {

        let request = dados?.agenda_setup_id ? api.put : api.post;
        let url     =  dados?.agenda_setup_id ? `agenda/setup/${ dados?.agenda_setup_id}` : `agenda/setup`;

        let newDados = {
            ...dados,
            list_dia_semana: dados?.list_dia_semana.join(","),
            atracao_id: atracaoId,
            atracao_item_id: atracaoItemId,
            hr_agenda_ini: trataHora(dados?.hr_agenda_ini),
            hr_agenda_fim: trataHora(dados?.hr_agenda_fim)
        }

        const response = yield call(request, url, newDados);
        newDados = { ...newDados,  ...response?.data?.data }
        let newLista = yield atualizaLista( dados?.agenda_setup_id, newDados,  !dados?.agenda_setup_id);

        yield put(SuccessActions.setSuccess(true, 'Período salvo com sucesso!'));
        yield put(PeriodosActions.loadPeriodosSuccess(atracaoId, atracaoItemId, trataDados(newLista)));
        yield put(PeriodosActions.savePeriodoSuccess());

        if(callback && typeof callback === 'function'){
            callback();
        }

    } catch (error) {
        error.message =  error.message ?? "Erro ao salvar período."
        yield put(PeriodosActions.savePeriodoFailure(error));
    }
}

export function* removePeriodoRequest({ atracaoId, atracaoItemId, agendaSetupId}) {
    try {

        if(!agendaSetupId){
            throw Error("Periodo não encontrado.");
        }

        let confirm = yield Swal.fire({
            title: "Exclusão de período", 
            text: "Confima a exclusão do período?",
            icon: '',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonText: "SIM",
            reverseButtons: true
        });

        if(confirm.isConfirmed){
            yield call(api.delete, `agenda/setup/${agendaSetupId}`);

            let newLista = yield atualizaLista( agendaSetupId, false, false, true);

            yield put(SuccessActions.setSuccess(true, 'Período removido com sucesso!'));

            yield put(PeriodosActions.loadPeriodosSuccess(atracaoId, atracaoItemId, trataDados(newLista)));
        }

        yield put(PeriodosActions.removePeriodoSuccess());


    } catch (error) {
        error.message =  error.message ?? "Erro ao remover período."
        yield put(PeriodosActions.removePeriodoFailure(error));
    }
}