import { FormControl, InputLabel, MenuItem, Select,Checkbox, ListItemText } from '@material-ui/core';
import React from 'react';
import lodash from 'lodash';
import Input from '@material-ui/core/Input';

SelectSemana.defaultProps = {
    value: []
}


export default function SelectSemana({ label, value, setValue, renderInputProps, InputProps, disabled, error, ...rest}){


    const MenuProps = {
            PaperProps: {
                style: {
                maxHeight: 220,
                width: 150,
            },
        },
    };

    const options = [
        {value:'1', title:'Segunda'},
        {value:'2', title:'Terça'},
        {value:'3', title:'Quarta'},
        {value:'4', title:'Quinta'},
        {value:'5', title:'Sexta'},
        {value:'6', title:'Sábado'},
        {value:'7', title:'Domingo'}
    ]

    return (
        <FormControl required {...rest}>
            <InputLabel id="mutiple-checkbox-label">Dias da Semana</InputLabel>
            <Select
                labelId="mutiple-checkbox-label"
                id="mutiple-checkbox"
                multiple
                value={value}
                onChange={setValue}
                input={<Input />}
                renderValue={(selected) => <> 
                    {selected.map((item) => (
                       options[item === 0 ? item : item-1].title+' '
                    ))}
                </>
                }
                MenuProps={MenuProps}
            >
            {lodash.map(options, (option) => (
                <MenuItem key={option?.value} value={option?.value}>
                    <Checkbox checked={value && value.indexOf(option?.value) > -1} />
                    <ListItemText primary={option?.title} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
    
    
    
}

