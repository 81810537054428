import React, { useCallback, useEffect,useState } from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataGrid from '../../DataGrid';
import { useStyles } from './styles';
import DateRange from '../../DateRange';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AgendaPeriodoActions from '../../../store/ducks/agenda/agendaPeriodo';
import PeriodoItemForm from './PeriodoItemForm';

export default function AgendaPeriodoItens({atracaoId, atracaoItemId, atracaoSetupId, dtAgendaIni, dtAgendaFim}) {

	const classes = useStyles();
	const dispatch = useDispatch();
	const {data, loading, error}  = useSelector(state => state.agendaPeriodo);

	const [editaItem, setEditaItem] = useState(false);
	const [filtro, setFiltro]		= useState({dt_agenda: 'startNow'});

	const carregar = useCallback((propsDataGrid) => {
		dispatch(AgendaPeriodoActions.loadAgendaPeriodoRequest(atracaoId, atracaoItemId, atracaoSetupId, filtro, propsDataGrid ? propsDataGrid?.page : false))
	}, [dispatch,atracaoId,atracaoItemId,atracaoSetupId, filtro]) 

	const handleFiltros = (periodo) => {
		const { startDate, endDate, filter } = periodo; 
		let newFiltro = {};
	
		if(filter === 'start_now'){
		  newFiltro.dt_agenda = 'startNow';
		}else{
		  if(startDate === endDate){
			newFiltro.dt_agenda = moment(startDate).format('YYYY-MM-DD');
		  }else{
			newFiltro.dt_agenda_ini = moment(startDate).format('YYYY-MM-DD');
			newFiltro.dt_agenda_fim =  moment(endDate).format('YYYY-MM-DD');
		  }
		}
	
		setFiltro(newFiltro);
	}

	const onClickItem = (acao, dados) => {
		if(acao === 'editar'){
			setEditaItem(dados);
		}else{
			dispatch(AgendaPeriodoActions.removeAgendaPeriodoRequest(atracaoSetupId,dados?.agenda_id));
		}
	}

	const handleRowActions = (params) => (
		<ButtonGroup variant="text"  size="small" className={classes.group}>
		  <Button className={classes.button} title="Editar" color="secondary" startIcon={<FontAwesomeIcon icon="edit" className={classes.icon} />} onClick={() => onClickItem('editar',params?.row)} >Editar </Button>
		  <Button className={classes.remover}   title="Excluir"  startIcon={<FontAwesomeIcon icon="trash" className={classes.icon} />} onClick={() => onClickItem('excluir',params?.row)} >Remover </Button>
		</ButtonGroup>
	)

	const columns  = [
		{ field: 'dia_semana', headerName: 'Dia', flex: .5},
		{ field: 'dt_agenda_grid', headerName: 'Data', flex: 1},
		{ field: 'hr_agenda', headerName: 'Hora', flex: 1},
		{ field: 'num_pessoa_max', headerName: 'Nº Máximo', flex: .5},
		{ field: 'actions', headerName: ' ', width: 200, renderCell: (params) =>  handleRowActions(params) },
	]

	useEffect(() => {
		carregar(false, false)
	},[carregar, filtro])
	
  	return (
		<div className={classes.rootItens}>
			<Box  minHeight={'70%'}>
				<Box marginBottom={1}>
					<DateRange onChange={handleFiltros} />
				</Box>
				<DataGrid
					reload={carregar}
					error={error}
					loading={loading}
					columns={columns}
					rows={data?.data ?? []}
					rowCount={data?.meta?.total}
					pageSize={10}
					onPageChange={carregar}
					rowHeight={30}
					headerHeight={20}
				/>     
			</Box>
			<PeriodoItemForm open={editaItem !== false} close={() => setEditaItem(false)} agenda={editaItem} />
		</div>
  	);
}