import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Texto from '../Campo/texto';
import AtracaoActions from '../../../store/ducks/atracao/atracao';
import { Box, Button, CircularProgress, InputLabel } from '@material-ui/core';
import { useStyles } from './styles';
import Select from '../Campo/select';
import Loading from '../../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Imagem from '../Campo/imagem';
import Inteiro from '../Campo/inteiro';
import Error from '../../Error';
import UsuariosActions from '../../../store/ducks/usuarios';
import TiposAtracaoActions from '../../../store/ducks/atracao/tipos'
import EditorHTML from '../../EditorHTML'
import ColorPicker from '../Campo/colorPicker';

export default function DadosBasicos({ atracaoId, isFormEdited, setIsFormEdited }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { loading, loadingCreate, error, data } = useSelector(state => state.atracao.itemCrud.dadosBasicos);
    const usuarios = useSelector(state => state.usuario.lista)
    const { lista: tipos } = useSelector(state => state.atracao.tipos);

    const [tiposAtracao, setTiposAtracao] = useState([])

    const { errors, register, control, reset, handleSubmit, formState } = useForm();

    const save = (data) => {
        dispatch(AtracaoActions.saveAtracaoRequest(atracaoId, data));
    }

    const buscaUsuarios = (e) => {
        dispatch(UsuariosActions.loadUsuariosRequest(e?.target?.value));
    }

    useEffect(() => {
        reset({
            ...data,
            expositores: data.expositores?.asMutable()
        });
    }, [reset, data])

    useEffect(() => {
        dispatch(TiposAtracaoActions.loadTiposAtracaoRequest());
        dispatch(UsuariosActions.loadUsuariosRequest());
    }, [dispatch])

    useEffect(() => {
        setTiposAtracao(tipos.map(tipo => {
            return {
                title: tipo.des_atracao_tp,
                value: tipo.atracao_tp_id
            }
        }))
    }, [tipos])

    useEffect(() => {
        setIsFormEdited(formState.isDirty)
    }, [formState, setIsFormEdited]);

    return (<>
        {loading && <Loading loading={loading} size={30} />}
        {!loading && <form className={classes.formulario}>
            <div className={classes.row}>
                <Controller
                    name={`imagem_atracao`}
                    control={control}
                    rules={{ required: false }}
                    render={({ onChange, value }) =>
                        <Imagem
                            name={`imagem_atracao`}
                            url={value ?? data?.arq_imagem_atracao}
                            width={130}
                            height={60}
                            onChange={(file) => onChange(file[0]?.data)}
                            containerStyle={classes.image}
                        />
                    }
                />
                <Texto
                    fullWidth
                    size="small"
                    label="Nome"
                    name={`nom_atracao`}
                    inputRef={register({ required: false })}
                    error={Boolean(errors?.nom_atracao)}
                    helperText={errors?.nom_atracao && "O campo é obrigatório."}
                />
                <Controller
                    name="atracao_tp_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value }) =>
                        <Select
                            style={{ width: 220 }}
                            label={"Tipo"}
                            defaultValue={'OUTRO'}
                            value={!value ? (tiposAtracao.length > 0 ? tiposAtracao[0].value : null) : value}
                            options={tiposAtracao}
                            setValue={onChange}
                            required={true}
                        />
                    }
                />
            </div>

            <div className={classes.row}>
                <Texto
                    fullWidth
                    size="small"
                    label="Latitude"
                    name={`num_latitude_atracao`}
                    inputRef={register({ required: false })}
                    required={false}
                    error={Boolean(errors?.num_latitude_atracao)}
                    helperText={errors?.num_latitude_atracao && "O campo é obrigatório."}
                />
                <Texto
                    fullWidth
                    size="small"
                    label="Longitude"
                    name={`num_longitude_atracao`}
                    inputRef={register({ required: false })}
                    required={false}
                    error={Boolean(errors?.num_latitude_atracao)}
                    helperText={errors?.num_latitude_atracao && "O campo é obrigatório."}
                />
            </div>
            <div className={classes.row}>
                <Texto
                    fullWidth
                    size="small"
                    label="Link Vídeo"
                    name={`des_caminho_video_atracao`}
                    inputRef={register({ required: false })}
                    required={false}
                />
                <Controller
                    name={`num_pessoa_max`}
                    control={control}
                    rules={{ required: false, min: 1, valueAsNumber: true, validate: { positive: value => parseInt(value) > 1 } }}
                    render={({ onChange, value }) =>
                        <Inteiro
                            fullWidth
                            size="small"
                            label="Lotação Máxima"
                            error={Boolean(errors?.num_pessoa_max)}
                            minValue={1}
                            setValue={onChange}
                            value={value}
                            required={false}

                        />
                    }
                />
                <Controller
                    name={`icone_atracao`}
                    control={control}
                    rules={{ required: false }}
                    render={({ onChange, value }) =>
                        <Imagem
                            name={`icone_atracao`}
                            url={value ?? data?.des_icon_atracao}
                            width={80}
                            height={60}
                            onChange={(file) => onChange(file[0]?.data)}
                            containerStyle={classes.icone}
                            buttonLabel={<><FontAwesomeIcon icon="icons" size="2x" /> Selecione um ícone</>}
                        />
                    }
                />
            </div>
            <div className={classes.row}>
                <Controller
                    name={`num_pessoa_max_grupo`}
                    control={control}
                    rules={{ required: false, min: 1, valueAsNumber: true, validate: { positive: value => parseInt(value) > 1 } }}
                    render={({ onChange, value }) =>
                        <Inteiro
                            fullWidth
                            size="small"
                            label="Nº de Pessoas por Grupo"
                            error={Boolean(errors?.num_pessoa_max_grupo)}
                            minValue={1}
                            setValue={onChange}
                            value={value}
                            required={false}
                        />
                    }
                />
                <Controller
                    name={`num_grupo_max`}
                    control={control}
                    rules={{ required: false, min: 1, valueAsNumber: true, validate: { positive: value => parseInt(value) > 1 } }}
                    render={({ onChange, value }) =>
                        <Inteiro
                            fullWidth
                            size="small"
                            label="Nº Máximo de Grupos"
                            error={Boolean(errors?.num_grupo_max)}
                            minValue={1}
                            setValue={onChange}
                            value={value}
                            required={false}
                        />
                    }
                />
            </div>
            <div className={classes.row}>
                <Controller
                    name='des_atracao_html'
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                    render={({ value, onChange }) =>
                        <div style={{ flex: 11, margin: 10 }}>
                            <InputLabel>Descrição:</InputLabel>
                            <EditorHTML
                                value={value}
                                onChange={onChange}
                            />
                        </div>
                    }
                />
                <Controller
                    name='cod_cor_hexa_pin'
                    control={control}
                    rules={{ required: false }}
                    defaultValue={null}
                    render={({ value, onChange }) => {
                        return <div style={{ flex: 1, margin: 10 }}>
                            <InputLabel>Cor do pin:</InputLabel>
                            <ColorPicker
                                value={value}
                                onChange={onChange}
                            />
                        </div>
                    }}
                />
            </div>
            <div className={classes.row}>
                <Controller
                    name="expositores"
                    control={control}
                    rules={{ required: false }}
                    defaultValue={[]}
                    render={({ onChange, value }) => (<>
                        <Select
                            fullWidth
                            component="autocomplete"
                            label="Expositores"
                            disabled={usuarios.error}
                            options={usuarios.data?.data}
                            optionKey={'usuario_id'}
                            optionLabel={'nom_usuario'}
                            value={value}
                            setValue={(e, value) => onChange(value)}
                            loading={usuarios.loading}
                            required={false}
                            multiple={true}
                            getOptionLabel={(option) => option['nom_usuario'] + ' - ' + option['des_email']}
                            renderInputProps={{
                                onChange: (value) => buscaUsuarios(value)
                            }}
                        />
                    </>)
                    }
                />
            </div>
        </form>
        }

        <Box display="flex" justifyContent="flex-end" flexGrow={1} pr={1}>
            <Button onClick={handleSubmit(save)} variant="contained" color="secondary" endIcon={<FontAwesomeIcon icon="chevron-right" />} disabled={loadingCreate || !isFormEdited || loading}>
                {(loadingCreate || loading) && <CircularProgress size={15} />} salvar {!atracaoId && " e continuar"}
            </Button>
        </Box>

        <Error error={error} showAlert={false} alertMessage={error} />

    </>
    );
}