import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    updateAgendamentoStatusRequest: ['agendamentoId', 'status'],
    updateAgendamentoStatusSuccess: null,
    updateAgendamentoStatusFailure: ['error'],

});

export const AgendamentoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    agendamentoId: false,
    loading: false,
    error: false
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_AGENDAMENTO_STATUS_REQUEST]: (state, action)=>
        state.merge({ loading: true, error: false, data:{}, agendamentoId: action.agendamentoId }),
    [Types.UPDATE_AGENDAMENTO_STATUS_SUCCESS]: (state, action) =>
        state.merge({ loading: false, error: false, agendamentoId: false }),
    [Types.UPDATE_AGENDAMENTO_STATUS_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error, agendamentoId: false }),
    
});
