import { combineReducers } from 'redux';
import { reducer as login } from './login';
import { reducer as menu } from './menu';
import { reducer as view } from './view';
import { reducer as tema } from './tema';
import { reducer as experiencia } from './experiencia/experiencias';
import { reducer as atracao } from './atracao/atracoes';
import { reducer as programacao } from './programacao/programacao';
import { reducer as agendamento } from './agendamento/agendamentos';
import { reducer as aglomeracao } from './aglomeracao';
import { reducer as usuario } from './usuarios';
import { reducer as perfil } from './perfil';
import { reducer as acomodacao } from './acomodacoes';
import { reducer as caracterizador } from './caracterizador/caracterizadores';
import { reducer as success } from './success';
import { reducer as periodos } from './agenda/periodos';
import { reducer as agendaPeriodo } from './agenda/agendaPeriodo';
import { reducer as usuarioInstalacao } from './usuarioInstalacao';
import { reducer as comunicacao } from './comunicacao/comunicacoes';

import history from '../../routes/history';
import {connectRouter} from 'connected-react-router';

const appReducer = combineReducers({
    login,
    menu,
    view,
    tema,
    experiencia,
    atracao,
    programacao,
    agendamento,
    aglomeracao,
    usuario,
    perfil,
    acomodacao,
    caracterizador,
    periodos,
    agendaPeriodo,
    success,
    usuarioInstalacao,
    comunicacao,
    router: connectRouter(history),
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_SUCCESS') {
    state = {
      tema: state.tema
    };
  }

  return appReducer(state, action);
};
export default rootReducer;