import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';

export default function MoreOptions({title, iconButton,itensMenu, menuProps, itemProps, iconProps, buttonProps, id }) {
  const [menu, setMenu]   = useState(null);
    return (
      <>
        <Tooltip title={title ?? buttonProps?.title}> 
          <IconButton
              id={id}
              onClick={(event) => { event.preventDefault(); setMenu(event.currentTarget);} }
              {...buttonProps}
              title={undefined}
          >
              {iconButton ?? <FontAwesomeIcon icon={faEllipsisV} {...iconProps}/>}
          </IconButton> 
        </Tooltip>
       
        <Menu
          anchorEl={menu}
          keepMounted
          open={Boolean(menu)}
          onClose={() => setMenu(null)}
          {...menuProps}
        > 
          { lodash.map(itensMenu, (item, key) =>{
               return <span>
                {item && 
                  <MenuItem key={key} onClick={() => {item.onClick(); setMenu(null); }} {...itemProps} {...item?.props}>
                    {item?.icon && <FontAwesomeIcon icon={item?.icon} size={"sm"} style={{marginRight: 5 }}/> } {item?.title}
                  </MenuItem>
                }
                </span>
                
          })
          }
        </Menu>
      </>
    );
}
