import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
    root:{ 
      height:"100vh", 
      display: 'flex',
      justifyContent: 'center',
      alignItems:'center',
      flexDirection:'column',
      // background: '#ff9933'
    },
    paper:{
      // width: '100%',
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      minHeight: '50vh',

      [theme.breakpoints.down('xs')]: {
        height: '100vh',
        justifyContent: 'flex-start',

      },
    },
    blocoLogo:{
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    
    logoLogin:{
      width: '50%',
      height: 'auto',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    appBarSpacer: {
      marginTop: theme.spacing(2),
    },
    title:{
      color: theme.palette.text.secondary,
      textAlign: 'center'
    },
    
    button:{
      width: '60%',
      margin: theme.spacing(1)
    }
  }));