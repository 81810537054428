import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '../DialogTitle';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';


function DialogMeet({open, close, title, content, actions,contentProps, ...props}) {
	const classes     = useStyles();
	const theme 	  = useTheme();
	const fullScreen  = useMediaQuery(theme.breakpoints.down('sm'));

	return (	
		<Dialog
			open={open}
			onClose={close}
			fullScreen={fullScreen}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			maxWidth="lg"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			{...props}
		>
			
			{title &&<DialogTitle id="alert-dialog-title" onClose={close} classes={classes} >
				 <div className={classes.areaTitulo}>{title}</div>
			</DialogTitle>}
			
			<DialogContent dividers classes={{root: classes.root}} {...contentProps} >
				<div className={classes.areaConteudo}  {...contentProps}  id="alert-dialog-description">{content}</div>
			</DialogContent>
			<DialogActions {...props.actionsProps}>
				{actions}
			</DialogActions>
		</Dialog>	
	)

}

export default  (DialogMeet);
