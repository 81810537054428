import { call, put } from 'redux-saga/effects';
import MenuActions from '../ducks/menu';
import loginActions from '../ducks/login';
import swal from '@sweetalert/with-react';
import api from '../../config/axios';
import history from '../../routes/history';
import lodash from 'lodash';


export function* loadMenuRequest(action) {
    try {

        let request  =  yield call(api.get,`menu`);

        if(lodash.size(request?.data?.data) > 0) {
            yield put(MenuActions.setMenuSelecionadoRequest(request?.data?.data[0]));
        }
        if(lodash.size(request?.data?.data) === 0){
            swal('Erro','Faça login pelo aplicativo. \n Login para web ainda não está permitido para o seu perfil', 'error');
            yield put(loginActions.logoutRequest());
        }
        
        yield put(MenuActions.loadMenuSuccess(request?.data?.data));
    } catch (error) {
        swal('Erro','Erro ao carregar itens de menu, atualize a página.', 'error');
        yield put(MenuActions.loadMenuFailure(error));
    }
}
export function* setMenuSelecionadoRequest(action){
    try {

        const menuSelecionado = action.menuSelecionado;
        
        history.push(menuSelecionado?.des_link);
        yield put(MenuActions.setMenuSelecionadoSuccess(menuSelecionado));
        

    } catch (error) {
        swal('Erro','Selecionar menu, atualize a página', 'error');
    }
}