import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loginRequest: [ 'username', 'password'],
    loginSuccess: ['data'],
    loginFailure: ['error'],

    logoutRequest: ['ignoreCodEvento'],
    logoutSuccess: null,
    logoutFailure: ['error'],

    loggedRequest: null,
    loggedSuccess: ['data','logged'],
    validateTokenRequest: null,
    loggedFailure: ['error'],

    validaTokenSenhaRequest: ['tokenSenha', 'instalacao'],
    validaTokenSenhaSuccess: ['data'],
    validaTokenSenhaFailure: ['error'],

    recuperaSenhaRequest: ['dadosUrl', 'dados', 'callBackApp'],
    recuperaSenhaSuccess: ['data'],
    recuperaSenhaFailure: ['error'],

    esqueciSenhaRequest: ['email'],
    esqueciSenhaSuccess: ['email'],
    esqueciSenhaFailure: ['error'],

});

export const LoginTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    loadingLogged:0, //comeca com 0 para diferenciar o 0 de true e false, se tiver 0 quer dizer que nao carregou nenhuma vez ainda
    error: false,
    logged:false,
    usuarioToken: false,
    errorUsuario: false,
    loadingAlteraSenha:false,
    errorAlteraSenha:false,
    emailRecuperacao: false
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOGIN_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOGIN_SUCCESS]: (state, action) =>
        state.merge({ data: action.data,  logged:true, loading: false, error: false }),
    [Types.LOGIN_FAILURE]: (state, action) =>
        state.merge({ loading: false, logged:false, error: action.error }),
    [Types.LOGOUT_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOGOUT_SUCCESS]: state =>
        state.merge({ loading: false, logged:false, error: false , data:{} }),
    [Types.LOGOUT_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error  }),
   
    
    [Types.LOGGED_REQUEST]: state =>
        state.merge({senhaAlterada:false,loading: false, loadingLogged: true, error: false}),
    [Types.LOGGED_SUCCESS]: (state, action) =>
        state.merge({logged:action.logged, data:action.data, loadingLogged:false, error: false}),
    [Types.LOGGED_FAILURE]: (state, action) =>
        state.merge({loadingLogged:false }),
    
    [Types.VALIDATE_TOKEN_REQUEST]: (state, action) =>
        state.merge({ loading: false, error: action.error  }),
        
    [Types.RECUPERA_SENHA_REQUEST]: state =>
        state.merge({ loadingAlteraSenha: true, errorAlteraSenha: false, errorUsuario: false}),
    [Types.RECUPERA_SENHA_SUCCESS]: (state, action) =>
        state.merge({usuarioToken: action.data,loadingAlteraSenha: false, errorAlteraSenha: false }),
    [Types.RECUPERA_SENHA_FAILURE]: (state, action) =>
        state.merge({ loadingAlteraSenha: false, errorAlteraSenha: action.error, errorUsuario: false }),
    
    [Types.VALIDA_TOKEN_SENHA_REQUEST]: state =>
        state.merge({ loading: true, errorUsuario: false}),
    [Types.VALIDA_TOKEN_SENHA_SUCCESS]: (state, action) =>
        state.merge({usuarioToken: action.data,loading: false, errorUsuario: false }),
    [Types.VALIDA_TOKEN_SENHA_FAILURE]: (state, action) =>
        state.merge({ loading: false, errorUsuario: action.error, usuarioToken: false }),
    

    [Types.ESQUECI_SENHA_REQUEST]: state =>
     state.merge({ loading: true, errorUsuario: false}),
    [Types.ESQUECI_SENHA_SUCCESS]: (state, action) =>
        state.merge({emailRecuperacao: action.email,loading: false, errorUsuario: false }),
    [Types.ESQUECI_SENHA_FAILURE]: (state, action) =>
        state.merge({ loading: false, errorUsuario: action.error }),

});
