import React, { useCallback, useEffect } from 'react';
import withWidth from '@material-ui/core/withWidth';
import Page from '../../components/Page';
import { useDispatch } from 'react-redux';
import ProgramacaoItemActions from '../../store/ducks/programacao/programacaoItem';
import history from '../../routes/history';
import ProgramacaoItemForm from '../../components/Form/ProgramacaoItemForm';

function ProgramacaoItem() {

  const dispatch    = useDispatch();

  const programacaoItem = history.location.state;

  let title = programacaoItem?.nome ? `Edição - ${programacaoItem?.nome}` : "Nova Programação";

  const carregar = useCallback((atracaoId, atracaoItemId) => {
    dispatch(ProgramacaoItemActions.loadProgramacaoItemRequest(atracaoId, atracaoItemId));
  }, [dispatch]);

  
  useEffect(() => {
    carregar(programacaoItem?.atracaoId, programacaoItem?.atracaoItemId);   
  }, [carregar, programacaoItem]);

  return (
    <>
      <Page title={title} conteudoDireita={false} atualizar={false} loading={false} botaoVoltar>
        <ProgramacaoItemForm  atracaoId={programacaoItem?.atracaoId} atracaoItemId={programacaoItem?.atracaoItemId}  openTab={programacaoItem?.openTab} />
      </Page>
    </>
  );
}

export default  withWidth()(ProgramacaoItem);
