import { combineReducers } from 'redux';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { reducerComunicacao } from './comunicacao';

const { Types, Creators } = createActions({
    loadComunicacoesRequest: ['filtros', 'ordenacao', 'page'],
    loadComunicacoesSuccess: ['lista', 'meta'],
    loadComunicacoesFailure: ['error'],

    clearComunicacoesRequest: null,
});

export const ComunicacoesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    lista: [],
    meta: {},
    optionsCodDestino: [
        { title: 'Página', value: 'PAGINA' },
        { title: 'Link', value: 'LINK' },
        // { title: 'Atração', value: 'ATRACAO' },
        // { title: 'Programação', value: 'ITEM' },
    ],
    optionsCodTipo: [
        { title: 'Banner', value: 'BANNER' }
    ],
    loading: false,
    error: false
});

const reducerComunicacoes = createReducer(INITIAL_STATE, {
    [Types.LOAD_COMUNICACOES_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOAD_COMUNICACOES_SUCCESS]: (state, action) =>
        state.merge({ lista: action.lista, meta: action.meta, loading: false, error: false }),
    [Types.LOAD_COMUNICACOES_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),

    [Types.CLEAR_COMUNICACOES_REQUEST]: state =>
        state.merge({ lista: [], meta: {}, loading: true, error: false }),
});


export const reducer = combineReducers({lista: reducerComunicacoes, itemCrud: reducerComunicacao});
