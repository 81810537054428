import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadTiposAtracaoRequest: [],
    loadTiposAtracaoSuccess: ['lista'],
    loadTiposAtracaoFailure: ['error'],
});

export const TiposAtracaoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    lista: [],
    loading: false,
    error: false
});

export const reducerTiposAtracao = createReducer(INITIAL_STATE, {
    [Types.LOAD_TIPOS_ATRACAO_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_TIPOS_ATRACAO_SUCCESS]: (state, action) =>
        state.merge({ lista: action.lista, loading: false, error: false }),
    [Types.LOAD_TIPOS_ATRACAO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
});
