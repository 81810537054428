import React from 'react';
import './App.css';

import Routes from './routes/routes';
import { Provider } from 'react-redux';
import store from './store';
import ReactGA from 'react-ga';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

ReactGA.initialize('UA-90663511-5');
library.add( fal , fas);

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
