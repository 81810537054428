import React from 'react';
import { Typography } from '@material-ui/core';

import MoreOptions from '../../MoreOptions';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useTheme} from '@material-ui/core/styles';
import {useStyles} from './styles';


export default function IndSituacao({key, indSituacao, buttonProps, onSelect, disabled}){
    
    const styles = useStyles();
    let theme         = useTheme();

    return <MoreOptions 
                key={key}
                iconButton={
                    <> 
                        {indSituacao === 'A' && 
                            <Typography variant="subtitle2">
                                <FontAwesomeIcon icon={faCheck} color={theme.palette.success.main} /> Ativo 
                            </Typography>
                        }
                        {indSituacao === 'I' &&  
                            <Typography variant="subtitle2">
                                <FontAwesomeIcon icon={faBan} color={theme.palette.error.main} /> Inativo 
                            </Typography>
                        }
                    </>
                }
                buttonProps={{classes: {root: styles.status}, title: "Alterar Situação", disabled: disabled , ...buttonProps}}
                itensMenu={[
                        indSituacao !== 'A' ? { key:"ativar", title: "Ativar", onClick: () => onSelect('A') } : false, 
                        indSituacao !== 'I' ? { key:"inativar", title: "Inativar", onClick:  () => onSelect('I')} : false
                    ]
                }
        />
}