import React,  { useState } from 'react';
import {useTheme} from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import { faEye, faEyeSlash, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';


function Password({label, onPressEnter, showRegraSenha, senhaFixa, error, errorMessage, ...rest}) {

  const [showPassword, setShowPassword] = useState(senhaFixa === true);
  const [regraSenha, setRegraSenha]     = useState(false);

  let tema         = useSelector(state=>state.tema.data);
  let theme        = useTheme();

  return (
    
        <TextField variant="filled" margin="normal"
            required fullWidth name="password"
            label={label}
            type={showPassword ? "text" :"password"}
            id="password"
            onKeyPress={(ev) => {
                if(onPressEnter){
                    if (ev.key === 'Enter') {
                        onPressEnter(ev);
                    }
                }
            }}
            onFocus={() => {
                if(showRegraSenha === true){
                    setRegraSenha(true);
                }
            }}
            onBlur={() => setRegraSenha(false)}
            autoComplete="current-password"
            InputProps={{
                endAdornment:(
                    <InputAdornment position="end" >
                        {!senhaFixa && <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                            onKeyPress={(ev) => {
                                if(onPressEnter){
                                    if (ev.key === 'Enter') {
                                        ev.stopPropagation()
                                    }
                                }
                            }}
                            aria-label={ showPassword ? "Senha aberta" : "Senha fechada"}
                        >
                            <FontAwesomeIcon icon={ showPassword ? faEyeSlash : faEye} size="sm"/>
                        </IconButton>}
                        {senhaFixa && <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.text.primary} size="1x" title="Guarde essa senha. Ela é fixa para esse evento."/>}
                    </InputAdornment>
                )
            }}
            FormHelperTextProps={{style: {margin: 0, padding: 0}}}
            helperText={ 
                <> 
                    { showRegraSenha && regraSenha && tema?.regraSenha && 
                        <Typography variant={"caption"} color="textSecondary">
                            <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.text.secondary} size="1x" /> {tema?.regraSenha} 
                        </Typography>
                    } 
                    {error && errorMessage}
                </>
            }
            error={error}
            {...rest}
        />
  );
}
export default (Password);