import React, {useEffect}  from 'react';
import { useSelector } from 'react-redux';

import Body from '../../Body';
import { ThemeProvider } from '@material-ui/core/styles';
import {useStyles} from './styles';
import createTheme, {defaultTheme} from '../../../Theme';

import CircularProgress from '@material-ui/core/CircularProgress';

export default function LayoutTema(props) {

  let tema          = useSelector(state=>state.tema);
  let Theme         = createTheme(defaultTheme);
  
  let styles        = useStyles();
  let tela          = props.tela;
  if(!props.tela){
    tela            = "externa";
  }
  let themeBody = {};
  if(tema.data.des_arq_esquema_cor_web){
    let themeJSON = JSON.parse(tema.data.des_arq_esquema_cor_web);
    let temaTela  = themeJSON;
    if(themeJSON.body){
      themeBody = themeJSON.body;
    }

    if(themeJSON[tela]){
      themeBody = {...themeBody, ...themeJSON[tela].body};
      temaTela  = {...temaTela, ...themeJSON[tela] }
    }

    Theme    = createTheme(temaTela);
  }

  useEffect(()=>{
    if(tema.data.des_arq_favicon){
      document.getElementById('favicon').href       = tema.data.des_arq_favicon;
      document.getElementById('favicon_apple').href = tema.data.des_arq_favicon;
    
    }
  }, [tema.data.des_arq_favicon])

  return (<>
    {!tema.loading  && 
      <Body style={themeBody}>
        <ThemeProvider theme={Theme} >
          {props.children}
        </ThemeProvider>
      </Body>
    }
    {tema.loading && 
      <div align="center" className={styles.centerLoading}><CircularProgress color="primary" /></div>
    }
  </>
  );
}