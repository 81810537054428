import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	selecionado:{
		background: theme.palette.grey[400],
		flex: 1,
		'&:hover':{
			background: theme.palette.grey[400]
		}
	},
	button: {
		flex: 1,
		'&:hover':{
			background: theme.palette.grey[200]
		}
	},
	titulo:{
		marginBottom: theme.spacing(1)
	}
}));