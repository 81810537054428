import React,  { useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useStyles } from './styles';
import { Box, Container, Button, TextField, Typography,  CircularProgress, Paper, Link } from '@material-ui/core';
import lodash from 'lodash';
import LoginActions from '../../store/ducks/login';
import Error from '../../components/Error';
import Copyright from '../../components/Copyright';
import imagemLogo from '../../imagens/logo_going_padrao.jpg';

function EsqueciSenha(props) {
  const classes     = useStyles();
  const dispatch    = useDispatch();

  const [email, setEmail] = useState("");

  let loading           = useSelector(state=>state.login.loading);
  let error             = useSelector(state=>state.login.errorUsuario);
  let emailRecuperacao  = useSelector(state=>state.login.emailRecuperacao);
  let tema              = useSelector(state=>state.tema.data);

  let logo  = tema?.arq_logo_instalacao ?? imagemLogo;

  let disabledEnviar = lodash.size(email) <= 0;

  const enviaEmail = () => {
    if(!disabledEnviar){
      dispatch(LoginActions.esqueciSenhaRequest(email));
    }
  }

  const clearEmail = () => {
    if(emailRecuperacao){
      dispatch(LoginActions.clearEmailRecuperacao());
    }
  }

  return (

    <div className={classes.root}>
    <Container  maxWidth="sm" className={classes.container} >
      <Paper  elevation={3} variant="outlined" className={classes.paper} >
        <div  className={`${classes.bloco} ${classes.blocoLogo}`} > 
          {logo && <img src={logo} className={classes.logoLogin} alt={'Logomarca'} />}
        </div>
        <div className={classes.divider}></div>
        <div className={`${classes.bloco} ${classes.blocoForm}`}>
          {!emailRecuperacao && <Box marginTop={3} >
              <Typography  variant="subtitle1"  aria-label="Esqueceu sua senha? Não se preocupe! "> Esqueceu sua senha? Não se preocupe! </Typography>
              <Typography variant="subtitle2" > Informe o e-mail que você utilizou para realizar o seu cadastro. </Typography>
              <TextField 
                className={classes.formControl}
                onChange={(e) => setEmail(e.target.value)}
                variant="filled" 
                value={email}
                label={'E-mail'} 
                placeholder={`Digite seu(a) e-mail...`}
                fullWidth 
                required 
                disabled={loading}
              />
            </Box>
          } 
          {emailRecuperacao && 
            <Box margin={1}>
              <Typography  variant="h6" > Enviamos um e-mail de redefinição. </Typography>
              <br/>
              <Typography variant="subtitle1" style={{wordBreak: 'break-word'}}> Caso seu endereço de e-mail esteja no nosso banco de dados, você receberá um e-mail de redefinição em <strong>{emailRecuperacao}</strong>. Clique no link de redefinição de senha para cadastrar uma nova senha.</Typography>
              <br/>
              <Typography variant="caption">Não recebeu ainda? Verifique a pasta de lixo e spam ou <Link onClick={() => clearEmail()} color="secondary" variant="subtitle2" >tente novamente</Link>.</Typography>
            </ Box>
          } 
          <Error error={error} alertMessage={"Erro ao enviar email. Tente Novamente!"} showAlert={true}  /> 

          <Box display={"flex"} flexDirection="row" justifyContent="space-between"  alignItems="baseline" margin={1}>
              <Link href={`/login`} color="secondary" variant="body2" > Voltar ao Login </Link>
            {!emailRecuperacao && <Button variant="contained"  color="primary" disabled={disabledEnviar || loading} onClick={() => enviaEmail()}>  {loading && <CircularProgress size={15}  /> }  Enviar</Button> }
          </Box>
        </div>

      </Paper>
      
    </Container>
    <Box  display={{ xs: 'none', sm: 'block', md: 'block', lg: 'block' }} mt={8}>
      <Copyright />
    </Box>      

  </div>





    // <div  className={classes.root}>
    //   <Container maxWidth="sm" className={classes.container}>
    //     <Paper  elevation={3} variant="outlined" className={classes.paper} >
    //       <div className={classes.blocoLogo}>
    //           {logo && <img src={logo} className={classes.logo} alt="Logo Costa do Sauípe"  />}
    //       </div>
        
    //       <Box display="flex" flexDirection="column" justifyContent="flex-start" marginTop={6} minHeight="70vh">
    //         <div>
    //           <Typography variant="h6" className={classes.title} color="primary" aria-label= {!emailRecuperacao ? "Esqueci Minha Senha" : "Redefinir Sua Senha"} > {!emailRecuperacao ? "Esqueci Minha Senha" : "Redefinir Sua Senha"} </Typography>
    //         </div>
          
    //           {!emailRecuperacao && <>
    //               <Typography  variant="h6"  component="strong" aria-label="Esqueceu sua senha? Não se preocupe! "> Esqueceu sua senha? Não se preocupe! </Typography>
    //               <Typography variant="subtitle2"> Informe o e-mail que você utilizou para realizar o seu cadastro. </Typography>
    //               <TextField 
    //                 className={classes.formControl}
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 variant="filled" 
    //                 value={email}
    //                 label={'E-mail'} 
    //                 placeholder={`Digite seu(a) e-mail...`}
    //                 fullWidth 
    //                 required 
    //                 disabled={loading}
    //               />
    //             </>
    //           } 
    //           {emailRecuperacao && 
    //             <Box margin={1}>
    //               <Typography  variant="h6" > Enviamos um e-mail de redefinição. </Typography>
    //               <br/>
    //               <Typography variant="subtitle1" style={{wordBreak: 'break-word'}}> Caso seu endereço de e-mail esteja no nosso banco de dados, você receberá um e-mail de redefinição em <strong>{emailRecuperacao}</strong>. Clique no link de redefinição de senha para cadastrar uma nova senha.</Typography>
    //               <br/>
    //               <Typography variant="caption">Não recebeu ainda? Verifique a pasta de lixo e spam ou <Link onClick={() => clearEmail()} color="secondary" variant="subtitle2" >tente novamente</Link>.</Typography>
    //             </ Box>
    //           } 
    //           <Error error={error} alertMessage={"Erro ao enviar email. Tente Novamente!"} showAlert={true}  /> 

    //         <Box display={"flex"} flexDirection="row" justifyContent="space-between"  alignItems="baseline" margin={1}>
    //             <Link href={`/login`} color="secondary" variant="body2" > Voltar ao Login </Link>
    //           {!emailRecuperacao && <Button variant="contained"  color="primary" disabled={disabledEnviar || loading} onClick={enviaEmail}>  {loading && <CircularProgress size={15}  /> }  Enviar</Button> }
    //         </Box>
    //       </Box>
    //     </Paper>
    //   </Container>
    // </div>
  );
}
export default (EsqueciSenha);