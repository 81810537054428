import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useStyles } from './styles';
import { Avatar, Box, Link } from '@material-ui/core';
import MenuList from '../Menu/MenuList';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import loginActions from '../../store/ducks/login';
import UsuarioInstalacaoActions from '../../store/ducks/usuarioInstalacao';
import MenuHeader from '../Menu/MenuHeader';
import lodash from 'lodash'

function Header({menu, onClickMenu, menuSelecionado}) {

    const classes               = useStyles();
    const dispatch		        = useDispatch();
    const user                  = useSelector(state=>state.login.data);
    const tema                  = useSelector(state=>state.tema.data);
    const { lista: instalacoes } = useSelector(state=>state.usuarioInstalacao)

    const onClickSair = () => {
        Swal.fire({
            title: "Sair do sistema",
            text: "Deseja sair do sistema?",
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
            reverseButtons: true
        }).then((value) => {
            if(value.isConfirmed){
                dispatch(loginActions.logoutRequest());
            }
        });
    }

    const onClickAlternarInstalacao = () => {
        Swal.fire({
            title: 'Alternar instalação',
            input: 'select',
            inputOptions: instalacoes,
            inputAttributes: {
                autocapitalize: 'off'
            },
            inputValue: tema.instalacao_id,
            showCancelButton: true,
            confirmButtonText: 'Alternar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (instalacaoId) => {
                dispatch(UsuarioInstalacaoActions.updateInstalacaoUsuarioRequest(instalacaoId));
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Alternando instalação...',
                    timer: 10000,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading()
                    }
                  })
            }
        })
    }

    let logo = tema?.arq_logo_instalacao;

    useEffect(() => {
        dispatch(UsuarioInstalacaoActions.loadInstalacoesUsuarioRequest())
    }, [dispatch])

    return (<>
      <AppBar>
        <Toolbar className={classes.toolbar} disableGutters> 
            <Box marginLeft={1} marginRight={2}>
                {logo && <Link to={"/home"} aria-label="Redirecionar para a tela inicial" tabIndex="0">
                    <Box>
                        <img  alt={"Logomarca"} src={logo} className={classes.logo}/>
                    </Box>
                </Link>}
            </Box>
            <Box className={classes.barraMenu}>
                <MenuHeader 
                    menuLista={menu}
                    onClick={onClickMenu}
                    menuSelecionado={menuSelecionado}
                />
            </Box>
            <Box>
                <MenuList
                    id={"menu-perfil"}
                    ariaLabel="Perfil e Sair"
                    menuItens={
                        [
                            {
                                show: lodash.size(instalacoes) > 1,
                                children: 'Alternar instalação',
                                onClick: () => onClickAlternarInstalacao(),
                                'aria-label': 'Alternar instalação',
                                style: { width: '100%' }
                            },
                            {
                                show: true,
                                children: " Sair  ",
                                onClick:()=>onClickSair(),
                                'aria-label':"Fazer logout",
                                style:{ width: '100%'}
                            }
                        ]
                    }   
                >
                    <Avatar 
                        alt={user.nom_usuario}
                        src={user.arq_imagem_usuario}
                        className={classes.avatar}
                        aria-label="Perfil"
                    />
                </MenuList>
            </Box>
          
            </Toolbar>
        </AppBar>
    </>
  );
}
  export default Header;