import { call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import ComunicacoesActions from '../../ducks/comunicacao/comunicacoes';
import lodash from 'lodash';
import {param} from 'jquery';

function trataRowsGrid(dados){
    
    let rows = lodash.map(dados, (item, key) => {
        return {
            ...item,
            id: key, 
            actions: ' '
        };
    });
    return rows;
}

export function* loadComunicacoesRequest({ filtros, ordenacao, page }) {
    try {

        let pagina = page ?? 1;
        let response = yield call(api.get, `comunicacao?${param(filtros)}&${param(ordenacao)}&page=${pagina}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        let data = yield trataRowsGrid(response.data?.data);
        response.data.data = data;
        yield put(ComunicacoesActions.loadComunicacoesSuccess(response.data.data, response.data.meta));

    } catch (error) {
        error.message = "Erro ao carregar comunicações."
        yield put(ComunicacoesActions.loadComunicacoesFailure(error));
    }
}
