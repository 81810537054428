import lodash from 'lodash';

export function  errorDescription(error){
    let msgDescrition = '';
    let quebra = '';

    if(error?.errors){
        lodash.map(error?.errors, function(item){
            lodash.map(item, function(erro){
                msgDescrition += quebra + erro;
                quebra = '\n';
            });
        });

        return msgDescrition;        
    }

    if(error?.message){
        return error?.message;        
    }

    return "Erro";
     
}  