import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    
    root:{
      display: 'flex',
      height: 'calc(100vh - 200px)'
    },
    tabs:{
      borderRight: '1px #ccc solid',
      minWidth: '15%',
      // backgroundColor: theme.palette.grey[100]
    },
    tabNovo:{
      color: theme.palette.success.main
    },
    rootItens:{
      minHeight: '100%'    
    },
    button:{
      fontSize: 10
    },
    icon:{
      width:'15px !important',
      height:'12px !important'

    },
    remover:{
      fontSize: 10,
      color: theme.palette.error.main
    },
    formulario:{
      minHeight: 'calc(100vh - 390px)'

      // padding: theme.spacing(2)
    },
    row:{
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(.5),
     
      '& .MuiFormControl-root':{
        margin: theme.spacing(.5),
        alignSelf: 'flex-end'
      },
      '& .MuiAutocomplete-root':{
        alignSelf: 'flex-end', marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
       
      },
    },
    cancel:{
      backgroundColor: theme.palette.error.main,
      color: theme.palette.background.paper,
      marginRight: 5
    }
}));
