import { call, put } from 'redux-saga/effects';
import api from '../../config/axios';
import UsuariosActions from '../ducks/usuarios';
import {param} from 'jquery';

export function* loadUsuariosRequest({busca}) {
    try {
        let request =  `usuario` ;
        
        let filtro  = {};

        if(busca){
            filtro = {busca_texto: busca}
        }

        let response = yield call(api.get, `${request}?${param(filtro)}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        yield put(UsuariosActions.loadUsuariosSuccess(response.data));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar usuários."
        yield put(UsuariosActions.loadUsuariosFailure(error));
    }
}
