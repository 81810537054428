import {call, put, select} from 'redux-saga/effects';
import AgendamentoActions from '../../ducks/agendamento/agendamento';
import AgendamentosActions from '../../ducks/agendamento/agendamentos';
import lodash from 'lodash';
import Swal from 'sweetalert2';
import api from '../../../config/axios';
import SuccessActions from '../../ducks/success';

function* atualizaLista(agendamentoId, newValues, create){
    let agendamentosLista  = (yield select((state) => state.agendamento.lista.data.asMutable()));
    let agendamentos  = [...agendamentosLista?.data] ?? [];

    if(create) {
       agendamentos.push({...newValues});
    }

    agendamentos = lodash.map(agendamentos, (item, key) => {
        if(item.agenda_exp_usu_id === agendamentoId){
            return { ...item, ...newValues, id: key }
        }
        return {...item, id: key};
    });


    return {...agendamentosLista, data: agendamentos};
}

export function* updateAgendamentoStatusRequest({ agendamentoId, status}) {
    try {

        if(!agendamentoId){
          throw new Error("Agendamento inválido.")
        }

        let confirm = yield Swal.fire({
            title: "Alteração de Status", 
            text: "Confima alteração de status?",
            icon: '',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonText: "SIM",
            reverseButtons: true
        });

        if(confirm.isConfirmed){
            yield call(api.put, `agendamento/${agendamentoId}/status/${status}`);
            let newLista = yield atualizaLista(agendamentoId, {cod_status: status});
            yield put(AgendamentosActions.loadAgendamentosSuccess(newLista));
        }

        yield put(SuccessActions.setSuccess(true, 'Agendamento salvo com sucesso!'));
        yield put(AgendamentoActions.updateAgendamentoStatusSuccess());

    } catch (error) {
        error.message =  error.message ?? "Erro ao atualizar status do agendamento."
        yield put(AgendamentoActions.updateAgendamentoStatusFailure(error));
    }
}

