import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  status:{
      padding: theme.spacing(.3),
      borderRadius: theme.spacing(.5),
      backgroundColor: theme.palette.grey[100],
      borderStyle: 'solid',
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
      marginBottom: theme.spacing(.5),
  }
}));