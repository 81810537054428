import React from 'react';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

export default function TabPanel(props) {
    const { children, value, index, padding, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{width: '100%'}}
        {...other}
      >
        {value === index && (
          <Box p={padding ?? 2} display="flex" flexDirection="column" flexGrow={1} height="100%">
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    padding: PropTypes.number
  };
  