import { TextField } from '@material-ui/core';
import React from 'react';



export default function Inteiro({label, value, setValue, minValue, maxValue, ...rest}){
    const onChangeValue = (e) => {
        if(minValue){
            if(e.target?.value >= minValue || !e.target.value){
                setValue(e)
            }
        }else{
            if(setValue)
                 setValue(e)
        }

    }

    return  <TextField 
                label={label} 
                value={value ?? false} 
                onChange={(e) =>  onChangeValue(e)} 
                variant="filled" 
                required 
                fullWidth 
                type="number"
                autoComplete="off"
                {...rest}
            />
}