import { call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import AtracaoCaracterizadoresActions from '../../ducks/atracao/caracterizadores';
import lodash from 'lodash';
import SuccessActions from '../../ducks/success';

export function* loadAtracaoCaracterizadoresRequest({ atracaoId}) {
    try {

        let dados = {};

        if(atracaoId){
            let response = yield call(api.get, `atracao/${atracaoId}/caracterizador`);
            dados = response.data?.data;
            if(response.status !== 200 && response.status !== 201){
                throw response;
            }
        }
        
        yield put(AtracaoCaracterizadoresActions.loadAtracaoCaracterizadoresSuccess(dados));

    } catch (error) {
        error.message = error.message ?? "Erro ao carregar caracterizadores da atração."
        yield put(AtracaoCaracterizadoresActions.loadAtracaoCaracterizadoresFailure(error));
    }
}


export function* saveAtracaoCaracterizadoresRequest({ atracaoId, dados, callback}) {
    try {

        if(!atracaoId){
            throw Error("Os dados estão incompletos");
        }

        let dadosEnvio = lodash.map(dados?.caracterizadores, (value, key) => {
            return  {
                ...value,
                caracterizador_setup_id: value?.setup?.caracterizador_setup_id
            }
        });

        yield call(api.post, `atracao/${atracaoId}/caracterizador`, {data: dadosEnvio});

        if(callback && typeof callback === 'function'){
            callback()
        }

        yield put(SuccessActions.setSuccess(true, 'Caracterizações salvas com sucesso!'));
        yield put(AtracaoCaracterizadoresActions.saveAtracaoCaracterizadoresSuccess(dados?.caracterizadores));

    } catch (error) {
        error.message = error.message ?? "Erro ao salvar  os caracterizadores da atração."
        yield put(AtracaoCaracterizadoresActions.saveAtracaoCaracterizadoresFailure(error));
    }
}

