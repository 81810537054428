import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    loadAgendaPeriodoRequest: [ 'atracaoId','atracaoItemId', 'agendaSetupId', 'filtros', 'page'],
    loadAgendaPeriodoSuccess: ['agendaSetupId','data', 'page'],
    loadAgendaPeriodoFailure: ['error'],

    saveAgendaPeriodoRequest: [ 'agendaSetupId','agendaId','dados','callback'],
    saveAgendaPeriodoSuccess: ['agendaId'],
    saveAgendaPeriodoFailure: ['error'],

    removeAgendaPeriodoRequest: [ 'agendaSetupId','agendaId'],
    removeAgendaPeriodoSuccess: ['agendaId'],
    removeAgendaPeriodoFailure: ['error'],

    clearAgendaPeriodoRequest: null
});

export const AgendaPeriodoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: [],
    agendaSetupId: false,
    loading: false,
    loadingCreate: false,
    error: false,
    page: 1
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOAD_AGENDA_PERIODO_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:[] }),
    [Types.LOAD_AGENDA_PERIODO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, agendaSetupId: action.agendaSetupId, loading: false, error: false, page: action.page }),
    [Types.LOAD_AGENDA_PERIODO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
    
    [Types.SAVE_AGENDA_PERIODO_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false}),
    [Types.SAVE_AGENDA_PERIODO_SUCCESS]: (state, action) =>
        state.merge({ agendaId: action.agendaId, loadingCreate: false, error: false }),
    [Types.SAVE_AGENDA_PERIODO_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),
    
    [Types.REMOVE_AGENDA_PERIODO_REQUEST]: state =>
        state.merge({ loadingCreate: true, error: false}),
    [Types.REMOVE_AGENDA_PERIODO_SUCCESS]: (state, action) =>
        state.merge({ agendaId: action.agendaId, loadingCreate: false, error: false }),
    [Types.REMOVE_AGENDA_PERIODO_FAILURE]: (state, action) =>
        state.merge({ loadingCreate: false, error: action.error }),

    [Types.CLEAR_AGENDA_PERIODO_REQUEST]: (state, action) =>
        state.merge(INITIAL_STATE),
});

