import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root:{ 
      height:"100vh", 
      display: 'flex',
      justifyContent: 'center',
      alignItems:'center',
      flexDirection:'column',
      // background: '#ff9933'

    },
    container:{
      display: 'flex', 
      minHeight: '50%',
      [theme.breakpoints.down('xs')]: {
        minHeight: '100%',
      },
    },
    bloco:{
      display:"flex",
      alignItems:'center',
      justifyContent:'center',
      flexGrow:1,
      width:'50%',
      flexDirection: 'column',
    },
    blocoLogo:{
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      }
    },
    blocoForm:{
      [theme.breakpoints.down('xs')]: {
        flexGrow: 2, 
        justifyContent: 'flex-start',
        width: '100%',
      },
    },
    logoLogin:{
      width: '80%',
      height: 'auto',
    },
    paper:{
      flexGrow: 1, 
      display: 'flex', 
      padding: 20,
      flexDirection: 'row', 
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column', 
      },
    },
    submit:{
     margin: theme.spacing(1,2)
    },
    form:{
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    },
    title:{
      marginBottom: theme.spacing(3),
      alignSelf: 'center'
    },
    divider:{
      height: '70%',
      backgroundColor: theme.palette.secondary.light,
      width: '1px',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    link: {
      margin: theme.spacing(1,0),
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.secondary.main
    }

  }));