import { call, put } from "redux-saga/effects";
import AglomeracaoActions from '../ducks/aglomeracao';
import api from '../../config/axios';
import SuccessActions from '../ducks/success';

export function* loadStatusRequest() {
    try {
        let aglomeracao = yield call(api.get,'aglomeracao/status');

        yield  put(AglomeracaoActions.loadStatusSuccess(aglomeracao.data?.data));    

    } catch (error) {
        yield put(AglomeracaoActions.loadStatusFailure(error));
    }
}

export function* createAglomeracaoRequest({atracaoId, atracaoItemId, dados, callback}) {
    try {

        if(!dados){
            throw new Error('Dados inválidos.')
        }
        let dadosEnvio = {status_id: dados?.status_aglomeracao_id }

        if (!atracaoItemId) {
            yield call(api.post,`atracao/${atracaoId}/aglomeracao`, dadosEnvio);
        } else {
            yield call(api.post,`atracao/${atracaoId}/item/${atracaoItemId}/aglomeracao`, dadosEnvio);
        }

        yield put(SuccessActions.setSuccess(true, 'Reporte de aglomeração salva com sucesso!'));
        yield put(AglomeracaoActions.createAglomeracaoSuccess());

        if (callback && typeof callback === 'function') callback();

    } catch (error) {

        yield put(AglomeracaoActions.createAglomeracaoFailure(error));
    }
}
