import { all, takeLatest } from 'redux-saga/effects';

import { LoginTypes } from '../ducks/login';

import { atracaoEffects } from './atracao/atracaoEffects';
import { programacaoEffects } from './programacao/programacaoEffects';

import { MenuTypes } from '../ducks/menu';
import { ViewTypes } from '../ducks/view';
import { TemaTypes } from '../ducks/tema';

import { ExperienciasTypes } from '../ducks/experiencia/experiencias';
import { ExperienciaTypes } from '../ducks/experiencia/experiencia';

import { AgendamentosTypes } from '../ducks/agendamento/agendamentos';
import { AglomeracaoTypes } from '../ducks/aglomeracao';
import { UsuariosTypes } from '../ducks/usuarios';
import { PerfilTypes } from '../ducks/perfil';
import { AcomodacoesTypes } from '../ducks/acomodacoes';
import { AgendamentoTypes } from '../ducks/agendamento/agendamento';

import { CaracterizadoresTypes } from '../ducks/caracterizador/caracterizadores';
import { UploadTypes } from '../ducks/upload';
import { PeriodosTypes } from '../ducks/agenda/periodos';
import { AgendaPeriodoTypes } from '../ducks/agenda/agendaPeriodo';
import { UsuarioInstalacaoTypes } from '../ducks/usuarioInstalacao';
import { ComunicacoesTypes } from '../ducks/comunicacao/comunicacoes';
import { ComunicacaoTypes } from '../ducks/comunicacao/comunicacao';

import * as auth  from './login';
import * as menu  from './menu';
import * as view  from './view';
import * as tema  from './tema';
import * as experiencias  from './experiencia/experiencias';
import * as experiencia  from './experiencia/experiencia';


import * as agendamentos  from './agendamento/agendamentos';
import * as agendamento  from './agendamento/agendamento';

import * as aglomeracao  from './aglomeracao';
import * as usuarios  from './usuarios';
import * as perfil  from './perfil';
import * as acomodacoes  from './acomodacoes';

import * as caracterizadores  from './caracterizador/caracterizadores';
import * as upload  from './upload';
import * as periodos  from './agenda/periodos';
import * as agendaPeriodo  from './agenda/agendaPeriodo';
import * as usuarioInstalacao from './usuarioInstalacao';
import * as comunicacoes from './comunicacao/comunicacoes';
import * as comunicacao from './comunicacao/comunicacao';

export default function* rootSaga() {
    yield all([
        atracaoEffects,
        programacaoEffects,
        takeLatest(TemaTypes.LOAD_TEMA_REQUEST, tema.loadTemaRequest),

        takeLatest(LoginTypes.LOGIN_REQUEST, auth.login),
        takeLatest(LoginTypes.LOGGED_REQUEST, auth.loggedRequest),
        takeLatest(LoginTypes.LOGOUT_REQUEST, auth.logout),
        takeLatest(LoginTypes.RECUPERA_SENHA_REQUEST, auth.recuperaSenhaRequest),
        takeLatest(LoginTypes.VALIDA_TOKEN_SENHA_REQUEST, auth.validaTokenSenhaRequest),
        takeLatest(LoginTypes.ESQUECI_SENHA_REQUEST, auth.esqueciSenhaRequest),

        takeLatest(ExperienciasTypes.LOAD_EXPERIENCIAS_REQUEST, experiencias.loadExperienciasRequest),
        takeLatest(ExperienciaTypes.UPDATE_EXPERIENCIA_STATUS_REQUEST, experiencia.updateExperienciaStatusRequest),
        takeLatest(ExperienciaTypes.SAVE_EXPERIENCIA_REQUEST, experiencia.saveExperienciaRequest),

        takeLatest(AgendamentosTypes.LOAD_AGENDAMENTOS_REQUEST, agendamentos.loadAgendamentosRequest),
        takeLatest(AgendamentoTypes.UPDATE_AGENDAMENTO_STATUS_REQUEST, agendamento.updateAgendamentoStatusRequest),

        takeLatest(AglomeracaoTypes.LOAD_STATUS_REQUEST, aglomeracao.loadStatusRequest),
        takeLatest(AglomeracaoTypes.CREATE_AGLOMERACAO_REQUEST, aglomeracao.createAglomeracaoRequest),

        takeLatest(MenuTypes.LOAD_MENU_REQUEST, menu.loadMenuRequest),
        takeLatest(MenuTypes.SET_MENU_SELECIONADO_REQUEST, menu.setMenuSelecionadoRequest),
         
        takeLatest(ViewTypes.LOAD_VIEW_REQUEST, view.loadViewRequest),

        takeLatest(UsuariosTypes.LOAD_USUARIOS_REQUEST, usuarios.loadUsuariosRequest),     
        takeLatest(PerfilTypes.LOAD_PERFIL_REQUEST, perfil.loadPerfilRequest),     
        takeLatest(AcomodacoesTypes.LOAD_ACOMODACOES_REQUEST, acomodacoes.loadAcomodacoesRequest),     


        takeLatest(CaracterizadoresTypes.LOAD_CARACTERIZADORES_REQUEST, caracterizadores.loadCaracterizadoresRequest),

        takeLatest(UploadTypes.UPLOAD_ARQUIVO_REQUEST, upload.uploadArquivoRequest),

        takeLatest(PeriodosTypes.LOAD_PERIODOS_REQUEST, periodos.loadPeriodosRequest),
        takeLatest(PeriodosTypes.SAVE_PERIODO_REQUEST, periodos.savePeriodoRequest),
        takeLatest(PeriodosTypes.REMOVE_PERIODO_REQUEST, periodos.removePeriodoRequest),

        takeLatest(AgendaPeriodoTypes.LOAD_AGENDA_PERIODO_REQUEST, agendaPeriodo.loadAgendaPeriodoRequest),
        takeLatest(AgendaPeriodoTypes.REMOVE_AGENDA_PERIODO_REQUEST, agendaPeriodo.removeAgendaPeriodoRequest),
        takeLatest(AgendaPeriodoTypes.SAVE_AGENDA_PERIODO_REQUEST, agendaPeriodo.saveAgendaPeriodoRequest),

        takeLatest(UsuarioInstalacaoTypes.LOAD_INSTALACOES_USUARIO_REQUEST, usuarioInstalacao.loadInstalacoesUsuarioRequest),
        takeLatest(UsuarioInstalacaoTypes.UPDATE_INSTALACAO_USUARIO_REQUEST, usuarioInstalacao.updateInstalacaoUsuarioRequest),
        
        takeLatest(ComunicacoesTypes.LOAD_COMUNICACOES_REQUEST, comunicacoes.loadComunicacoesRequest),
        takeLatest(ComunicacaoTypes.LOAD_COMUNICACAO_REQUEST, comunicacao.loadComunicacaoRequest),
        takeLatest(ComunicacaoTypes.SAVE_COMUNICACAO_REQUEST, comunicacao.saveComunicacaoRequest),
    ]);
}
