import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Texto from '../Campo/texto';
import { Box, Button, CircularProgress, InputLabel } from '@material-ui/core';
import { useStyles } from './styles';
import Loading from '../../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Imagem from '../Campo/imagem';
import Inteiro from '../Campo/inteiro';
import Error from '../../Error';
import ProgramacaoItemActions from '../../../store/ducks/programacao/programacaoItem';
import EditorHTML from '../../EditorHTML';

export default function DadosBasicos({ atracaoId, atracaoItemId, isFormEdited, setIsFormEdited}) {
  
  const classes                      = useStyles();
  const dispatch                     = useDispatch();

  const {loading, loadingCreate, error, data}       = useSelector(state => state.programacao.itemCrud.dadosBasicos);
    
  const { errors, register, control, reset, handleSubmit, formState } = useForm();

  const save = (data) => {
    dispatch(ProgramacaoItemActions.saveProgramacaoItemRequest(atracaoId,atracaoItemId, data));
  }

  useEffect(() => {
    reset(data);
  }, [data,reset])


  useEffect(() => {
    setIsFormEdited(formState.isDirty)
  }, [formState,setIsFormEdited]);

  return (<>
        <Loading  loading={loading} size={30} />
        {!loading && <form className={classes.formulario}>
           <div className={classes.row}>
                <Controller 
                    name={`imagem_atracao_item`}  
                    control={control} 
                    rules={{required: false}}
                    render={({ onChange, value }) => 
                      <Imagem 
                        name={`imagem_atracao_item`}  
                        url={value ?? data?.arq_imagem_atracao_item}
                        width={130}
                        height={60}
                        onChange={(file) => onChange(file[0]?.data)}
                        containerStyle={classes.image}
                      />
                    }
                />
                <Texto 
                    fullWidth
                    size="small"
                    label="Nome"
                    name={`nom_atracao_item`}   
                    inputRef={register({ required: true })}
                    error={Boolean(errors?.nom_atracao_item)}
                    helperText={errors?.nom_atracao_item && "O campo é obrigatório."}
                />
           </div>
           <div className={classes.row}>
                <Texto 
                    fullWidth
                    size="small"
                    label="Latitude"
                    name={`num_latitude_atracao_item`}
                    inputRef={register({ required: true })}
                    error={Boolean(errors?.num_latitude_atracao_item)}
                    helperText={errors?.num_latitude_atracao_item && "O campo é obrigatório."}
                />
                <Texto 
                    fullWidth
                    size="small"
                    label="Longitude"
                    name={`num_longitude_atracao_item`}
                    inputRef={register({ required: true })}
                    error={Boolean(errors?.num_longitude_atracao_item)}
                    helperText={errors?.num_longitude_atracao_item && "O campo é obrigatório."}
                />
           </div>
           <div className={classes.row}>
                <Texto 
                    fullWidth
                    size="small"
                    label="Link Vídeo"
                    name={`des_caminho_video_atracao_item`}
                    inputRef={register({ required: false })}
                    required={false}
                />
                <Controller 
                  name={`num_pessoa_max`}
                  control={control} 
                  rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                  render={({ onChange, value }) => 
                    <Inteiro 
                        fullWidth={false}
                        size="small"
                        label="Lotação Máxima"
                        inputRef={register({ required: true })}
                        error={Boolean(errors?.num_pessoa_max)}
                        helperText={errors?.num_pessoa_max && "O campo é obrigatório."}
                        setValue={onChange}
                        value={value}
                    />
                  }
                />
               <Controller 
                    name={`icone_atracao_item`}
                    control={control} 
                    rules={{required: false}}
                    render={({ onChange, value }) => 
                      <Imagem 
                        name={`icone_atracao_item`}
                        url={value ?? data?.des_icon_atracao_item}
                        width={80}
                        height={60}
                        onChange={(file) => onChange(file[0]?.data)}
                        containerStyle={classes.icone}
                        buttonLabel={<><FontAwesomeIcon icon="icons" size="2x"/> Selecione um ícone</>}
                      />
                    }
                />
           </div>
           <div className={classes.row}>
              <Controller 
                name={`num_pessoa_max_grupo`}
                control={control} 
                rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                render={({ onChange, value }) => 
                    <Inteiro 
                        fullWidth
                        size="small"
                        label="Nº de Pessoas por Grupo"
                        error={Boolean(errors?.num_pessoa_max_grupo)}
                        minValue={1}
                        setValue={onChange}
                        value={value}
                        required={false}
                    />
                  }
              />
               <Controller 
                name={`num_grupo_max`}
                control={control} 
                rules={{required: false, min: 1, valueAsNumber: true, validate:{positive: value => parseInt(value) > 1}}}
                render={({ onChange, value }) => 
                    <Inteiro 
                        fullWidth
                        size="small"
                        label="Nº Máximo de Grupos"
                        error={Boolean(errors?.num_grupo_max)}
                        minValue={1}
                        setValue={onChange}
                        value={value}
                        required={false}
                    />
                  }
              />
           </div>
           <div style={{margin: 10}}>
              <InputLabel htmlFor="des_conteudo">Conteúdo html:</InputLabel>
           </div>
           <div className={classes.row}>
                <Controller
                  name='des_atracao_item_html'
                  control={control}
                  rules={{required: true}}
                  defaultValue={''}
                  render={({ value, onChange }) => 
                      <div style={{margin: 10}}>
                          <EditorHTML
                              value={value}
                              onChange={onChange}
                          />
                      </div>
                  }
                />
           </div>
       </form>
      }

      <Box display="flex" justifyContent="flex-end" flexGrow={1} pr={1}> 
        <Button onClick={handleSubmit(save)} variant="contained" color="secondary" endIcon={<FontAwesomeIcon icon="chevron-right" /> } disabled={loadingCreate || !isFormEdited}> 
          {(loadingCreate || loading) && <CircularProgress size={15} /> } salvar {!atracaoItemId && " e continuar"} 
        </Button>
      </Box>

      <Error error={error} showAlert={false} alertMessage={error} />

     </>
  );
}