import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    setSuccess: ['show', 'message'],
    clearSuccess: null
});

export const UploadTypes = Types;
export default Creators;


export const INITIAL_STATE = Immutable({
    show: false,
    message: "Sucesso!"
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_SUCCESS]: (state, action) =>
        state.merge({ show: action.show, message: action.message }),
    [Types.CLEAR_SUCCESS]: (state) =>
        state.merge(INITIAL_STATE),
    
});