import React, { useRef, useState } from 'react';
import { ClickAwayListener, Grow, Popper, Button, Paper, ButtonGroup, Tooltip, Typography, useTheme } from '@material-ui/core';
import { DateRangePicker} from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import {staticRanges, inputRanges} from './ranges';
import moment from 'moment';

export default function DateRange ({onChange, startDate, endDate}) {

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);
    const [selectionRange, setSelectionRange] = useState(staticRanges[0].range());

    const desPeriodo = () => {
        let diaCompletoIni = moment(selectionRange.startDate).format('D') + ' de ' + moment(selectionRange.startDate).format('MMMM') + ', ' + moment(selectionRange.startDate).format('YYYY') ;
        let diaCompletoFim = moment(selectionRange.endDate).format('D') + ' de ' + moment(selectionRange.endDate).format('MMMM') + ', ' + moment(selectionRange.endDate).format('YYYY');
        // let diaCompletoHorasIni = diaCompletoIni + ' - ' +moment(selectionRange.startDate).format('HH:mm');

        if(diaCompletoIni === diaCompletoFim){
            return diaCompletoIni ;
        }

        return diaCompletoIni+ ' - '+ diaCompletoFim;
    }

    const handleSelect = (date) => {
       onChange(date?.range1);
       setSelectionRange(date?.range1);
    }

    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };    

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup color="secondary" ref={anchorRef} size="small">
                <Button
                    color="secondary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="Selecione o período"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    
                >
                    <Tooltip title={"Escolha um período para filtrar."}>
                        <Typography variant="subtitle2"><FontAwesomeIcon icon={faCalendarAlt} /> Filtrar</Typography>
                    </Tooltip>
                </Button>
                <Button  onClick={handleClick}>{desPeriodo()}</Button>
                
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                    <Paper elevation={3}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <DateRangePicker
                                onChange={handleSelect}
                                ranges={[selectionRange]}
                                locale={ptBR}
                                inputRanges={inputRanges}
                                staticRanges={staticRanges}
                                rangeColors={[theme.palette.secondary.main]}
                            />
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}