import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles } from './styles';
import LayoutTema from '../LayoutTema';

import Toolbar from '@material-ui/core/Toolbar';
import ElevationScroll from '../../ElevationScroll';

import Header from '../../Header'
import withWidth from '@material-ui/core/withWidth';

import Copyright from '../../Copyright';

import MenuActions from '../../../store/ducks/menu';

import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';

function LayoutDefault({width, ...props}) {

	const classes     			= useStyles();
	const menu        			= useSelector((state)=>state.menu);
    const dispatch		        = useDispatch();

	const onClickMenu = (menu) => {
		dispatch(MenuActions.setMenuSelecionadoRequest(menu));
    }

	  
    useEffect(() => {
        dispatch(MenuActions.loadMenuRequest());
    }, [dispatch]);


	return (
	<LayoutTema tela={"interna"} {...props}>
		<CssBaseline />
		<div className={classes.root}>
			<ElevationScroll  width={width}>
				<Header menu={menu.data}  onClickMenu={onClickMenu} menuSelecionado={menu.menuSelecionado}/>
			</ElevationScroll>
			<Toolbar />
			<Container disableGutters={true} maxWidth={false} className={classes.conteudo}>
				<div className={classes.conteudoCentral}>
					{props.children}
					<div className={classes.copyright}> <Copyright /> </div>

				</div>
			</Container>
		</div>
	</LayoutTema>
	);
}
export default   withWidth()(LayoutDefault);
