import { makeStyles } from '@material-ui/core/styles';
// import Back from "../../imagens/bra_fundo_01.svg";

export const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(4),
      // marginBottom: theme.spacing(0),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(1.5)
      },
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
    },
    cabecalho:{
      display: 'flex',
      justifyContent: 'space-between'
    },
    conteudo:{
      margin: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        margin: 1
      },
      flexGrow: 1,
      minHeight: 'calc(100vh - 260px)'
    },
    title:{
      display: 'flex'
    },
    button:{
      marginRight: theme.spacing(2)
    },
    
  }));