import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
      height: 64, // setting height/width is optional
      borderRadius: 0,
      minWidth: 0
  },
  buttonActive:{
    backgroundColor: theme.palette.secondary.main,
    height: 64, // setting height/width is optional
    borderRadius: 0,
    minWidth: 0
  },
  label: {
  // Aligns the content of the button vertically.
      flexDirection: 'column',
      fontSize: '10px !important',
      minWidth: '50px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        minWidth: '0',
      }
  },

  iconImg:{
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      fontSize: '25px',
      // marginBottom: '2px',
    },
    [theme.breakpoints.up('md')]: {
      height: '35px',
      fontSize: '35px',

      // marginBottom: '5px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '35px',
      fontSize: '35px',

      // marginBottom: '5px',
    },

  },
  badge:{
    top:"5px"
  }
}));