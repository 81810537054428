import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    toolbar: {
      display: 'flex'
    },
    barraMenu:{
      width: '100%',
      display: 'flex',
      height: '100%',
      marginLeft: 15
    },
    blocoLogo:{
      flexGrow: 1,
      padding: theme.spacing(2)
    },
    logo:{
      [theme.breakpoints.down('md')]: {
        maxHeight:'30px'
      },
        maxHeight:'50px',
      padding: 5
    },

    label:{
      [theme.breakpoints.down('md')]: {
        fontSize: '10px'
      }
    },
    avatar:{
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(4.5),
        height:  theme.spacing(4.5)
      }
    },
    button: {
      height: 64, // setting height/width is optional
      borderRadius: 0,
      minWidth: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '10px'
      }
    },
    buttonActive:{
      background: `${theme.palette.secondary.main} !important`,
      height: 64, // setting height/width is optional
      borderRadius: 0,
      minWidth: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '10px'
      }
    },
  }));