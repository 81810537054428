import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root:{
		margin: 0,
		padding: 0
	},
	areaTitulo:{
		paddingRight: theme.spacing(4)
	},
	areaConteudo:{
		padding: theme.spacing(2),
		height: '100%'
	},	
	closeButton:{
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	}
}));