import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogUpload from '../../Dialogs/DialogUpload';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const  useStyles = makeStyles((theme) => ({
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        borderRadius: 5
    },
    button:{
        '& .MuiButton-label':{
            fontSize: 10
        },
        backgroundColor: theme.palette.grey[200]
    },
    icone:{
        zIndex: 1,
        padding: 5,
        color: theme.palette.primary.main,
        fontWeight: 'bold',  
        fontSize: 10,
        display: 'flex',
        justifyContent:'center', 
        alignItems: 'center',
        flexDirection: 'column',
    },
    hover:{
        zIndex: 1,
        padding: 5,
        backgroundColor: "transparent",
        fontWeight: 'bold',  
        color: 'transparent',
        fontSize: 10,
        display: 'flex',
        justifyContent:'center', 
        alignItems: 'center',
        flexDirection: 'column',
        '&:hover':{
            color: theme.palette.grey[800],
            backgroundColor: "#cccccc96",
        }
    }
}));

//component espera o upload de apenas uma imagem.
export default function Imagem({name, url, width, height, onChange, buttonLabel, containerStyle}) {
 
  const classes = useStyles();
  const [uploadImage, setUploadImage]     = useState(false);

  return (<>

        <Button onClick={() => setUploadImage(true)} style={{ width: width,height: height, margin: 5}} className={`${classes.button} ${containerStyle}`}> 
            {url && <>
                <span className={classes.imageSrc} style={{  backgroundImage: `url(${url})`}}> </span>
                <div className={classes.hover} style={{ width: width,height: height}}>
                    {buttonLabel ? buttonLabel : <><FontAwesomeIcon icon="camera" size="2x" /><span>Alterar Imagem </span></>}
                </div>
              </>
            }
            {!url && <div className={classes.icone} style={{ width: width,height: height}}>
                {!buttonLabel ? <><FontAwesomeIcon icon="camera" size="2x" /><span>Selecione uma Imagem</span></> : buttonLabel}
             </div>
            }
        </Button>

        <DialogUpload 
            title="Upload de Imagem" 
            open={uploadImage} 
            close={() => setUploadImage(false)} 
            confirm={(files) =>  {onChange(files); setUploadImage(false)} }
            uploadProps={{accept: ['.jpg', '.png', '.svg', '.jpeg'], showFiles: true}}

        />
    </>
  );
}
