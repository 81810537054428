import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';


const { Types, Creators } = createActions({
    loadComunicacaoRequest: ['comunicacaoId'],
    loadComunicacaoSuccess: ['data'],
    loadComunicacaoFailure: ['error'],

    saveComunicacaoRequest: ['comunicacaoId', 'dados'],
    saveComunicacaoSuccess: ['data'],
    saveComunicacaoFailure: ['error'],

    clearComunicacaoRequest: null
});

export const ComunicacaoTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    error: false
});

export const reducerComunicacao = createReducer(INITIAL_STATE, {
    [Types.LOAD_COMUNICACAO_REQUEST]: state =>
        state.merge({ loading: true, error: false }),
    [Types.LOAD_COMUNICACAO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.LOAD_COMUNICACAO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
    
    [Types.SAVE_COMUNICACAO_REQUEST]: state =>
        state.merge({ loading: true, error: false}),
    [Types.SAVE_COMUNICACAO_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, loading: false, error: false }),
    [Types.SAVE_COMUNICACAO_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),

    [Types.CLEAR_COMUNICACAO_REQUEST]: state =>
        state.merge({ data: {}, loading: false, error: false }),
});