import {Button,withStyles} from '@material-ui/core';

const ButtonSuccess = withStyles((theme) => ({
    root: {
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    }, 
  }))(Button);

export default  (ButtonSuccess);
