import { call, put} from 'redux-saga/effects';
import api from '../../../config/axios';
import ExperienciasActions from '../../ducks/experiencia/experiencias';
import lodash from 'lodash';
import {param} from 'jquery';
import moment from 'moment';

 function trataRowsGrid(dados){
    
    let rows = lodash.map(dados, (item, key) => {
        return {
            ...item,
            id: key, 
            nom_usuario: item.usuario?.nom_usuario,
            nom_perfil: item.perfil?.nom_perfil,
            actions: ' ',
            dt_experiencia_ini_grid: moment(item?.dt_experiencia_ini).format('DD/MM/yyyy'),
            dt_experiencia_fim_grid: item?.dt_experiencia_fim ? moment(item?.dt_experiencia_fim).format('DD/MM/yyyy'): '',
        };
    });

    return rows;
}

export function* loadExperienciasRequest({ filtros, ordenacao, page}) {
    try {

        let pagina = page ?? 1;

        let response = yield call(api.get, `experiencia?${param(filtros)}&${param(ordenacao)}&page=${pagina}&per_page=15`);

        if(response.status !== 200 && response.status !== 201){
            throw response;
        }
        
        let data = yield trataRowsGrid(response.data?.data);
        response.data.data = data;
        yield put(ExperienciasActions.loadExperienciasSuccess(response.data));

    } catch (error) {
        error.message = "Erro ao carregar experiências."
        yield put(ExperienciasActions.loadExperienciasFailure(error));
    }
}
