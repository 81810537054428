import {Button,withStyles} from '@material-ui/core';

const ButtonCancel = withStyles((theme) => ({
    root: {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.light,
      },
    }, 
  }))(Button);

export default  (ButtonCancel);
