import { call, put, race, select, take} from 'redux-saga/effects';
import api from '../../../config/axios';
import history from '../../../routes/history';
import AtracaoActions from '../../ducks/atracao/atracao';
import AtracaoCaracterizadoresActions from '../../ducks/atracao/caracterizadores';
import UploadActions from '../../ducks/upload';
import SuccessActions from '../../ducks/success';

export function* loadAtracaoRequest({ atracaoId}) {
    try {

        let dados = {
            icone_atracao: null,
            imagem_atracao: null,
            cod_tipo_atracao: 'OUTRO'
        };

        if(atracaoId){
            let response = yield call(api.get, `atracao/${atracaoId}`);

            dados = {
                ...response.data?.data,
                icone_atracao: null,
                imagem_atracao: null
            };

            if(response.status !== 200 && response.status !== 201){
                throw response;
            }


            yield put(AtracaoCaracterizadoresActions.loadAtracaoCaracterizadoresRequest(atracaoId));

            const {caracterizadorError} = yield race({
                caracterizadorError:  take(AtracaoCaracterizadoresActions.loadAtracaoCaracterizadoresFailure().type),
                success: take(AtracaoCaracterizadoresActions.loadAtracaoCaracterizadoresSuccess().type),
            });
    
            if(caracterizadorError){
                throw caracterizadorError;
            }
            
        }

       
        yield put(AtracaoActions.loadAtracaoSuccess(atracaoId, dados));


    } catch (error) {
        error.message = error.message ?? "Erro ao carregar atração."
        yield put(AtracaoActions.loadAtracaoFailure(error));
    }
}


function* uploadImagem(imagem) {
    
    yield put(UploadActions.uploadArquivoRequest(imagem));

    const {error, success} = yield race({
        error:  take(UploadActions.uploadArquivoFailure().type),
        success: take(UploadActions.uploadArquivoSuccess().type),
    });

    return {error, success}
}

export function* saveAtracaoRequest({ atracaoId, dados}) {
    try {

        let dadosAtuais  = yield select((state) => state.atracao.itemCrud.dadosBasicos.data);
        
        let dadosEnvio = atracaoId ? {...dadosAtuais, ...dados} : dados;

        if(dados?.imagem_atracao){
            let { error, success} = yield uploadImagem(dados?.imagem_atracao);
            if(error){
                throw error;
            }
            dadosEnvio = {...dadosEnvio, arq_imagem_atracao: success?.data}
        }

        if(dados?.icone_atracao){
            let { error, success} = yield uploadImagem(dados?.icone_atracao);
            if(error){
                throw error;
            }
            dadosEnvio = {...dadosEnvio, des_icon_atracao: success?.data}
        }

        let request    = atracaoId ? api.put : api.post;
        let url        = atracaoId ?  `atracao/${atracaoId}` :  `atracao`;

        let response = yield call(request, url, dadosEnvio);

        if(!atracaoId){
            history.push('/atracao', {atracaoId: response?.data?.data?.atracao_id, nome: response?.data?.data?.nom_atracao, openTab: 1})
        }

        yield put(SuccessActions.setSuccess(true, 'Atração salva com sucesso!'));
        yield put(AtracaoActions.saveAtracaoSuccess(response?.data?.data?.atracao_id, response?.data?.data));

    } catch (error) {
        error.message = error.message ?? "Erro ao salvar atração."
        yield put(AtracaoActions.saveAtracaoFailure(error));
    }
}

