import { combineReducers } from 'redux';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {reducer as reducerAgendamento} from '../agendamento/agendamento';

const { Types, Creators } = createActions({
    loadAgendamentosRequest: [ 'atracaoId', 'atracaoItemId','filtros', 'ordenacao','page'],
    loadAgendamentosSuccess: ['data'],
    loadAgendamentosFailure: ['error'],

    clearAgendamentosRequest: null,

});

export const AgendamentosTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: {},
    loading: false,
    error: false,
    page: 1
});

export const reducerAgendamentos = createReducer(INITIAL_STATE, {
    [Types.LOAD_AGENDAMENTOS_REQUEST]: state =>
        state.merge({ loading: true, error: false, data:{} }),
    [Types.LOAD_AGENDAMENTOS_SUCCESS]: (state, action) =>
        state.merge({ data: action.data, page: action?.page, loading: false, error: false }),
    [Types.LOAD_AGENDAMENTOS_FAILURE]: (state, action) =>
        state.merge({ loading: false, error: action.error }),
    
    [Types.CLEAR_AGENDAMENTOS_REQUEST]: (state, action) =>
        state.merge({ ...INITIAL_STATE}),
});

export const reducer = combineReducers({lista: reducerAgendamentos, itemCrud: reducerAgendamento});
